export class PromotionItem {
    id: number;
    product_code: string;
    product_name: string;
    promo_group_id: number;
    promotion_group_name: string;
    location_id: number;
    product_id?: number;
    promotion_group_id?: number;
}
