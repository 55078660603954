<dx-data-grid #productGroupGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  height="95vh"
>
<dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="product_group_code" dataType="string" caption="Code" width="15%">
    <dxi-validation-rule type="required" message="product group id is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="product_group_name" dataType="string" caption="Name">
    <dxi-validation-rule type="required" message="product group name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
</dx-data-grid>
