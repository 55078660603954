<dx-data-grid #mixMatchLevelGrid
  [dataSource]="mixMatchDataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowRemoving)="onRowRemoving($event)"
>
<dxo-editing
    mode="row"
    [allowDeleting]="true"
    [allowAdding]="true"
    [allowUpdating]="true"
  >
  </dxo-editing>
  <dxi-column dataField="selection_level" dataType="number" caption="Level" alignment="left" width="100">
    <dxi-validation-rule type="required" message="Level is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="description" dataType="string"></dxi-column>
  <dxi-column dataField="purchase_quantity" dataType="number" caption="Purchase Quantity">
    <dxi-validation-rule type="required" message="Pirchase Quantity code is required"></dxi-validation-rule>
  </dxi-column>
  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

  <div *dxTemplate="let item of 'detail'">
      <app-mix-match-item [key]="item.data.header_id" [level]="item.data.selection_level"></app-mix-match-item>
  </div>

</dx-data-grid>
