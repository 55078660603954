export class MixMatchHeader {
    id: number;
    group_id: number;
    location_id: number;
    name: string;
    total_price: number | null;
    free_item_id: number | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}
