import { Component, EventEmitter, OnInit, Output,ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import themes from 'devextreme/ui/themes';
import { AuthGuard } from '../auth.guard.service';
import { Router } from '@angular/router';
import { SimpleObject, SimpleService } from '../simple.service';
import { User } from '../user.model';
import { CookieService } from 'ngx-cookie-service';
import { ProductGroup } from '../models/product-group.model';
import { WeekRangeSharedService } from '../services/week-range-shared.service';
import { WeekRangeValues } from '../week-range-selector/week-range-selector.component';
import { CalendarWeek } from '../week-range-selector/week-range-selector.component';
import { UserDetails } from '../models/user-details';
import { ProfileService } from '../services/profile.service';
import { ClearStorage } from '../services/clear-service.service';
import { AppComponent } from '../app.component';
import { InfoCardsService } from '../services/info-card.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [SimpleService, AuthGuard,ProfileService]
})
export class HeaderComponent implements OnInit {
   profileSettings: SimpleObject[];
   userName: string;
   weekRange: WeekRangeValues | undefined | null;
   weekRangeHistoric: WeekRangeValues | undefined | null = undefined;
   endDate: string | null = null;
   displayWeekRange: boolean = false;
   userLoggedInDetails: UserDetails;
   infoCardsCountWarnings:any;
   infoCardsCountErrors:any;
   @Output() toggleDrawer = new EventEmitter<void>();
@ViewChild(AppComponent) appComponent: AppComponent;
buttonOptions: any = {
  icon: "menu",
  onClick: () => this.toggleDrawer.emit()
};
  constructor(private auth: AuthGuard,
      private router: Router,
      private weekRangeSharedService: WeekRangeSharedService,
      private simpleService: SimpleService,
      private cookieService: CookieService,
      private profileService: ProfileService,
      private clearStorage: ClearStorage,
      public infoCardsService: InfoCardsService
      ) {
        }

  ngOnInit(): void {
    //track url
    this.infoCardsService.fetchInfoCards();
    this.infoCardsService.infoCardsCountByTypeSubject.subscribe(countsByType => {
      this.infoCardsCountWarnings = countsByType['warning'] || 0; //  for getting warning count
      this.infoCardsCountErrors = countsByType['error'] || 0; //  for getting error count
    });
    const targetUrl = "/slotting"
    let previousUrl = this.router.url;
    if(localStorage.getItem('weekRangeValues') !== null) {
    const savedWeekRange = localStorage.getItem('weekRangeValues');
    this.weekRangeSharedService.selectedWeekRange$.subscribe(weekRange => {
      this.weekRange = weekRange;
      if (weekRange) {
       this.calculateEndDate();
       this.saveToLocalStorage(); // Add this line after modifying values
      }
    });
    this.loadFromLocalStorage();
    this.weekRange = JSON.parse(savedWeekRange as string) as WeekRangeValues;
    this.calculateEndDate();  // calculate the end date based on the retrieved range
    this.saveToLocalStorage();
    this.displayWeekRange = (this.router.url === targetUrl);
    }
    //subscribe to navigation event
    this.router.events.subscribe(event => {
      if(this.router.url === targetUrl && this.weekRangeHistoric !== undefined){
        this.weekRange = this.weekRangeHistoric;
        this.weekRangeHistoric = undefined;
      }
      this.displayWeekRange = (this.router.url === targetUrl);
      previousUrl = this.router.url;
    });
    this.profileSettings = this.simpleService.getProfileSettings();
         this.auth.userNameChanged.subscribe((newUserName: string) => {
    this.userName = newUserName;
  });
  }
  navigateToNotifications():void{
    this.router.navigate(['/']);
  }
  loadFromLocalStorage(): void {
    const storedWeekRange = localStorage.getItem('weekRange');
    if (storedWeekRange) {
      this.weekRange = JSON.parse(storedWeekRange) as WeekRangeValues;
    }
    const storedEndDate = localStorage.getItem('endDate');
    if (storedEndDate) {
      this.endDate = storedEndDate;
    }
    const storedDisplayWeekRange = localStorage.getItem('displayWeekRange');
    if (storedDisplayWeekRange) {
      this.displayWeekRange = storedDisplayWeekRange === 'true'; // because it will be stored as a string
    }
  }
  saveToLocalStorage(): void {
    if (this.weekRange) {
      localStorage.setItem('weekRange', JSON.stringify(this.weekRange));
    }
    if (this.endDate) {
      localStorage.setItem('endDate', this.endDate);
    }
    localStorage.setItem('displayWeekRange', String(this.displayWeekRange));
  }
  userImage(){
    const userLoggedIn = localStorage.getItem('userDetails');
    const userLoggedInDetails = userLoggedIn ? JSON.parse(userLoggedIn) : null;
    if(userLoggedInDetails && userLoggedInDetails.data) {
      this.userName = userLoggedInDetails.data.username;
      if(userLoggedInDetails?.data?.location_id === 7){
        return '../../assets/independent-liquour-retailers.png';
      }
      else if(userLoggedInDetails?.data?.location_id === 8){
        return '../../assets/thirsty_camel_logo.png';
      }
      else if(userLoggedInDetails?.data?.location_id === 3){
        return '';
      }
      return; // return a default image or null here
    }
    return;
  }

  itemClick(e: any) {
    if(e.itemData.name === 'Logout') {
      this.auth.logOut();
      this.clearStorage.logout();
    } else if(e.itemData.name === 'Profile') {
      this.profileService.setEditProfile(true);
    }
  }

  checkComparison() {
    return true;
  }
  navigateToDocumentation() {
    //window.open('https://develop-promoflo.s3.ap-southeast-2.amazonaws.com/documentation/documentation_how_to.html', '_blank');
  }
  //calculates end date of the selected date range. i.e. start date + week count
  private calculateEndDate() {
    if (this.weekRange) {
      const calendarWeeks = JSON.parse(localStorage.getItem('calender-weeks') || '[]') as CalendarWeek[];
      const startWeekObject = calendarWeeks.find(week => week.start_date.toString() === this.weekRange?.startWeek);

      if (startWeekObject) {
        const startDate = new Date(startWeekObject.start_date);
        startDate.setDate(startDate.getDate() + (this.weekRange.numberOfWeeks * 7) - 1);
        this.endDate = startDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      }
    }
  }
}
