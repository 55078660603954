import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  changeDateFormat(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  yearFirstDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  twoWeeksAgo(): string {
    let date = new Date();
    date.setDate(date.getDate() - 48);
    let formattedDate = date.toISOString().split('T')[0];
    return formattedDate; // Outputs: "yyyy-mm-dd"
  }

}
