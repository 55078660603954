import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify'

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor() { }

  tellUser(result: any) {
    if(result.success) {
      if(typeof result.data === 'object') {
        return notify(result.data.result, 'success', 2000);
      }
      return notify(result.data, 'success', 2000);
    } else {
      let error = '';
      if('data' in result) {
        error = ': '+result.data;
      }
     return  notify(result.message + error, 'error', 6000);
    }
  }

  returnError(error: any) {
    return notify(error, 'error', 6000);
  }
}
