<app-custom-loader *ngIf="detailsLoading"></app-custom-loader>
<div id="tooltip" class="tooltip">Core Range Product</div>

<div class="byGroupDetailsGrid" >
<dx-data-grid #byGroupDetailsGrid [ngClass]="{ 'hidden': detailsLoading, 'visible': !detailsLoading }"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [focusedRowEnabled]="true"
  [wordWrapEnabled]="false"
  [height]="'90%'"
  [width]="'100%'"
  [columns]="columns"
  [columnAutoWidth]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onRowUpdating)="rowUpdating($event)"
  [showBorders]="true"
  [showColumnLines]="true"
  (onContentReady)="contentReadyLoading($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onFocusedCellChanging)="onFocusedCellChanged($event)"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [columnFixing]="{enabled: true}"
  [columnResizingMode]="'widget'"
  [columnChooser]="{enabled: true, mode: 'select'}"
  >
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <!-- <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="byGroupDetailsState"
  ></dxo-state-storing> -->

  <dxo-state-storing
      [enabled]="true"
      type="custom"
      [storageKey]="storageKey"
      [customLoad]="loadState"
      [customSave]="saveState"
    ></dxo-state-storing>

  <dxo-toolbar [visible]="false">
    <dxi-item name="columnChooser" [visible]="false" ></dxi-item>
  </dxo-toolbar>

  <dxo-load-panel [enabled]="false"></dxo-load-panel>
  <dxo-editing
    mode="row"
    refreshMode="reload"
    [allowUpdating]="allowEditing"
    [allowDeleting]="allowEditing"
  ></dxo-editing>
  <dxi-column dataField="product_code" dataType="string" caption="Product Code" [allowEditing]="false" [fixed]="true"></dxi-column>
  <dxi-column dataField="product_name" dataType="string"  caption="Product Name" [allowEditing]="false" [fixed]="true" width="250px"></dxi-column>
  <dxi-column dataField="planning_category_name" dataType="string" caption="Category"  [allowEditing]="false" width="180px"></dxi-column>
  <dxi-column dataField="supplier_name" dataType="string" caption="Supplier" [allowEditing]="false" width="200px"></dxi-column>
  <div *dxTemplate="let data of 'mixMatchTemplate'">
    Mix n Match
    <dx-button *ngIf="permissions" class="edit_button promotion_status_button" icon="rename" hint="Edit Mix n Match" (onClick)="mixMatchPopupEvent($event)"></dx-button>
  </div>
  <dxi-column dataField="mix_match_header_name" dataType="string" caption="Mix n Match" [allowEditing]="false" headerCellTemplate="mixMatchTemplate"></dxi-column>
  <dxi-column dataField="carton_quantity" dataType="number" caption="Carton" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="packs_per_carton" dataType="number" caption="Packs" [allowEditing]="false"></dxi-column>
  <ng-container *ngFor="let column of columns">
    <dxi-column
      [dataField]="column.dataField"
      [name]="column.dataField"
      [format]="currencyFormat"
      [dataType]="column.dataType"
      [allowEditing]="true"
      [setCellValue]="setRebateTotal">
  </dxi-column>
  </ng-container>
  <dxi-column dataField="rebate" dataType="number" [format]="currencyFormat" [allowEditing]="false" caption="Rebate Total"></dxi-column>
  <dxi-column dataField="max_rebate_qty" dataType="number" caption="Max Rebate Qty"></dxi-column>
  <dxi-column dataField="store_position_name" dataType="string" caption="Store Position">
    <dxo-lookup [dataSource]="storePositions" valueExpr="position_name" displayExpr="position_name"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="allowance" [format]="currencyFormat" dataType="number" caption="Allowance" [setCellValue]="setAllowanceTotal"></dxi-column>
  <dxi-column dataField="allowance_group" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Group"></dxi-column>
  <dxi-column dataField="allowance_promotion" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Allowance Promotion"></dxi-column>
  <dxi-column dataField="allowance_total" [format]="currencyFormat" dataType="number" caption="Allowance Total" [setCellValue]="setAllowance" [calculateCellValue]="calculateAllowanceTotal"></dxi-column>
  <dxi-column dataField="allowance_universal" [format]="currencyFormat" dataType="number" visible="false" [allowEditing]="false" caption="Uni. Deal"></dxi-column>
  <dxi-column dataField="cost_ex_gst" [format]="currencyFormat" dataType="number" caption="Std Cost ex WET" [visible]="false" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="cost_inc_wet_only" [format]="currencyFormat" dataType="number" caption="Std Cost"  [allowEditing]="false"></dxi-column>
  <dxi-column dataField="new_cost" [format]="currencyFormat" caption="New Cost" [calculateCellValue]="calculateNewCost" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="sell_price" [format]="currencyFormat" dataType="number" caption="Promo Price"></dxi-column>
  <dxi-column dataField="edlpPrice" [format]="currencyFormat" dataType="number" caption="EDLP Price" [visible]="false" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="gp_percent" [format]="{type: 'fixedPoint', precision: 2}" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="gp_amount" [format]="currencyFormat" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="rrp" [format]="currencyFormat" caption="RRP" [allowEditing]="false" [visible]="false"></dxi-column>
  <dxi-column dataField="price_override" dataType="boolean" caption="Price Override" [visible]="false"></dxi-column>
  <dxi-column dataField="comment" dataType="string" caption="Comment" [visible]="false" cellTemplate="commentCell"></dxi-column>
  <dxi-column [format]="currencyFormat" caption="R-Cost" [calculateCellValue]="calculateRebateCost" [allowEditing]="false"></dxi-column>
  <dxi-column [format]="currencyFormat" caption="R-GP$" [calculateCellValue]="calculateRebateGpAmount" [allowEditing]="false"></dxi-column>
  <dxi-column [format]="currencyFormat" caption="R-GP%" [calculateCellValue]="calculateRebateGpPercent" [allowEditing]="false"></dxi-column>

  <dxo-summary>
    <dxi-group-item column="product_group_name" summaryType="count"> </dxi-group-item>
  </dxo-summary>
  <div *dxTemplate="let data of 'commentCell'">
    <div class="comment-cell-container">
      <dx-button icon="overflow" (onClick)="openEditor(data.value, data)" class="transparent-button"></dx-button>
      <span>{{data.value}}</span>
    </div>     
  </div>
</dx-data-grid>
<dx-tooltip position="right">
  <div class='tooltipContent'>
    <div>Mix n Match Item</div>
  </div>
</dx-tooltip>
</div>

<dx-popup 
  [(visible)]="commentPopupVisible" 
  [width]="800"
  [height]="200"
  [showTitle]="true"
  title="Comment"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true">
  <dx-text-area [(value)]="commentValue" [height]="80"></dx-text-area>
  <div class="button-container">
    <dx-button text="Save" (onClick)="saveComment()" type="success"></dx-button>
  </div>
</dx-popup>
<!-- Mix n Match -->
<dx-popup
  [(visible)]="mixMatch.editMixMatch"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Mix n Match"
  height="95%"
  width="60%"
  [showCloseButton]="true"
  (onHiding)="mixMatch.onHiding($event)">
    <app-mix-match #mixMatch></app-mix-match>
</dx-popup>

<!-- Mix n Match Qty -->
<dx-popup *ngIf="permissions"
  #mixMatchQty
  [(visible)]="mixMatchQtyPopup.editMixMatchQty"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Add Mix n Match"
  height="400px"
  width="500px"
  [showCloseButton]="true"
  (onShowing)="mixMatchQtyPopup.onShowing($event)"
  (onHiding)="mixMatchQtyPopup.onHiding($event)">
    <app-mix-match-qty #mixMatchQtyPopup
      (qty)="getQty($event)"
      (importAllCheckbox)="getimportAllCheckbox($event)"
      (price)="getPrice($event)"
      (close)="createMixnMatch()"
      (quickAdd)="quickAddMixMatch($event)"
      (mixMatchHeaderId)="getMixMatchHeaderId($event)"
      (nameFieldEmitter)="getMixnMatchName($event)"
      (mixMatchHeaderName)="getMixMatchHeaderName($event)"
      [productId]="productId">
    </app-mix-match-qty>
