import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
@Injectable({
    providedIn: 'root' // This ensures that the service is available as a singleton across the app
  })
export class LoadingService {
    private isLoading = new Subject<boolean>();
    public isLoading$: Observable<boolean> = this.isLoading.asObservable();
  
    /**
     * Toggles the loading state.
     * @param val A boolean value to set the loading state.
     */
    toggleLoading(val: boolean): void {
      this.isLoading.next(val);
    }

    /**
     * Completes the loading observable upon service destruction to prevent memory leaks.
     */
    ngOnDestroy(): void {
      this.isLoading.complete();
    }
    unsubscribe(){
        this.isLoading.unsubscribe();
    }
}
