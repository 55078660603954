import { Component } from '@angular/core';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent {
  editWarehouse: boolean = false;
  dataSource: CustomStore;
  private apiUrl: string = environment.baseApiUrl;
  constructor(private horizontalMenuService:HorizontalMenuService,
              private crudService: CrudService,
              private notifyService: NotifyService) {
    this.horizontalMenuService.editWarehouse$.subscribe((value) => {
      this.editWarehouse = value;
      this.dataSource = new CustomStore<any>({
        load: async () => {
          return await new Promise((resolve) => {
            this.crudService.getData(`${this.apiUrl}warehouses`).subscribe((result: any) => {
              resolve({data: result, totalCount: result.length});
            })
          })
        },
        insert: async (values) => {
          return await new Promise(async (resolve) => {
            await this.crudService.insertData(`${this.apiUrl}warehouses`, values)
            .subscribe((result: any) => {
              this.notifyService.tellUser(result);
              resolve(result);
            });
          });
        },
        update: async (key, values) => {
          console.log(key, values);
          return await new Promise(async (resolve) => {
            await this.crudService.updateData(`${this.apiUrl}warehouses`, key.id, values)
            .subscribe((result: any) => {
              this.notifyService.tellUser(result);
              resolve(result);
            });
          });
        },
        remove: async (key) => {
          return await new Promise(async (resolve) => {
            await this.crudService.deleteData(`${this.apiUrl}warehouses`, key.id)
            .subscribe((result: any) => {
              this.notifyService.tellUser(result);
              resolve(result);
            });
          });
        }
      })
    });
  }
}
