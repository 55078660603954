<div class="dx-field">
  <div class="dx-fieldset">
    <div class="dx-field-container ">
      <div class="dx-field-label mixnmatch_label">Name:</div>
      <div class="dx-field-value">
    <dx-text-box 
      [width]="100"
      [(value)]="nameField"
    ></dx-text-box>
  </div>
</div>
    <div class="dx-field-container ">
      <div class="dx-field-label mixnmatch_label">Qty</div>
      <div class="dx-field-value">
    <dx-number-box
      [showSpinButtons]="true"
      [width]="110"
      [(value)]="qtyValue"
      [min]="2"
      (onValueChanged)="setQty($event)"
    ></dx-number-box>
  </div>
</div>
  <div class="dx-field-container ">
    <div class="dx-field-label mixnmatch_label">Price:</div>
    <div class="dx-field-value">
    <dx-number-box
      [width]="100"
      format="$ #,##0.##"
      (onValueChanged)="setPrice($event)"
      (onEnterKey)="closePopup()"
    ></dx-number-box>
  </div>
</div>
  <div class="dx-field-container ">
    <div class="dx-field-label mixnmatch_label large_label">Add All:</div>
    <div class="dx-field-value">
      <dx-check-box
      (onValueChanged)="setCheckbox($event)">
        
      </dx-check-box>
    </div>
    </div>
    <div class="dx-field-container ">
      <div class="dx-field-value">
    <dx-button 
    class="margin-left ok-button"
    text="OK"
    type="success"
    [disabled]="buttonDisabled"
    (onClick)="closePopup()"></dx-button>
  </div>
  </div>
</div>

   

</div>
<dx-list #dataList id="list"
[dataSource]="mixMatchList"
selectionMode="single"
[height]="'190px'"

(onItemClick)="onSelect($event)">
<div *dxTemplate="let data of 'item'">
  <div class="dx-field">
    <div class="dx-field-label">{{data.name}}</div>
    </div>
  </div>
</dx-list>
