<div class="mediaTypesGrid">
  <dx-data-grid #mediaTypesGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [columnAutoWidth]="true"
  [height]="'90%'"
>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxi-column dataField="media_type_name" dataType="string" minWidth="200px" caption="Media Type">
    <dxi-validation-rule type="required" message="Media-type name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="media_type_field_type" dataType="string" caption="Data Type">
    <dxi-validation-rule type="required" message="Media type is required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="fieldTypes"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="export" dataType="boolean" caption="Export"></dxi-column>
  <dxi-column dataField="max" dataType="integer" caption="Max"></dxi-column>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
</dx-data-grid>
</div>
