import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, Observable, take, Subject, map, of, throwError } from 'rxjs';
import { Response } from './user.model';
import { UserDetails } from './models/user-details';
import { environment } from 'src/environments/environment';
import themes from 'devextreme/ui/themes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private authApiUrl: string = environment.authApiUrl;
  private authUiUrl: string = environment.authUiUrl;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient) {}

  logOut() {
    const token = this.cookieService.get('otd-token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    this.http.get(`${this.authApiUrl}logout`, { headers })
      .subscribe(response => {
        console.log(response);
        this.cookieService.deleteAll();
        window.location.replace(this.authUiUrl);
      },
      (error) => {
        console.error(error);
      });
  }

  userDetails(): Observable<UserDetails> {
    this.checkBearerTokenCookie();
    const tokenDetails = JSON.parse(this.cookieService.get('otd-user'));
    const url = `${this.authApiUrl}user-details?email=${tokenDetails.email}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + tokenDetails.token
    });
    return this.http.get<UserDetails>(url, { headers }).pipe(
      tap(data => {
        themes.current(environment.themes[data.data.theme]);
        document.body.style.backgroundColor = environment.bgColours[data.data.theme];
        localStorage.setItem('userDetails', JSON.stringify(data));
      }),
      take(1)
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkBearerTokenCookie().pipe(
      map(isValid => {
        if (isValid) {
          return true; // Token is valid
        } else {
          window.location.href = this.authUiUrl
          return false;
        }
      })
    );
  }
  

  checkBearerTokenCookie(): Observable<boolean> {
    const token = this.cookieService.get('otd-token');
    if (token) {
      console.log('Bearer token found in cookie');
      return this.http.post<{ success: boolean }>(`${this.authApiUrl}verify`, { token: token })
        .pipe(
          map(res => {
            if (res.success) {
              console.log('Token is valid.');
              return true;
            } else {
              console.log('Token is invalid.');
              window.location.href = this.authUiUrl;
              return false;
            }
          }),
          catchError(error => {
            console.error('Error verifying token:', error);
            window.location.href = this.authUiUrl;
            return of(false);
          })
        );
    } else {
      console.log('Bearer token not found in cookie');
      window.location.href = this.authUiUrl;
      return of(false);
    }
  }
  userNameChanged = new Subject<string>();
  updateUserDetails(details: UserDetails, newPassword?:string): Response {
    console.log("Update User Details");
    const newUserName = details.data.username;
    this.userNameChanged.next(newUserName);
    const token = this.cookieService.get('otd-token');
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    if (token) {
      const payload = newPassword ? { ...details.data, password: newPassword } : details.data;
      this.http.put<Response>(`${this.authApiUrl}update-profile`, payload, { headers })
   .pipe(
     tap(res => {
       if(res.success) {
         console.log('Details Updated');
         console.log(res);
       }
       return res;
     }),
     catchError(error => {
       console.error('Failed to update user details:', error);
       return throwError(() => new Error('Failed to update due to server error'));
     })
   ).subscribe({
     next: (response) => {
       // Handle successful response
     },
     error: (error) => {
       // Properly handle the error scenario
       console.error('Update failed:', error);
     }
   });

    }
    return {success: false, message: 'Token is missing'};
  }

  userName(): string {
    return this.cookieService.get('otd-userName');
  }
  userCookieDetails(): object {
    if(this.cookieService.check('otd-user')) {
      return JSON.parse(this.cookieService.get('otd-user'));
    }
    return {};
  }
 
  verifyLoginDetails(email: string, password: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<ILogin>(`${this.authApiUrl}login`, { email: email, password: password }, httpOptions)
      .pipe(
        map(response => true), // Assuming the request was successful and we got a 200 status
        catchError(error => {
          // You can check for specific status codes here if you want to differentiate
          console.error('Login failed', error);
          return of(false); // Return Observable of false
        })
      );
  }
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
export interface ILogin {
  success: boolean;
  data: {
    token: string;
    name: string;
  };
  message: string;
}
