import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HorizontalMenuService {

private editProdGroups: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editProducts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionGroups: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionPeriods: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionNames: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editBuyingGroups: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editSlotTypes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editEvents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionTypes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editStorePositions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editAllowances: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editMediaTypes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editProductCategories: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionCategories: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editSuppliers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editMixMatch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editProductSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editPromotionRebates: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editWarehouse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editMixMatchQty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editRebate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private viewHostData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
private editWarehousePricing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Public observable for the 'editProfile' BehaviorSubject, allowing
  // external components to subscribe to its changes.
  editProdGroups$ = this.editProdGroups.asObservable();
  editProducts$ = this.editProducts.asObservable();
  editPromotionGroups$ = this.editPromotionGroups.asObservable();
  editPromotionPeriods$ = this.editPromotionPeriods.asObservable();
  editPromotionNames$ = this.editPromotionNames.asObservable();
  editBuyingGroups$ = this.editBuyingGroups.asObservable();
  editSlotTypes$ = this.editSlotTypes.asObservable();
  editEvents$ = this.editEvents.asObservable();
  editStatus$ = this.editStatus.asObservable();
  editPromotionTypes$ = this.editPromotionTypes.asObservable();
  editStorePositions$ = this.editStorePositions.asObservable();
  editAllowances$ = this.editAllowances.asObservable();
  editMediaTypes$ = this.editMediaTypes.asObservable();
  editProductCategories$ = this.editProductCategories.asObservable();
  editPromotionCategories$ = this.editPromotionCategories.asObservable();
  editSuppliers$ = this.editSuppliers.asObservable();
  editMixMatch$ = this.editMixMatch.asObservable();
  editProductSelection$ = this.editProductSelection.asObservable();
  editPromotionRebates$ = this.editPromotionRebates.asObservable();
  editWarehouse$ = this.editWarehouse.asObservable();
  editMixMatchQty$ = this.editMixMatchQty.asObservable();
  editRebate$ = this.editRebate.asObservable();
  viewHostData$ = this.viewHostData.asObservable();
  editWarehousePricing$ = this.editWarehousePricing.asObservable();


  /**
   * Updates the 'popup bool' BehaviorSubject with a new value.
   *
   * @param {boolean} value - The new value to update the '_editProfile' state.
   */
  seteditProdGroups(value: boolean) {
    this.editProdGroups.next(value);
  }
  seteditProducts(value: boolean) {
    this.editProducts.next(value);
  }
  seteditPromotionGroups(value: boolean) {
    this.editPromotionGroups.next(value);
  }
  seteditPromotionPeriods(value: boolean) {
    this.editPromotionPeriods.next(value);
  }
  seteditPromotionNames(value: boolean) {
    this.editPromotionNames.next(value);
  }
  seteditSlotTypes(value: boolean) {
    this.editSlotTypes.next(value);
  }
  seteditEvents(value: boolean) {
    this.editEvents.next(value);
  }
  seteditStatus(value: boolean) {
    this.editStatus.next(value);
  }
  seteditPromotionTypes(value: boolean) {
    this.editPromotionTypes.next(value);
  }
  seteditStorePositions(value: boolean) {
    this.editStorePositions.next(value);
  }
  seteditBuyingGroups(value: boolean)
  {
    this.editBuyingGroups.next(value);
  }
  seteditAllowances(value: boolean)
  {
    this.editAllowances.next(value);
  }
  seteditMediaTypes(value: boolean)
  {
    this.editMediaTypes.next(value);
  }
  seteditProductCategories(value: boolean)
  {
    this.editProductCategories.next(value);
  }
  seteditPromotionCategories(value: boolean)
  {
    this.editPromotionCategories.next(value);
  }
  seteditSuppliers(value: boolean)
  {
    this.editSuppliers.next(value);
  }
  seteditMixMatch(value: boolean)
  {
    this.editMixMatch.next(value);
  }
  seteditProductSelection(value: boolean)
  {
    this.editProductSelection.next(value);
  }
  seteditPromotionRebates(value: boolean)
  {
    this.editPromotionRebates.next(value);
  }
  seteditWarehouse(value: boolean)
  {
    this.editWarehouse.next(value);
  }
  seteditMixMatchQty(value: boolean)
  {
    this.editMixMatchQty.next(value);
  }
  seteditRebate(value: boolean)
  {
    this.editRebate.next(value);
  }
  setviewHostData(value: boolean)
  {
    this.viewHostData.next(value);
  }
  seteditWarehousePricing(value: boolean)
  {
    this.editWarehousePricing.next(value);
  }
}
