import { Injectable } from '@angular/core';

export class AllowanceType {
  type: string;
}

const allowanceTypes: AllowanceType[] = [
  {type: 'Allowance'},
  {type: 'Rebate'}
];

@Injectable({
  providedIn: 'root'
})
export class AllowanceTypesService {

  constructor() { }
  getAllowanceTypes() {
    return allowanceTypes;
  };
}
