import { Component,ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { PromotionGroup } from 'src/app/models/promotion-group.model';
import { firstValueFrom } from 'rxjs';
import { LocalDataService } from 'src/app/services/local-data.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
@Component({
  selector: 'app-promotion-groups',
  templateUrl: './promotion-groups.component.html',
  styleUrls: ['./promotion-groups.component.css']
})
export class PromotionGroupsComponent {
  dataSource: any;
  promotionCategories: any;
  suppliers: Supplier[];
  editPromotionGroups:boolean;
  private apiUrl: string = environment.baseApiUrl;
  @ViewChild("promotionGroupGrid", { static: false }) public dataGrid: DxDataGridComponent;
  
  constructor(private crudService: CrudService,
    private localData: LocalDataService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService) {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        const suppliers = await this.crudService.getData(`${this.apiUrl}suppliers`).toPromise();
        this.suppliers = suppliers;
        localStorage.setItem('suppliers', JSON.stringify(suppliers));
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}promotion-groups?get_all=1`).subscribe((result: any) => {
            localStorage.setItem('promotion-groups', JSON.stringify(result.data));
            resolve(result);
          });
        });
    },
      insert: async (values: PromotionGroup) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        console.log(values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}promotion-groups`, values)
          .subscribe((result: any) => {
            this.localData.addData('promotion-groups', values);
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
          const result: any = await this.crudService.updateData(`${this.apiUrl}promotion-groups`, key, values)
          .subscribe((result: any) => {
            this.localData.updateData('promotion-groups', key, values);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}promotion-groups`, key)
          .subscribe((result: any) => {
            this.localData.removeData('promotion-groups', key);
            resolve(result);
          });
        });
      }
    })

    this.horizontalMenuService.editPromotionGroups$.subscribe(async (value: boolean) => {
      this.promotionCategories =  await this.crudService.getData(`${this.apiUrl}planning-categories`).toPromise();
      this.editPromotionGroups = value;
    });
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionGroups(value))
  }   
}

export class Supplier
{
  supplier_code: string;
  supplier_name: string;
}
