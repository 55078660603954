
<dx-data-grid #productsGrid
  [dataSource]="dataSource"
  [remoteOperations]="{ groupPaging: true }"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  (onEditorPreparing)="onEditorPreparing($event)"
  keyExpr="id"
  height="95vh"
>
<dxo-scrolling mode="virtual"></dxo-scrolling>
<!-- <dxo-paging [pageSize]="100"></dxo-paging> -->
<dxo-sorting mode="multiple"></dxo-sorting>
<!-- <dxo-filter-row [visible]="true"></dxo-filter-row>
<dxo-header-filter [visible]="true"></dxo-header-filter> -->
<!-- <dxo-group-panel [visible]="true"></dxo-group-panel> -->
<dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
<dxo-search-panel [visible]="true"></dxo-search-panel>
<dxo-toolbar>
  <!-- <dxi-item><dx-button hint="Import Product File" icon="download" [disabled]="importDisabled" (onClick)="importProductFile()"></dx-button></dxi-item> -->
  <dxi-item name="addRowButton"></dxi-item>
  <dxi-item><dx-button icon="refresh" (onClick)="refreshDataGrid()"></dx-button></dxi-item>
  <dxi-item name="searchPanel"></dxi-item>
  <!-- <dxi-item name="groupPanel"></dxi-item> -->
</dxo-toolbar>
<dxo-editing
    mode="popup"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
    <dxo-popup
        title="Add Product"
        [showTitle]="true"
        width="50%"
        height="60%"
    ></dxo-popup>
    <dxo-form>
        <dxi-item itemType="group" [colCount]="5" [colSpan]="2">
            <dxi-item dataField="product_code" [colSpan]="1"></dxi-item>
            <dxi-item dataField="product_name" [colSpan]="4"></dxi-item>
            <dxi-item dataField="supplier_code" [colSpan]="1"></dxi-item>
            <dxi-item dataField="supplier_name" [colSpan]="4"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="6" [colSpan]="2">
            <dxi-item dataField="product_group_code" [colSpan]="2"></dxi-item>
            <!-- <dxi-item dataField="product_group_name" [colSpan]="2"></dxi-item> -->
            <dxi-item dataField="carton_quantity" [colSpan]="2"></dxi-item>
            <dxi-item dataField="luc_ex_gst" [colSpan]="2"></dxi-item>
            <dxi-item dataField="cost_inc_gst" [colSpan]="2"></dxi-item>
            <!-- <dxi-item dataField="sell_price" [colSpan]="2"></dxi-item>
            <dxi-item dataField="gp_percent" [colSpan]="2"></dxi-item>
            <dxi-item dataField="gp_amount" [colSpan]="2"></dxi-item> -->
        </dxi-item>
    </dxo-form>
  </dxo-editing>
  <dxi-column dataField="product_code" dataType="string" caption="Code">
    <dxi-validation-rule type="required" message="Product code is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="product_name" dataType="string" caption="Name" width="250">
    <dxi-validation-rule type="required" message="Product name is required"></dxi-validation-rule>
  </dxi-column>
<dxi-column dataField="supplier_code" dataType="string" caption="Supplier Code">
  <dxi-validation-rule type="required" message="Supplier code is required"></dxi-validation-rule>
</dxi-column>
  <dxi-column dataField="supplier_name" dataType="string" caption="Supplier Name" width="250"></dxi-column>
  <dxi-column dataField="product_group_name" dataType="string" caption="Group Name" [groupIndex]="0"></dxi-column>
  <dxi-column dataField="product_group_code" dataType="string" caption="Product Group" width="250">
    <dxo-lookup [dataSource]="product_groups" displayExpr="product_group_name" valueExpr="product_group_code"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="carton_quantity" dataType="integer" [allowSearch]="false" caption="Carton Qty"></dxi-column>
  <dxi-column dataField="packs_per_carton" dataType="integer" [allowSearch]="false" caption="Per CTN"></dxi-column>
  <dxi-column dataField="sales_unit" dataType="integer" [allowSearch]="false" caption="Per Unit"></dxi-column>
  <dxi-column dataField="luc_ex_gst" [format]="{type: 'currency', precision: 2}" [allowSearch]="false" caption="LUC"></dxi-column>
  <dxi-column dataField="cost_ex_gst"[format]="{type: 'currency', precision: 2}" [allowSearch]="false" caption="Cost"></dxi-column>
  <!-- <dxi-column dataField="sell_price" dataType="float" caption="Sell Price"></dxi-column>
  <dxi-column dataField="gp_percent" [format]="{type: 'fixedPoint', precision: 2}" minWidth="80" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="gp_amount" [format]="{type: 'currency', precision: 2}" minWidth="80" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column> -->
  <dxi-column dataField="id" dataType="integer" [allowSearch]="false" [visible]="false"></dxi-column>
  <dxo-summary>
    <dxi-group-item column="product_group_name" summaryType="count"> </dxi-group-item>
  </dxo-summary>
</dx-data-grid>
