import { ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { PromotionPeriod } from '../models/promotion-period.model';
import { PromotionData } from '../models/promotion-data.model';
import { SlotTypes } from '../models/slot-types.model';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';
import { DxDataGridComponent, DxTooltipComponent } from 'devextreme-angular';
import { EventsService, Event } from '../services/events.service';
import { WeekRangeValues } from '../week-range-selector/week-range-selector.component';
import { Values } from '../promotion-header/promotion-header.component';
import { on } from "devextreme/events";
import { ConfirmationService } from '../services/confirmation.service';
import CustomStore from 'devextreme/data/custom_store';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifyService } from '../services/notify.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ConfigService } from '../services/config.service';
import { DateService } from '../services/date.service';


@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css']
})

export class DataGridComponent implements OnInit {

  promotionPeriodData: PromotionPeriod[] = [];
  promotionGroupsData: PromotionData[] = [];
  slotTypes: SlotTypes[] = [];
  columns: Array<any> = [];
  tooltipData: any[] = [];
  toolTipText: string;
  tooltipTarget: string = '';
  tooltipHoverTarget: string = '';
  events: Event[] = [];
  startDates: any[] = [];
  calenderWeeks: any[] = [];
  activated: string = '';
  storageKey: string = "slottingState";
  gridBuilt: boolean = false;
  suppliers: Supplier[] = [];
  cloneSlottingData: boolean = false;
  importPromotionData: boolean = false;
  promotion_name: string = '';
  promotion_id: number = 0;
  filteredData:any;
  currentFilter: any;
  currentToggleState: ToggleState = ToggleState.ShowAll;
  showFilterRow: boolean | null | undefined = false;
  showVerticalLines: boolean = false;
  hasChanges: boolean = false;
  selectedDayIndex: any;
  dataStartDate: any;
  toggleStateItems = [
    { id: ToggleState.ShowAll, name: 'Show All' },
    { id: ToggleState.ShowWith, name: 'Slotted' },
    { id: ToggleState.ShowWithout, name: 'Unused' }
];


  private apiUrl : string = environment.baseApiUrl;
  @ViewChild('gridContainer', { static: false }) gridContainer: DxDataGridComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild('toolTipHover', { static: false }) toolTipHover: any;
  selectedItemKeys: any;
  promotionDataMain: any;
  storedPromotionNames: any;

  weekSelector: boolean = false;
    buttonOptions: any = {
        icon: "menu",
        hint:"Select Date Range",
        onClick: () => {
            this.weekSelector = !this.weekSelector;
            this.cdr.detectChanges();
        }
    }
promotionHeaders: Values[] =[];
  constructor(private crudService: CrudService,
              private eventService: EventsService,
              private confirmationService: ConfirmationService,
              private cdr: ChangeDetectorRef,
              private notifyService: NotifyService,
              private configService: ConfigService,
              private dateService: DateService) {


                this.currentFilter = 'auto';

              }

  async ngOnInit(): Promise<void> {
    console.log('init data grid');
    this.selectedItemKeys = [ToggleState.ShowAll];
    const storedData = localStorage.getItem('promotion-headers');
    if (storedData) {
      this.promotionHeaders = JSON.parse(storedData);
    } else {
      const result = await this.crudService.getData(this.apiUrl + 'promotion-headers').toPromise();
      localStorage.setItem('promotion-headers', JSON.stringify(result));

    }
    if(localStorage.getItem('promotion-names') !== null) {
      this.storedPromotionNames = localStorage.getItem('promotion-names');
    } else {
      this.crudService.getData(`${this.apiUrl}promotion-names`).subscribe((result: any) => {
        this.storedPromotionNames = result.data;
        localStorage.setItem('promotion-names', JSON.stringify(result.data));
      });
    }
    this.getConfigString('WeekStartIndex');
    this.dataStartDate = await this.getDataStartDate();
    console.log("selectedDayindex:",this.selectedDayIndex);
    console.log(typeof(this.selectedDayIndex))
    await this.buildGrid();
    this.getCustomStore(this.apiUrl);
  }

  async getDataStartDate() {
    let startDate = this.dateService.twoWeeksAgo(new Date());
    const config = await this.configService.GetConfigByKey('DataStartDate');
    if(config.length !== 0){
      startDate =  config[0].config_string;
    }
    return startDate;
  }

  async getConfigString(config: string) {
    try {
        // Fetching configuration data
        const response = await this.configService.GetConfigByKey(config);

        // Check if the response array is not empty and has the property
        if (response.length > 0 && response[0].config_string) {
            this.selectedDayIndex =  parseInt(response[0].config_string);

        } else {
            console.log('No config found or missing config_string');
            this.selectedDayIndex = '';
        }
    } catch (error) {
        console.error('Failed to get config string:', error);
    }
  }
  // Function to toggle the filter row visibility
  toggleFilterRow(): void {
    this.showFilterRow = !this.showFilterRow;
    this.clearFilter();
  }
  showOnlyPromotionPeriodData: boolean = false;

  async applyFilter() {

    // Load data from the CustomStore
   await this.promotionDataMain.load().then((data: PromotionData[]) => {
      this.promotionGroupsData = data; // Set the promotionGroupsData here
    }).catch((error: any) => {
      console.error("Error loading data: ", error);
      this.filteredData = [];
    });
    // Extract week numbers from promotionPeriodData and prefix with 'w'
    const validWeekNumbers = new Set<string>();

    this.promotionPeriodData.forEach(period => {
      period.week_number.forEach(week => {
        validWeekNumbers.add('w' + week.number);
      });
    });
    const weekNumbersArray = Array.from(validWeekNumbers) as string[];
    switch(this.currentToggleState) {
      case ToggleState.ShowWith:
        this.filteredData = this.promotionGroupsData.filter(row =>
          weekNumbersArray.some(weekNum => Boolean((row as any)[weekNum])
          )
        );
        break;
      case ToggleState.ShowWithout:
        this.filteredData = this.promotionGroupsData.filter(row =>
          weekNumbersArray.every(weekNum => !(row as any)[weekNum])
        );
        break;
      default: // ShowAll
      this.promotionDataMain.load().then((data: any) => {
      this.filteredData = data;
      });
        break;
    }
  }
  selectedDropDownItem: any = null;
  onDropdownSelectionChanged(e:any) {
    this.selectedDropDownItem = e.item;
    this.currentToggleState = e.item.id;

    this.applyFilter();
}

  clearFilter() {
    this.gridContainer.instance.clearFilter();
  }
  canDeactivate(): Promise<boolean> {
    if (this.gridContainer && this.gridContainer.instance.hasEditData()) {
      return this.confirmationService.confirmUnsavedChangesNavigation();
    } else {
      // if(this.hasChanges) {
      //   console.log('refresh-planning-data');
      //   this.crudService.getData(`${this.apiUrl}refresh-planning-data`).toPromise();
      // }
      return Promise.resolve(true);
    }
  }

  openCloneSelection() {
    this.cloneSlottingData = !this.cloneSlottingData;
  }

  enableVerticalLines() {
    this.showVerticalLines = !this.showVerticalLines;
  }

  loadState()
  {
    console.log('loading state');
    if(localStorage.getItem(this.storageKey) !== null) {
      return JSON.parse(localStorage.getItem(this.storageKey) || '');
    }
  }

  saveState = (state: any) => {
    if(this.gridBuilt) {
      if (state) {
          const numberToRemove = state.columns.length - 4
          state.columns.splice(4, numberToRemove);
      }
      console.log('SaveState');
      localStorage.setItem(this.storageKey, JSON.stringify(state));
    }
  }

  clearData(): void
  {
    this.promotionPeriodData = [];
    this.promotionGroupsData = [];
    this.slotTypes = [];
    this.columns = [];
  }
  setTimeouts(e:any){
    e.component.option('scrolling.updateTimeout', 1500);
  }
  async buildGrid(weekRangeValues?: WeekRangeValues)
  {
    if(weekRangeValues) {
      this.clearData();
      const promotionPeriodData: PromotionPeriod[] = await this.crudService.getData(`${this.apiUrl}promotion-periods?start_date=${weekRangeValues.startWeek}&number_of_weeks=${weekRangeValues.numberOfWeeks}`).toPromise();
      this.promotionPeriodData = promotionPeriodData;
    } else if(localStorage.getItem('weekRangeValues') !== null) {
      this.clearData();
      const weekRangeValues: WeekRangeValues = JSON.parse(localStorage.getItem('weekRangeValues') || '{}');
      const promotionPeriodData: PromotionPeriod[] = await this.crudService.getData(`${this.apiUrl}promotion-periods?start_date=${weekRangeValues.startWeek}&number_of_weeks=${weekRangeValues.numberOfWeeks}`).toPromise();
      this.promotionPeriodData = promotionPeriodData;
    } else {
      const promotionPeriodData: PromotionPeriod[] = await this.crudService.getData(`${this.apiUrl}promotion-periods`).toPromise();
      this.promotionPeriodData = promotionPeriodData;
    }
    localStorage.setItem('promotion-periods', JSON.stringify(this.promotionPeriodData));
    const slotTypesData: SlotTypes[] = await this.crudService.getData(`${this.apiUrl}slot-types`).toPromise();
    const slotTypesWithCodeName = slotTypesData.map((slot) => {
      const codeName = slot.code + ' - ' + slot.name;
      console.log('slot types');
      return { ...slot, codeName };
    });
    this.slotTypes = slotTypesWithCodeName;
    this.crudService.getData(`${this.apiUrl}calendar-weeks?start_date=${this.dataStartDate}`).subscribe((data:any) => {
      this.calenderWeeks = data;
      this.cdr.detectChanges(); // Update the view
    });
    //this.calenderWeeks = JSON.parse(localStorage.getItem('calender-weeks') || '[]');
    await this.getEvents();
    await this.addColumn();
    this.gridBuilt = true;
  }

  getCustomStore(apiUrl: string) {
    this.promotionDataMain = new CustomStore<any>({
      load: async (options) => {
        const take = 5000; // Set the number of items to take per request
        let skip = 0;
        let result: any[] = [];
        let total = 0;

        do {
          const data = await this.crudService
            .getData(`${apiUrl}planning-data`, { skip, take })
            .toPromise();
          //console.log('data', data);
          result = result.concat(
            data.data.map((item: any, index: number) => ({ index, ...item }))
          );
          skip += take;
          total = data.total;
        } while (total >= take);
        //console.log('result', result);
        return result;
      },
    });

    // Load data from the CustomStore
    this.promotionDataMain.load().then((data: any) => {
      this.promotionGroupsData = data; // Set the promotionGroupsData here
      this.filteredData = data; // update filteredData
      let state = this.loadState();
      this.gridContainer.instance.state(state);
      this.gridBuilt = true;
    }).catch((error: any) => {
      console.error("Error loading data: ", error);
      this.filteredData = [];
    });
  }

  async getEvents() {
    this.events = await this.eventService.getEvents(20240101, 20260101); // These dates will need to be supplied when the date range filter is implemented.
  }
  selectedValue: any = null; // initially set to null or any default value

  get dropdownButtonText(): string {
    switch (this.currentToggleState) {
      case ToggleState.ShowWith:
        return 'Slotted';
      case ToggleState.ShowWithout:
        return 'Unused';
      default:
        return 'Show All';
    }
  }
 reloadGrid(weekRangeValues: WeekRangeValues)
  {
    this.weekSelector = false;
    this.gridBuilt = false;
    this.clearData();
    localStorage.setItem('weekRangeValues', JSON.stringify(weekRangeValues));
    console.log("wrv", weekRangeValues);
     this.buildGrid(weekRangeValues);
  }

  closeCloneSelection() {
    this.cloneSlottingData = false;
    this.buildGrid();
  }

  closeImportSelection() {
    this.importPromotionData = false;
  }

  async onRowInserting(values: any) {
    const userDetails = localStorage.getItem('userDetails');
    const parsedDetails = JSON.parse(userDetails || '{}');
    values.group_id = parsedDetails.data.group_id;
    values.location_id = parsedDetails.data.location_id;
    return await new Promise(async (resolve) => {
      const result: any = await this.crudService.insertData(`${this.apiUrl}planning-data`, values)
      .subscribe((result: any) => {
        resolve(result);
      });
    });
  }

  async onRowUpdating(e: any) {
    // const updates = [];

    // const body = {
    //   buying_group_id: e.oldData.buying_group_id,
    //   promo_group_id: e.oldData.promo_group_id,
    //   group_id: e.oldData.group_id,
    //   location_id: e.oldData.location_id,
    //   period_weeks_id: 0,
    //   slot_type: ''
    // };

    // const keys = Object.keys(e.newData);

    // for (const key of keys) {
    //   const week_id = this.columns.find(c => c.dataField === key);
    //   if(week_id) {
    //     const fieldName: string = week_id.dataField;
    //     body.period_weeks_id = week_id.caption;
    //     body.slot_type = e.newData[fieldName];
    //   }

    //   const updatePromise = new Promise(async (resolve) => {
    //     const result: any = await this.crudService.updateData(`${this.apiUrl}planning-data2`, 1, body).toPromise();
    //     resolve(result);
    //   }).catch((error: any) => {;
    //     console.log('updatePromise', error);
    //   });

    //   updates.push(updatePromise);
    // }

    // // Wait for all updates to complete before executing the final line
    // await Promise.all(updates);

    // // Now, execute the final line
    // console.log('refresh-planning-data');
    // await this.crudService.getData(`${this.apiUrl}refresh-planning-data`).toPromise();
  }


  addColumn() {
    this.startDates = [];
    this.promotionPeriodData.forEach(period => {
        this.columns.push({
            caption: period.promotion_name,
            isBand: true,
            headerTemplate: 'promoTemplate',
            name: period.id,
            dataType: 'string',
            allowReordering: false
        });

        const periodStartDates = period.week_number.map(week => ({
            week_id: week.id,
            start_date: this.getStartDateById(week.id) // start here for issue week dates
        }));

        const periodColumns = period.week_number.map(week => ({
            allowReordering: false,
            caption: week.id,
            minWidth: 80,
            ownerBand: period.promotion_name,
            allowEditing: true,
            dataField: 'w' + week.number,
            name: this.getStartDateById(week.id),
            dataType: 'string',
            lookup: {
                dataSource: this.slotTypes,
                valueExpr: 'code',
                displayExpr: 'codeName',
                allowClearing: true
            },
            cellTemplate: 'cellTemplate',
            headerTemplate: 'headerTemplate',
            alignment: 'center',
            cssClass: 'week-header',
        }));
        this.startDates = [...this.startDates, ...periodStartDates];
        this.columns = [...this.columns, ...periodColumns];
    });
}
  hasMatchingPromotion(promotion_name: string)
  {
    let hasMatch = false;
    let promotionStatus: string | null = null;
    // Find the promotion name object based on the promotion_name
    if(localStorage.getItem('promotion-names') !== null) {
      const storedPromotionNames: any = localStorage.getItem('promotion-names');
    } else {
      this.crudService.getData(`${this.apiUrl}promotion-names`).subscribe((result: any) => {
        const storedPromotionNames: any = result.data;
        localStorage.setItem('promotion-names', JSON.stringify(result.data));
      });
    }
    const storedPromotionNames = localStorage.getItem('promotion-names');
    const promotion_names: PromotionPeriod[] = storedPromotionNames ? JSON.parse(storedPromotionNames) : [];
    //console.log('promotion_names', promotion_names);
    const foundPromotion = promotion_names.find(promotion => promotion.promotion_name === promotion_name);

    if (foundPromotion) {
      // Check if any promotion header has the same promotion_period_id as the found promotion's id
      const storedData = localStorage.getItem('promotion-headers');
      const promotion_headers: Values[] = storedData ? JSON.parse(storedData) : [];
      hasMatch = promotion_headers.some(header => header.promotion_period_id === foundPromotion.id);
      const matchingHeader = promotion_headers.find(header => header.promotion_period_id === foundPromotion.id);
      if (matchingHeader) {
        hasMatch = true;
        promotionStatus = matchingHeader.status_type;
      }
    }

    return [hasMatch, promotionStatus];
  }
  onCellPrepared(e:any) {
    if(e.column.headerCellTemplate === 'promoTemplate') {
      const [isMatch, status] = this.hasMatchingPromotion(e.column.caption);
      if(e.column.index >= 3 && e.column.isBand && isMatch) {
        if(status === 'open' ){ //Open Status
          e.cellElement.style.setProperty('background-color', '#a9ff6663', 'important');
        }
        if(status === 'restricted' ){ //Review Status
          e.cellElement.style.setProperty('background-color', '#ffea668c', 'important');
        }
        if(status === 'closed' ){ //Confirmed Status
          e.cellElement.style.setProperty('background-color', '#ff585894', 'important');
        }
      }
      let tooltipTimeout: any;
      if (e.column.index >= 3 && e.column.isBand) {
          on(e.cellElement, "mouseover", (arg: any) => {
              if (isMatch) {
                  switch (status) {
                      case 'open': this.toolTipText = "Activated"; break;
                      case 'restricted': this.toolTipText = "In Review"; break;
                      case 'closed': this.toolTipText = "Finalised"; break;
                      default: this.toolTipText = "Right Click To Deactivate"; break;
                  }
              } else {
                  this.toolTipText = "Right Click To Activate";
              }

              // the tooltipHoverTarget tooltip for headers
              if (e.cellElement.id) {
                this.tooltipHoverTarget = '#' + e.cellElement.id;
            }

              let mouseX = arg.clientX;
              let mouseY = arg.clientY;

      // Adjust position of tooltip
      if (this.toolTipHover && this.toolTipHover.instance) {
          // offset so the tooltip doesn't cover the cursor
          this.toolTipHover.instance.option('position', {
            my: 'left top',
            at: 'right top',
            offset: '10 0',
            collision: 'fit',
            boundaryOffset: '10 10',
            of: arg
        });
        tooltipTimeout = setTimeout(() => {
          this.toolTipHover.instance.show(arg.target);
      },1000);
      }
    });
          on(e.cellElement, "mouseout", (arg: any) => {
            clearTimeout(tooltipTimeout);
              this.toolTipHover.instance.hide();
          });
      }
  }
}
  contextMenuItems(e: any)
  {
    if(e.column.index >= 3 && e.column.isBand) {
      const [isMatch] = this.hasMatchingPromotion(e.column.caption);
      if (!isMatch) {
          if (!e.items) {
              e.items = [];
          }
          e.items.push({
              text: 'Activate Promotion',
              onItemClick: () => { this.contextMenuClick(e.column) }
          });
      } else if(isMatch){
          if (!e.items) {
              e.items = [];
          }
          e.items.push({
              text: 'Already Activated'
          });
      }
    }
  }

   async contextMenuClick(column: any)
  {
    this.importPromotionData = !this.importPromotionData;
    this.promotion_name = column.caption;
    this.promotion_id = column.name;
  }

  getStartDateById(idToFind: number): string | undefined {
    const foundObject = this.calenderWeeks.find(week => week.id === idToFind);
    return foundObject ? this.formatDateDayMonth(foundObject.start_date) : undefined;
  }

  getSlotTypeColour(code: string): string {
    const slotType = this.slotTypes.find(type => type.code === code);
    return slotType ? slotType.colour : '';
  }

  hasEvent(week_id: number): string {
    let show = 'hidden';
    const hasEvent = this.events.some((event: Event) => {
      return event.week_id === week_id && event.editable == 0;
    });

    if (hasEvent) {
      show = 'visible';
    }
  return show;
  }

  hasCustomEvent(week_id: number): string {
    let show = 'hidden';
    const hasEvent = this.events.some((event: Event) => {
      return event.week_id === week_id && event.editable == 1;
    });

    if (hasEvent) {
      show = 'visible';
    }
  return show;
  }

  refreshDataGrid() {
    this.gridContainer.instance.refresh();
  }

  eventData(e: any) {
    if (e.rowType === "header" && this.events.find(event => event.week_id === e.column.caption)) {
      on(e.cellElement, "mouseover", (arg: any) => {
        this.tooltipData = this.events.filter(event => event.week_id === e.column.caption);
        this.tooltipTarget = '#'+e.cellElement.id;
        this.tooltip.instance.show(arg.target);
      });

      on(e.cellElement, "mouseout", (arg: any) => {
        this.tooltip.instance.hide();
      });
    }
  }

  formatDateDayMonth = (date: string): string =>
  {
    //const selectedDayIndex: number = parseInt(localStorage.getItem('selectedDayIndex') || '0');
    //const selectedDayIndex: any = this.configService.GetConfigByKey('WeekStartIndex');

    const dateParts = date.split('-');
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      const new_date = new Date(year, month, day);
      new_date.setDate(new_date.getDate() + this.selectedDayIndex);
      const formattedDate = new_date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit" });
      return formattedDate;
    }
    return '01-01-2023';
  }

  formatDate(integerDate: number): string {
    const dateStr = integerDate.toString();
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    return `${day}-${month}-${year}`;
  }

  styleLookup(e: any)
  {

        if(e.index > 3){
    if (e.parentType == "dataRow" && e.editorName == 'dxSelectBox')
    e.editorOptions.onOpened = function (e: any) {
      e.component._popup.option('width', '150');
    };


    e.editorOptions.onValueChanged = function (ev: any) {
      let selectedItem = ev.component.option("selectedItem");
      if(ev.value) {
        let data: slottingData = {
          weekId: e.caption,
          weekName: e.dataField,
          code: selectedItem.code,
          spanPromotion: selectedItem.span_promotion,
          weekCount: selectedItem.week_count || 1,
        }
          e.setValue(data);
        }
        else {
          e.setValue(e.dataField);
        }
      };
    }

}


  setCellValue = (rowData: any, value: any, currentRowData: any) => {
    console.log('value', value);
    if(typeof value === 'string') {
      rowData[value] = null;
      this.autoSave(rowData, currentRowData);
      return;
    }
    let weekId = value.weekId;
    console.log('weekId', weekId);
    let selectedWeek = parseInt(value.weekName.slice(1), 10);
    console.log('selectedWeek', selectedWeek);
    let weekIdsInPromotion = this.getWeekNumberArray(weekId);
    console.log('weekIdsInPromotion', weekIdsInPromotion);
    for(let i = 0; i< value.weekCount; i++) {
      let weekIdExists: boolean = this.doesIdExist(weekIdsInPromotion, weekId);
      let columnName = 'w' + (selectedWeek);
      if(weekIdExists === true) {
        rowData[columnName] = value.code;
      } else if(weekIdExists === false && value.spanPromotion === 1){
        rowData[columnName] = value.code;
      }
      if(selectedWeek === 52) {
        selectedWeek = 0;
      }
      selectedWeek++;
      weekId++;
    }
    this.autoSave(rowData, currentRowData);
  }

  async autoSave(rowData: any, currentRowData: any) {
    const updates = [];
    //this.hasChanges = true;
    console.log('rowData', rowData);
    console.log('currentRowData', currentRowData);

    const body = {
      buying_group_id: currentRowData.buying_group_id,
      promo_group_id: currentRowData.promo_group_id,
      group_id: currentRowData.group_id,
      location_id: currentRowData.location_id,
      period_weeks_id: 0,
      slot_type: ''
    };

    const keys = Object.keys(rowData);
    console.log('key', keys);
    for (const key of keys) {
      console.log('key', key);
      const week_id = this.columns.find(c => c.dataField === key);
      if(week_id) {
        const fieldName: string = week_id.dataField;
        body.period_weeks_id = week_id.caption;
        body.slot_type = rowData[fieldName];
      }

      const updatePromise = new Promise(async (resolve, reject) => {
        console.log('updatePromise', body);
        this.crudService.updateData(`${this.apiUrl}planning-data`, 1, body)
            .pipe(
                catchError((error: any) => {
                    this.notifyService.tellUser(error);
                    console.error('Resource not found:', error);
                    return throwError(error);
                    })
            )
            .subscribe(
                result => {
                    resolve(result);
                },
                error => {
                    // Handle other errors here if needed
                    this.notifyService.tellUser(error);
                    console.error('An error occurred:', error);
                    reject(error);
                }
            );
        });

      updates.push(updatePromise);
    }

    // Wait for all updates to complete before executing the final line
    await Promise.all(updates);

    // Now, execute the final line
    //console.log('refresh-planning-data');
    this.gridContainer.instance.saveEditData();
    //await this.crudService.getData(`${this.apiUrl}refresh-planning-data`).toPromise();
    return;
  }

  getWeekNumberArray(weekId: number): Array<any>
  {
    const promotions: any = JSON.parse(localStorage.getItem('promotion-periods') || '');
    console.log('promotions', promotions);
    const foundItem = promotions.find((promotion: any) => promotion.week_number.some((week: any) => week.id === weekId));
    return foundItem ? foundItem.week_number : [];
  }

  doesIdExist(weekNumberArray: Array<any>, targetId: number): boolean
  {
    return weekNumberArray.some(week => week.id === targetId);
  }

  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Slotting Board');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if(gridCell!.rowType === 'header' && gridCell!.column!.cssClass === 'week-header') {
          excelCell.value = gridCell!.column!.dataField + ' - ' + gridCell!.column!.name;
        }
        if(gridCell!.rowType === 'data' && gridCell!.value !== undefined && gridCell!.column!.cssClass !== 'text_boxs') {
          const hexColour = this.getSlotTypeColour(gridCell!.value);
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: hexColour.substring(1) } };
          console.log(this.getSlotTypeColour(gridCell!.value));
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
  }

}

export class slottingData
{
  weekId: number;
  weekName: string;
  id?: number;
  code?: string;
  name?: string;
  codeName?: string;
  colour?: string;
  spanPromotion: boolean;
  weekCount: number;
}

export class Supplier
{
  supplier_code: string;
  supplier_name: string;
}
enum ToggleState {
  ShowAll,
  ShowWith,
  ShowWithout
}
