export class PromotionHeader
{
    id: number = 0;
    group_id: number = 0;
    location_id: number = 0;
    created_at: string = '';
    updated_at: string = '';
    promotion_period_id: number = 0;
    buying_group_id: number = 0;
    promotion_type: number = 0;
    description: string = '';
    promotion_start: string = '';
    promotion_end: string = '';
    buying_start: string = '';
    buying_end: string = '';
    status?: number = 0;
    promotion_name?: string = '';
    rebate_clone?: boolean = false;
    edlp: number;
}
