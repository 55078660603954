import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  private refreshGridSource = new Subject<void>();
  // Observable string streams
  refreshGrid$ = this.refreshGridSource.asObservable();

  // Service message commands
  requestRefreshGrid() {
    this.refreshGridSource.next();
  }
}
