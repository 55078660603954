import { Component, Input } from '@angular/core';
import { PromotionDetails } from 'src/app/models/promotion-details.model';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { GroupProductService } from '../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { LoadingService } from '../report-loading.service';
import { map } from 'rxjs';
import { DateService } from 'src/app/services/date.service';
interface Product {
  product_name: string;
  product_id: string;
  carton_quantity: number;
  packs_per_carton: number;
  cost_ex_gst: string;
  has_wet: number;
  allowance_total: number;
  allowance_universal: number;
}

interface Promotion {
  data: Product[];
}
const pdf = pdfMake;
pdf.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-core-range',
  templateUrl: './core-range.component.html',
  styleUrls: ['./core-range.component.css']
})
export class CoreRangeComponent {

  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 1;
  userDetails: any;
  start_date: string;
  end_date: string;
  disclaimer: string;
  constructor(private dateService:DateService,private crudService: CrudService,
              private groupProductService: GroupProductService,
              private base64Image: Base64Images,
              private costService: CostServiceService, private reportStylesService: ReportStylesService, private dateFormatService: DateFormatService, private loadingService: LoadingService)
  {
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');

  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }
  }
pagePromotion: string = '';
promotionIndex: number = 0;



  transformDataForPdfMake(promotions:any, supplier_name: any): Content[] {
   // Group promotions by category_description
  const groupedByCategory = promotions.reduce((acc:any, promotion:any) => {
    const category = promotion.category_description;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(promotion);
    return acc;
  }, {} as {[key: string]: any[]});

  // Prepare the content for the PDF
  const allRows: any[] = [];
  const headers = ['Supplier','Product ID', 'Product Name', 'Carton Qty', 'Sell Price', 'NUC', 'GP%', 'GP$'];
  const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));

  Object.entries(groupedByCategory).forEach(([categoryDescription, promotions]:any) => {
    // Add a category header row
    const productCount = promotions.length;
    allRows.push([{ text: `${categoryDescription} (${productCount} Products)`, style: 'supplierStyle', colSpan: 8, alignment: 'left' }, {}, {}, {}, {}, {}]);
    // Add product rows for each category
    promotions.forEach((promotion:any) => {
      if (promotion) {
 
      const rowData = [
        { text: promotion.supplier_name, style: 'bodyStyle' },
        { text: promotion.product_code, style: 'bodyStyle' },
        { text: promotion.product_name, style: 'bodyStyle' },
        { text: promotion.carton_quantity, style: 'bodyStyle', alignment: 'center' },
        { text: parseFloat(promotion.sell_price).toFixed(2), style: 'bodyStyle', alignment: 'center' },
        { text: (Number((this.costService.calculateNewCost(promotion) ?? 0)) / (Number(promotion.packs_per_carton))).toFixed(2), style: 'bodyStyle', alignment: 'center' },
        { text: this.costService.calculateGpPercent(promotion), style: 'bodyStyle', alignment: 'center' },
        { text: this.costService.calculateGpAmount(promotion).toFixed(2), style: 'bodyStyle', alignment: 'center' },
      ];
      allRows.push(rowData);
    }
    });
  });
  const subheader:Content = {
    stack: [
      {
        columns: [
          {
            text: `${this.name}`,
            style: 'header',
            alignment: 'left',
          },
          {
            image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
            width: 150,
            alignment: 'right',
            margin: [0, 0, 0, 0]
          }
        ]
      }
    ]
  };

  const secondHeader:Content  = {
    stack: [
      {
        columns: [
          {
            text: `All Suppliers`,
            alignment: 'left'
          },
          
          {
            text: `${this.rangeName}`,
            style: 'subheader',
            alignment: 'right',
            margin: [0, 0, 0, 0]
          }
        ]
      }
    ]
  };
  
  const tableContent: Content = {
    table: {
      headerRows: 1,
      widths: ['*',35, '*', 20, 25, 22, 26, 22],
      body: [styledHeaders, ...allRows]
    },
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      hLineColor: () => 'white',
      vLineColor: () => 'white',
      fillColor: function (rowIndex: number, node: any, columnIndex: any) {
        return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
      },
    }
  };

  return [subheader, secondHeader, tableContent]
}


calculateNUC(costExGst:any, allowanceTotal:any, allowanceUniversal:any, hasWet:any) {
  let nuc;
  if (!isNaN(costExGst) && !isNaN(allowanceTotal) && !isNaN(allowanceUniversal)) {
    const baseCost = costExGst - (allowanceTotal + allowanceUniversal);
    nuc = hasWet ? (baseCost + (0.29 * baseCost)).toFixed(2) : baseCost.toFixed(2);
  } else {
    console.log('Invalid numeric values for calculation.');
  }
  return nuc;
}
styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}
pdfCreator(promotions: any[], supplier_name: string) {
  const contentArray: Content[] = this.transformDataForPdfMake(promotions, supplier_name);
  const styles: any = this.reportStylesService.getStyles();

  const documentDefinition: TDocumentDefinitions = {
    content: contentArray,
    pageOrientation: 'portrait',
    pageSize: 'A4',
    styles: styles,
    info: {
      title: `Core Range ${this.promotionName}`,
    },
    footer: (currentPage: number, pageCount: number) => {
      return {

        stack: [
          {
            columns: [
              {
                text: `${this.disclaimer}`,
                style: 'footerDisclaimer',
                fontSize:6
              },

              {
                text: `Promoflo © 2024 OnTap Data Inc. \n
                Created: ${this.dateService.getCurrentDateTime()} \n
                Page ${currentPage} of ${pageCount}`,
                style: 'copyrightFooter',
                fontSize:6

              }
            ]
          }
          ]
      };
  }
  };

  const filename = `Core_Range_${this.promotionName}_${this.filtered_by}.pdf`;
  this.loadingService.toggleLoading(false);
  pdfMake.createPdf(documentDefinition).download(filename);
}
rangeName:string = '';
filtered_by:string = '';
async getPromoData(e: any) {
  if (e.buyingGroup == null) {
      return;
  }
  this.disclaimer = e.disclaimer;
  this.rangeName = e.coreRange.value.range_name;
  this.start_date = this.dateFormatService.changeDateFormat(e.start_date);
  this.end_date = this.dateFormatService.changeDateFormat(e.end_date);
  if (e.buyingGroup != null) {
      this.filtered_by = e.buyingGroupFiltered.buying_group_name;
      this.crudService
          .getData(
              `${this.apiUrl}core-range-details?core_range_header_id=${e.coreRange.value.id}&buying_group_id=${e.buyingGroupFiltered.id}`
          )
          .pipe(
              map((result: any) => {
                  // Check if e.audit is set to true
                  if (e.rebate) { // rebatable selected
                      // Filter the data and return only the values with audit set to true
                      return result.data.filter((item: any) => item.rebate_total > 0);
                  }
                  console.log(result)
                  // If e.audit is not set or set to false, return the original data
                  return result.data.filter((item: any) => item.audit === 1);
              }),
              map((data: any[]) => this.groupProductService.groupByCategory(data)),
              map((groupedData: any) => {
                  console.log("grouped:", groupedData);
                  // Sort products alphabetically within each category
                  for (const key in groupedData) {
                      groupedData[key].items.sort((a: any, b: any) =>
                          this.groupProductService.naturalSorts(a, b, 'product_name')
                      );
                  }
                  return groupedData;
              })
          )
          .subscribe((groupedSortedData: any) => {
              console.log("Grouped and Sorted Data: ", groupedSortedData);

              // Sort the keys of the grouped data based on category_sort_order
              const sortedEntries = Object.entries(groupedSortedData).sort((a: any, b: any) => {
                  return a[1].sort_order - b[1].sort_order;
              });

              console.log("Sorted Entries by sort_order: ", sortedEntries);

              // Flatten all grouped data into one array, but keep track of category descriptions
              const allPromotions = sortedEntries.reduce((acc: any[], [key, value]: any) => {
                  const categoryPromotions = value.items.map((item: any) => ({
                      ...item,
                      category_description: key
                  }));
                  return acc.concat(categoryPromotions);
              }, []);

              console.log("All Promotions with category_description: ", allPromotions);

              // Find the first non-empty category group for core_range_header_id
              let firstCategoryKey: any = sortedEntries.find(([key, value]: any) => value.items.length > 0);
              let firstItem = firstCategoryKey ? firstCategoryKey[1].items[0] : null;

              if (firstItem) {
                  console.log("Creating PDF with all promotions. Using core_range_header_id from:", firstItem);
                  this.pdfCreator(allPromotions, firstItem.core_range_header_id); // Pass all promotions as an array
              } else {
                  console.log("No data available to create PDF.");
              }
          });
  }
}

@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
  }

}
