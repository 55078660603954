<dx-data-grid #mixMatchSelectionGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  height="95vh"
>
    <dxo-selection
      mode="multiple"
    ></dxo-selection>
    <dxo-toolbar>
        <dxi-item location="after">
          <div *dxTemplate>
            <dx-button icon="exportselected" hint="Add selected offers" (onClick)="addItems($event)"></dx-button>
          </div>
        </dxi-item>
        <dxi-item name="searchPanel"></dxi-item>
      </dxo-toolbar>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
  <dxi-column dataField="name" dataType="string" caption="Name" width="15%"></dxi-column>
  <dxi-column dataField="total_price" dataType="number" caption="Total Price"></dxi-column>
  <dxi-column dataField="free_item_id" dataType="number" caption="Free Item"></dxi-column>

</dx-data-grid>
