import { Component, Input } from '@angular/core';
import { PromotionHeader } from 'src/app/models/promotion-header.model';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PageOrientation } from 'pdfmake/interfaces';
import { PageSize } from 'pdfmake/interfaces';
import { StyleDictionary } from 'pdfmake/interfaces';
import { GroupProductService } from '../group-products-service.service';
import { Base64Images } from 'src/app/services/base64-images.service';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import { ReportStylesService } from 'src/app/services/report-styles.service';
import { LoadingService } from '../report-loading.service';
import notify from 'devextreme/ui/notify';
import { DateService } from 'src/app/services/date.service';

const pdf = pdfMake;
pdf.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-promotion-advice',
  templateUrl: './promotion-advice.component.html',
  styleUrls: ['./promotion-advice.component.css']
})
export class PromotionAdviceComponent {
  @Input() name: string;
  @Input() reportComponent: string;
  private promoDetailsUrl: string = environment.baseApiUrl + 'promotion-details';
  private apiUrl: string = environment.baseApiUrl;
  data: any[];
  headers: PromotionHeader[];
  promotionName: string = '';
  pageNumber: number = 0;
  userDetails: any;
  disclaimer: string;
  constructor(private crudService: CrudService,
              private groupProductService: GroupProductService,
              private base64Image: Base64Images,
              private costService: CostServiceService,
              private dateFormatService: DateFormatService,
              private reportStylesService: ReportStylesService,
              private loadingService: LoadingService,
            private dateService:DateService)
  {
    const userDetailsData = localStorage.getItem("userDetails");
    if (userDetailsData) {
      this.userDetails = JSON.parse(userDetailsData || '{}');
  } else {
      // Handle the null case, e.g., set to a default value or log an error
      this.userDetails = {}; // or some other default value
  }
  }
pagePromotion: string = '';
promotionIndex: number = 0;
  transformDataForPdfMake(promotions: any[]): any[] {
    return promotions.flatMap(promotion => {  // Use flatMap to flatten the resulting array
      if(promotion.promotionName  == "Unknown"){
        return
      }
      const headers = [
            '','','Order Code', 'Product Description', 'Pack Qty',
            'Inv Price', 'NUC ex GST', 'Multi Buy', 'Promo Price',
            'GP%', 'Profit', 'Buying Dates', 'Loc', 'Notes'
        ];
        const styledHeaders = headers.map(header => ({ text: header, style: 'tableHeader' }));

        const allRows = promotion.suppliers.flatMap((supplier: any) => {
          // Create a row for the supplier name
          const supplierRow = [
            {},{ colSpan: 13, text: supplier.supplier, style: 'supplierStyle' },
            {}, {},{}, {}, {}, {}, {}, {}, {}, {}, {}, {}
        ];


          // Map over the products of the supplier
          const productRows = supplier.products.map((product: any) => {
            let rebateIndicator = product.hasRebate ? 'R' : '';
            let coreRangeIndicator = product.isCoreRange ? 'F' : '';
            let cost_ex_gst = product.cost_ex_gst;
            if(product.cost_ex_gst === null){
              cost_ex_gst = 0;
            }
            const sell_price_filtered = Number(product.sell_price).toFixed(2);
            return [

                { text: rebateIndicator, style: 'coreRange' },
                { text: coreRangeIndicator, style: 'coreRange' },
                { text: product.ordering_code, style: this.getStyle(product), alignment: 'center' },
                { text: product.product_name, style: 'bodyStyle' },

                { text: product.packs_per_carton, style: 'bodyStyle', alignment: 'center' },
               
                { text: `$${(this.costService.calculateNewCost(product) || 0).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' },
                { text: `$${((this.costService.calculateNewCost(product) || 0) / product.packs_per_carton).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' }, //NUC
                { text: product.multi_buy, style: 'bodyStyle', alignment: 'center' }, // Multi Buy
                { text:`$${sell_price_filtered}`, style: 'bodyStyle', alignment: 'center' },
                { text: this.costService.calculateGpPercent(product), style: 'bodyStyle', alignment: 'center'},// GP%
                { text: `$${this.costService.calculateGpAmount(product).toFixed(2)}`, style: 'bodyStyle', alignment: 'center' }, // Profit
                { text: `${this.dateFormatService.changeDateFormat(product.buying_start)} - ${this.dateFormatService.changeDateFormat(product.buying_end)}`, style: 'bodyStyle', alignment: 'center' },
                { text: product.store_position_name, style: 'bodyStyle', alignment: 'center' },
                { text: product.comment, style: 'bodyStyle', alignment: 'center' }
            ];
        });


          // Return the supplier row followed by its product rows
          return [supplierRow, ...productRows];
      });

const subheader = {
  stack: [
    {
      columns: [
        {
          text: `${this.name}`,
          style: 'header',
          alignment: 'left',
        },
        {
            image: this.base64Image.getBase64Image(this.userDetails.data.location_token),
            width: 150,
            alignment: 'right',
            margin: [0, 0, 0, 0]  // Adjust margin as needed for the image
        }

      ]
    }


    ]
  };

 const secondHeader = {
  stack: [
    {
      columns: [
        {
          text: `${this.headers[this.promotionIndex].promotion_name}`,

          style: 'subheader',
          alignment: 'left',
          margin: [10, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `${promotion.promotionName}`,

          style: 'subheader',
          alignment: 'center',
          margin: [0, 0, 0, 0]  // Adjust margin as needed for the text
        },
        {
          text: `Promotion Dates: ${this.dateFormatService.changeDateFormat(promotion.promotion_start)} - ${this.dateFormatService.changeDateFormat(promotion.promotion_end)}`,
          style: 'subheader',
          alignment: 'right',
          margin: [0, 0, 10, 0]  // Adjust margin as needed for the text
        }
      ]
    }
  ]
 }

  if(this.promotionIndex < (this.headers.length - 1)){
    this.promotionIndex++;
    return [
      subheader,
      secondHeader,
      {
          table: {
              headerRows: 1,
              widths: [ 10,10,30, '*', 30, 30, 30, 30, 30, 30, 30, 90, 'auto', 90 ],
              body: [styledHeaders, ...allRows]
          },
          pageBreak: 'after',
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            hLineColor: () => 'white',
            vLineColor: () => 'white',
            fillColor: function (rowIndex: number, node:any, columnIndex:any) {
              return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
            },

          }
      }
    ];
  
      } else {
        return [
          subheader,
          secondHeader,
          {
              table: {
                  headerRows: 1,
                  widths: [ 10,10,30, '*', 30, 30, 30, 30, 30, 30, 30, 90, 'auto', 90 ],
                  body: [styledHeaders, ...allRows]
              },
              layout: {
                hLineWidth: () => 0,
                vLineWidth: () => 0,
                hLineColor: () => 'white',
                vLineColor: () => 'white',
                fillColor: function (rowIndex: number, node:any, columnIndex:any) {
                  return (rowIndex % 2 === 0) ? '#d9f0f1' : null;
                },

          }
        }

      ];
      }
    });

}

styleRow(row: any[], style: string): any[] {
  return row.map(cell => {
      // If the cell is an object, merge the style with the existing properties
      if (typeof cell === 'object' && cell !== null) {
          return { ...cell, style: style };
      }
      // If the cell is a simple value, create a new object with text and style
      else {
          return { text: cell, style: style };
      }
  });
}

getStyle(product: any): string {
  if(product.edlp === 1){
    return 'edlpStyle';
  }else {
    return 'bodyStyle';
  }
}

pdfCreator(promotions: any[]) {

  const transformedDataArray = this.transformDataForPdfMake(promotions);
  this.promotionIndex = 0;
  const styles: any = this.reportStylesService.getStyles();

  const documentDefinition = {
      content: [...transformedDataArray],
      pageOrientation: 'landscape' as PageOrientation,
      pageSize: 'A4' as PageSize,
      styles: styles,
      border: [false,false,false,false],
      info: {
        title: `Promotion Advice ${this.promotionName}`,
      },
      footer: (currentPage: number, pageCount: number) => {
        return {

          stack: [
            {
              columns: [
                {
                  text: `${this.disclaimer}`,
                  style: 'footerDisclaimer',
                  fontSize:6
                },
               
                {
                  text: `Promoflo © 2024 OnTap Data Inc. \n
                  Created: ${this.dateService.getCurrentDateTime()} \n
                  Page ${currentPage} of ${pageCount}`,
                  style: 'copyrightFooter',
                  fontSize:6

                }
              ]
            }
            ]
        };
    }
  };

  pdfMake.createPdf(documentDefinition).download(`${this.name}_${this.headers[this.promotionIndex].promotion_name}_${this.groupSelected}.pdf`);
  this.loadingService.toggleLoading(false);
 
}

groupSelected:string ='';
filtered_by_promotion:string ='';
async getPromoData(e: any) {
  if (!e.groupSelected) {
    return;
  }
  let sortedHeaders:any
  this.groupSelected = e.groupSelectedName;
  this.disclaimer = e.disclaimer;
  await this.crudService.getData(`${this.apiUrl}promotion-headers?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}`).subscribe((result: any) => {
    this.promotionName = result[0].promotion_name;

     sortedHeaders = result.sort((a:any, b:any) => {
      const descriptionA = a.description || '';
      const descriptionB = b.description || '';
      return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
    });

    this.headers = sortedHeaders;
  });

  let promotion_period: any = [];
  if(localStorage.getItem('promotion-names') !== null) {
    promotion_period = localStorage.getItem('promotion-names');
    promotion_period = JSON.parse(promotion_period);
  }
  let selectedPromotion = promotion_period.find((promoPeriod:any) => promoPeriod.promotion_name === e.promotionNameSelected);
  let promotion_period_end_date;
  let promotion_period_start_date;

  if (selectedPromotion) {
      console.log("Found promotion:", selectedPromotion);
      promotion_period_start_date = selectedPromotion.promotion_start;
      promotion_period_end_date = selectedPromotion.promotion_end
  } else {
      console.log("Promotion not found");
  }
    console.log(promotion_period_start_date)
    console.log(promotion_period_end_date)
    console.log("promoperiod", promotion_period)
    this.crudService.getData(`${this.apiUrl}core-range-details?start_date=${promotion_period_start_date}&end_date=${promotion_period_end_date}&buying_group_id=${e.groupSelected.value}`).subscribe((coreRangeResult: any) => {
      const coreRangeFiltered = coreRangeResult.data.filter((item: any) => item.audit === 1);
      const potentialRebatable = coreRangeResult.data.filter((item: any) => item.rebate_total);
      console.log("corerange?",coreRangeResult)
      console.log("rebated?",potentialRebatable)
      const coreRangeIds = new Set<any>(coreRangeFiltered.map((item: any) => item.product_id));
      const rebatableIds = new Set<any>(potentialRebatable.map((item: any) => item.product_id));
      const coreRange = new Map<number, number>(coreRangeResult.data.map((item: any) => [item.product_id, item.rebate_total]));
  
    // Fetch the promotion data
    this.crudService.getData(`${this.promoDetailsUrl}?promotion_period_id=${e.promotionSelected.value}&buying_group_id=${e.groupSelected.value}&edlp`).subscribe((promoResult: any) => {
        const firstHeaderId = promoResult.data[0].promotion_header_id;
        try {
            let promoDataWithCoreRange = promoResult.data;

            // Check if the data is an array and the first element has expected properties
            if (!Array.isArray(promoDataWithCoreRange) || promoDataWithCoreRange.length === 0 || !promoDataWithCoreRange[0].product_id) {
                console.error('Unexpected data format or no data:', promoDataWithCoreRange);
                throw new Error('Invalid or no data returned from the server');
            }

            // If the first item doesn't have the expected structure, handle as an error or empty data
            if (typeof promoDataWithCoreRange[0] === 'object' && Object.keys(promoDataWithCoreRange[0]).some(key => isNaN(Number(key)))) {
                const errorMessage = Object.values(promoDataWithCoreRange[0]).join('');
                if (errorMessage.toLowerCase().includes("no records returned")) {
                    console.error('No records returned:', errorMessage);
                    throw new Error('No records returned from the server');
                }
            }

            // If the data structure is as expected, map over it to attach additional properties
      promoDataWithCoreRange = promoResult.data.map((promoProduct: any) => {
        let hasRebate = 0;
        return {
          ...promoProduct,
          isCoreRange: coreRangeIds.has(promoProduct.product_id),
          hasRebate: rebatableIds.has(promoProduct.product_id),
          promotion_header_size_id: promoProduct.edlp == 1 ? firstHeaderId : promoProduct.promotion_header_id
        };
      });

            if (promoDataWithCoreRange.length > 0) {
                if (this.selectedFilter) {
                    this.data = this.groupProductService.groupByProductValue(promoDataWithCoreRange, (product: any) => product[this.selectedFilter], sortedHeaders);
                } else {
                    // Default grouping
                    this.data = this.groupProductService.groupByProductValue(promoDataWithCoreRange, (product: any) => product.planning_category_name, sortedHeaders);
                }
            }

            console.log("data sent:", this.data);
            this.pdfCreator(this.data);
            this.data = [];
            promoDataWithCoreRange = [];
        } catch (e: any) {
            console.error('Error processing promo data:', e);
            notify("No data found in this configuration.", 'error', 3000);
            this.loadingService.toggleLoading(false);
        }
    });
}, (error:any) => {
    console.error('Error fetching core range data:', error);
    notify("Error fetching core range data.", 'error', 3000);
    this.loadingService.toggleLoading(false);
});
}

@Input() selectedFilter: string = '';
handleSelectedFilter(filter: string) {
  this.selectedFilter = filter;
}
}
