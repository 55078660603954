import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import CustomStore from 'devextreme/data/custom_store';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-promotion-rebates',
  templateUrl: './promotion-rebates.component.html',
  styleUrls: ['./promotion-rebates.component.css']
})
export class PromotionRebatesComponent {
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();
  @ViewChild("rebateGrid", { static: false }) public dataGrid: DxDataGridComponent;
  editPromotionRebates: boolean = false;
  dataSource: CustomStore;
  hasChanged: boolean = false;
  rebateTypes: any[] = [];
  private apiUrl: string = environment.baseApiUrl;

  constructor(private confirmationService:ConfirmationService,
              private horizontalMenuService:HorizontalMenuService,
              private crudService: CrudService,
              private notifyService: NotifyService) {
    this.horizontalMenuService.editPromotionRebates$.subscribe((value: boolean) => {
      this.onShow();
      this.editPromotionRebates = value;
    });
    this.rebateTypes = [{type: 0, name: 'Standard'}, {type: 1, name: 'Core Range'}];
    console.log(this.rebateTypes);
  }

  onShow() {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
          return await this.crudService.getData(`${this.apiUrl}rebates`).toPromise();
      },
      insert: async (values) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        return await new Promise(async (resolve) => {
          await this.crudService.insertData(`${this.apiUrl}rebates`, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            this.hasChanged = true;
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        console.log(key, values);
        return await new Promise(async (resolve) => {
          await this.crudService.updateData(`${this.apiUrl}rebates`, key, values)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            this.hasChanged = true;
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          await this.crudService.deleteData(`${this.apiUrl}rebates`, key)
          .subscribe((result: any) => {
            this.notifyService.tellUser(result);
            this.hasChanged = true;
            resolve(result);
          });
        });
      }
    })
  }

  onHiding(event: any) {
    console.log("DxDataGridComponent reference:", this.dataGrid);
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value: boolean) => this.horizontalMenuService.seteditPromotionRebates(value))
      .then((canHide: boolean) => {
        if(canHide) {
          if(this.hasChanged) {
            this.loadParentData.emit();
            this.hasChanged = false;
          }
          this.horizontalMenuService.seteditPromotionRebates(false);
        }
      });
  }
}
