<form (submit)="handleSubmit($event)">
  <dx-form
    id="edit-profile"
    [(formData)]="userDetails.data"
    (onFieldDataChanged)="onFieldDataChanged($event)"
  >
    <dxi-item itemType="group" cssClass="first-group" [colCount]="3">
      <dxi-item [colSpan]="2">
        <div class="circle">
          <p class="circle-inner">{{initials}}</p>
        </div>
      </dxi-item>
      <dxi-item itemType="group" [colSpan]="2">
        <dxi-item>
          <div class="top-right">
            <div class="dx-field-label">Theme: {{themeLabel}}</div>
            <dx-switch [(value)]="switchValue" (onValueChanged)="changeTheme($event)"></dx-switch>
          </div>
        </dxi-item>
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" cssClass="second-group" [colCount]="2">
      <dxi-item dataField="email" [editorOptions]="{readOnly: true}"></dxi-item>
      <dxi-item dataField="username"></dxi-item>
      <dxi-item *ngIf="!showPasswordFields" dataField="password">
        <dx-text-box mode="password" placeholder="Password" [(value)]="password_confirmation"></dx-text-box>
        <dxi-validation-rule type="required"></dxi-validation-rule>

      </dxi-item>
    </dxi-item>

      <div class="spacer"></div>
      <br/>
      <dxi-item itemType="group" class="password-group" [colCount]="2">
     

        <dxi-item *ngIf="showPasswordFields" editorType="dxTextBox">
          <dx-text-box mode="password" placeholder="Old Password" [(value)]="oldPassword"></dx-text-box>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item *ngIf="showPasswordFields">
          
        </dxi-item>
        <dxi-item *ngIf="showPasswordFields" editorType="dxTextBox">
          <dx-text-box mode="password" placeholder="New Password" [(value)]="newPassword" (onValueChanged)="handlePasswordValidation($event)"></dx-text-box>
        </dxi-item>
      <dxi-item *ngIf="showPasswordFields" editorType="dxTextBox">
        <dx-text-box mode="password" placeholder="Confirm Password" [(value)]="confirmPassword"></dx-text-box>
        <dxi-validation-rule type="compare" [comparisonTarget]="comparePasswords"(onValueChanged)="handlePasswordValidation($event)" message="Password and Confirm Password do not match"></dxi-validation-rule>
      </dxi-item>
      <dxi-item cssClass="toggleChangePassword" horizontalAlignment="center" colSpan="2" itemType="button" [buttonOptions]="{
        text: buttonText,
        type: 'default',
        useSubmitBehavior: false,
        onClick: togglePasswordFields.bind(this)
      }"></dxi-item>
    </dxi-item>
    <dxi-item 
    cssClass="save_button"
      itemType="button"
      [buttonOptions]="buttonOptions"
    ></dxi-item>
    
  </dx-form>
</form>
