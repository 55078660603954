<div class="dx-fieldset" id="setDisclaimerPopup">
  <div class="dx-fieldset-header">
    <div class="textarea-wrapper">
      <dx-text-area
        [inputAttr]="{ 'aria-label': 'Notes' }"
        [(value)]="disclaimer"
        (onValueChanged)="setDisclaimer($event)"
      >
      </dx-text-area>
    </div>
    <div class="saveButton">
      <dx-button
        stylingMode="contained"
        text="Save"
        type="default"
        [width]="120"
        (onClick)="saveConfiguration()"
      >
      </dx-button>
    </div>
</div>

<dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false"
        >
</dx-load-panel>
