import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { CostServiceService } from 'src/app/services/cost-service.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { PromotionDetailsGroup } from '../models/promotion-details-group.model';
import { Product } from '../models/product.model';
import { RebateNames } from '../models/rebate-names.model';
import { DxDataGridComponent, DxPopupComponent, DxTooltipComponent } from 'devextreme-angular';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotifyService } from '../services/notify.service';
import { Router } from '@angular/router';
import { on } from 'devextreme/events';
import { confirm } from 'devextreme/ui/dialog';


@Component({
  selector: 'app-promotion-details-by-group',
  templateUrl: './promotion-details-by-group.component.html',
  styleUrls: ['./promotion-details-by-group.component.css']
})
export class PromotionDetailsByGroupComponent {

  dataSource: CustomStore;
  currencyFormat = { type: 'currency', precision: 2 };
  rebateNames: RebateNames[] = [];
  columns: any[] = [];
  header: any;
  promoType:string = '';
  detailsLoading:boolean;
  allowEditing: boolean = true;
  disabled: boolean = false;
  tooltipData: any;
  tooltipTarget: string = '';
  storageKey: string = 'promotionDetailsGroupsState';
  isBuilt: boolean = false; 
  permissions: boolean = true; //Change this logic for when there is permissions on user details for admin users etc.
  private apiUrl: string = environment.baseApiUrl;
  @ViewChild(DxDataGridComponent, { static: false }) public dataGrid: DxDataGridComponent;
  @ViewChild('promotionGroupSelection', {static: false}) promotionGroupSelection: DxPopupComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;

  constructor(private crudService: CrudService,
    private costService: CostServiceService,
    private horizontalMenuService: HorizontalMenuService,
    private notifyService: NotifyService,
    private router: Router) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.header = state['header'];
    }
    this.loadData();
    let promotionLocked: string = '';
      if(this.header.status_type === 'closed') {
        this.allowEditing = false;
        this.disabled = true;
        promotionLocked = ' - *** Promotion Locked  - View Only Mode***';
      }
    this.promoType = `${this.header.promotion_name} - ${this.header.description} | Details: ${this.header.promotion_start} - ${this.header.promotion_end}${promotionLocked}`;
  }

  loadState = () =>
    {
      setTimeout(() => {
        console.log('loading state');
        if (localStorage.getItem(this.storageKey) !== null) {
          const state = JSON.parse(localStorage.getItem(this.storageKey) || '');
          this.dataGrid.instance.state(state);
          return state;
        }
      }, 300);
    }
  
    saveState = (state: any) => {
      if(!this.detailsLoading) {
        setTimeout(() => {
          console.log('SaveState');
          //const state = this.dataGrid.instance.state();
          localStorage.setItem(this.storageKey, JSON.stringify(state));
        }, 300);
      }
    }

  loadData() {
    this.detailsLoading = true;
    this.dataSource = new CustomStore({
      key: 'id',
      load: () => lastValueFrom(this.crudService.getData(`${this.apiUrl}promotion-details-groups?promotion_header_id=${this.header.id}`)
        ).then((result:any) => {
          this.detailsLoading = false;
          return result;
      }),
      update: async (key: number, values: PromotionDetailsGroup) => {
        values.buying_group_id = this.header.buying_group_id;
        values.allowance_total = this.dataGrid.instance.cellValue(key, 'allowance_total');
        const result = await lastValueFrom(
          this.crudService.updateData(`${this.apiUrl}promotion-details-groups`, key, values).pipe(
            tap((result: any) => {

            })
          ));
        this.notifyService.tellUser(result);
        return result;
      },
      remove: async (key) => {
        await lastValueFrom(this.crudService.deleteData(`${this.apiUrl}promotion-details-groups`, key).pipe(
          tap((result: any) => {
            this.notifyService.tellUser(result);
          })
        ));
      },
    });
    this.crudService.getData(this.apiUrl + 'rebates?type=0').toPromise()
    .then(async (rebateNames: any) => {
      this.rebateNames = rebateNames.data;
      await this.addColumn();
      this.dataGrid.instance.state(this.loadState());
      this.isBuilt = true;
    })
    .catch((error: any) => {
      console.log('Error fetching rebate names: ', error);
    });
  }

  resetGridState() {
    confirm('Are you sure you want to reset the grid to default?', 'Reset to default').then((result) => {
      if(result) {
        localStorage.removeItem('promotionDetailsGroupsState');
        this.dataGrid.instance.state(null); // Reset the grid state
        this.dataGrid.instance.refresh(); // Refresh the grid
      }
    });
  }

  updateCosts() {
    this.crudService.getData(`${this.apiUrl}update-costs?promotion_header_id=${this.header.id}&account_code=${this.header.account_code}`).subscribe((result:any) => {
      if(result.success) {
        this.dataGrid.instance.refresh();
        this.notifyService.tellUser(result);
      }
    });
  }

  addColumn()
  {
    this.columns = [];
    this.rebateNames.forEach((rebate: RebateNames) => {
       this.columns.push({
          dataField: rebate.rebate_name,
          dataType: 'number',
          visable: true,
          format: {
            type: 'currency',
            precision: 2
          }
        });
      });
  }

  onEditorPreparing(e: any) {
    if(e.parentType === "dataRow")
     e.editorOptions.onFocusIn = function (args: any) {
       args.element.querySelector("input.dx-texteditor-input").select();
     };
  }

  returnToPlanning = (e:any) =>{
    this.router.navigate(['/promotions']);
  }

  allowancePopupEvent(e: any)
  {
    //prevents sorting from happening while clicking button in header
    const nativeEvent = e.event || e;
    if (nativeEvent.stopPropagation) {
      nativeEvent.stopPropagation();
    }
      this.horizontalMenuService.seteditAllowances(true);
  }

  rebatePopupEvent(e: any)
  {
    const nativeEvent = e.event || e;
    if (nativeEvent.stopPropagation) {
      nativeEvent.stopPropagation();
    }
    this.horizontalMenuService.seteditPromotionRebates(true);
  }

  onEditingStart(e: any) {
    this.rebateNames.forEach((rebate: RebateNames) => {
      if(e.column.dataField === rebate.rebate_name) {
        localStorage.setItem('selectedRebateColumn', rebate.rebate_name);
      }
    });
  }

  setRebateTotal = (newData: any, value: any, currentRowData: any) =>{
    {
      const rebateColumn = localStorage.getItem('selectedRebateColumn') || '';
      newData[rebateColumn] = value;
      let total: number = 0;
      this.rebateNames.forEach((rebate: RebateNames) => {
          total += (+currentRowData[rebate.rebate_name] || 0);
        });
      newData.rebate = total + value - (+currentRowData[rebateColumn] || 0);
    }
  }

  compareGroupTotals(row: PromotionDetailsGroup): number {
    if(row.pitem_count === row.pd_count) {
      return 0;
    }
    return 1
  }

  updateGroupCount(item: any) {
    this.dataGrid.instance.selectRowsByIndexes([(item.rowIndex - 1)]);
    const rowData: any = this.dataGrid.instance.getSelectedRowsData();
    rowData[0].pitem_count = 1;
    rowData[0].pd_count = 1;
    this.dataGrid.instance.cellValue(0, 'groupTotalColumn', rowData);
  }

  groupTotalData(e: any) {
    if(e.rowType === "data" && e.data.pitem_count !== e.data.pd_count && e.column.cellTemplate === 'cellTemplate') {
        on(e.cellElement, "mouseover", (arg: any) => {
          this.tooltipTarget = '#groupTotalColumn';
          this.tooltip.instance.show(arg.target);
          this.tooltipData = [`Promotion has: ${e.row.data.pd_count} of ${e.row.data.pitem_count} Promotion Group items`];
        });

        on(e.cellElement, "mouseout", (arg: any) => {
          this.tooltip.instance.hide();
        });
      }
  }

  calculateAllowanceTotal(row: PromotionDetailsGroup) {
    const allowance_total = (+row.allowance_group || 0) + (+row.allowance_promotion || 0) + (+row.allowance || 0);
    return allowance_total;
  }

  setAllowanceTotal(newData: any, value: any, currentRowData: any): void
  {
    const total = (+currentRowData.allowance_group || 0) + (+currentRowData.allowance_promotion || 0) + (+value || 0);
    newData.allowance = value;
    newData.allowance_total = total;
  }

  setAllowance(newData: any, value: any, currentRowData: any): void
  {
    const amount = (+value || 0) - ((+currentRowData.allowance_group || 0) + (+currentRowData.allowance_promotion || 0));
    newData.allowance_total = value;
    newData.allowance = amount;
  }

  calculateGpAmount = (row: Product) =>
  {
    return this.costService.calculateGpAmount(row);
  }

  calculateGpPercent = (row: Product) =>
  {
    return this.costService.calculateGpPercent(row);
  }

  calculateNewCost = (row: Product) =>
  {
    return this.costService.calculateNewCost(row);
  }

  openPromotionGroupSelection = (e: any) => {
    this.promotionGroupSelection.instance.show();
  }

  closePromotionGroupSelection = () => {
    this.promotionGroupSelection.instance.hide();
  }
  calculateRebateCost = (row: Product) =>
  {
    return this.costService.calculateRebateCost(row);
  }

  calculateRebateGpAmount = (row: Product) =>
  {
    return this.costService.calculateRebateGpAmount(row);
  }

  calculateRebateGpPercent = (row: Product) =>
  {
    return this.costService.calculateRebateGpPercent(row);
  }

}
