import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private apiUrl: string = environment.baseApiUrl;
  
  constructor(private crudService: CrudService) {}
  
  async getEvents(start_date: number, end_date: number): Promise<Event[]> {
    return await this.crudService.getData(`${this.apiUrl}events?start_date=${start_date}&end_date=${end_date}`).toPromise();
  }
}

export class Event {
  date: number | string;
  event_name: string;
  region: any;
  week_id: number;
  week_number: number;
  editable: number;
  length?: number;
}
