export class MixMatchItem {
  id: number;
  header_id: number;
  product_id: number;
  selection_level: number;
  created_at: string;
  updated_at: string;
  product_name: string;
  product_code: string;
}
