import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { PromotionHeaderComponent } from './promotion-header/promotion-header.component';
import { AuthGuard } from './auth.guard.service';
import { CoreRangeHeadersComponent } from './settings/core-range-headers/core-range-headers.component';
import { CoreRangeDetailsComponent } from './settings/core-range-details/core-range-details.component';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { MediaPlanningComponent } from './media-planning/media-planning.component';
import { CanDeactivateGuard } from './services/can-deactivate-guard';
import { ProductsComponent } from './settings/products-component/products-component.component';
import { PromotionGroupsComponent } from './settings/promotion-groups/promotion-groups.component';
import { PromotionNamesComponent } from './settings/promotion-names/promotion-names.component';
import { PromotionPeriodsComponent } from './settings/promotion-periods/promotion-periods.component';
import { PromotionStatusComponent } from './settings/promotion-status/promotion-status.component';
import { PromotionTypeComponent } from './settings/promotion-type/promotion-type.component';
import { PromotionAllowancesComponent } from './settings/promotion-allowances/promotion-allowances.component';
import { PromotionCategoriesComponent } from './settings/promotion-categories/promotion-categories.component';
import { BuyingGroupsComponent } from './settings/buying-groups/buying-groups.component';
import { MediaTypesComponent } from './settings/media-types/media-types.component';
import { EventsComponent } from './settings/events/events.component';
import { StorePositionsComponent } from './settings/store-positions/store-positions.component';
import { SlotTypesComponent } from './settings/slot-types/slot-types.component';
import { WarehouseComponent } from './settings/warehouse/warehouse.component';
import { RawHostDataComponent } from './raw-host-data/raw-host-data.component';
import { WarehousePricingComponent } from './warehouse-pricing/warehouse-pricing.component';
import { PromotionDetailsByGroupComponent } from './promotion-details-by-group/promotion-details-by-group.component';

const routes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'slotting', component: DataGridComponent, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]},
  {path: 'promotions', component: PromotionHeaderComponent, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  {path: 'core-range', component: CoreRangeHeadersComponent, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]},
  {path: 'core-range-details', component: CoreRangeDetailsComponent, canActivate: [AuthGuard], canDeactivate:[CanDeactivateGuard]},
  {path: 'promotion-details', component: PromotionDetailsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-details-by-group', component: PromotionDetailsByGroupComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'media-planning', component: MediaPlanningComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'products', component: ProductsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-groups', component: PromotionGroupsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-names', component: PromotionNamesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-periods', component: PromotionPeriodsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-status', component: PromotionStatusComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-types', component: PromotionTypeComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-allowances', component: PromotionAllowancesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'promotion-categories', component: PromotionCategoriesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'buying-groups', component: BuyingGroupsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'slotting-types', component: SlotTypesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'store-positons', component: StorePositionsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'events', component: EventsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'media-types', component: MediaTypesComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'warehouses', component: WarehouseComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'raw-host-data', component: RawHostDataComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
  {path: 'warehouse-pricing', component: WarehousePricingComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
