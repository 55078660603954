import { Component } from '@angular/core';

@Component({
  selector: 'app-clone-planning-data',
  templateUrl: './clone-planning-data.component.html',
  styleUrls: ['./clone-planning-data.component.css']
})
export class ClonePlanningDataComponent {
  //
}
