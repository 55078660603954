import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) { }

  dateFormat(date: string): string
  {
    const dP: DatePipe = new DatePipe('en-AU');
    return dP.transform(date, 'yyyy-MM-dd') || '';
  }

  twoWeeksAgo(date: Date): string
  {
    const twoWeeksAgo = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    return this.dateFormat(twoWeeksAgo.toISOString());
  }
  getCurrentDateTime(){
    return this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
  }

}
