<dx-data-grid #rebateGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  height="98%"
>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxi-column dataField="rebate_name" dataType="string" caption="Name">
    <dxi-validation-rule type="required" message="Rebate name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="type" dataType="number" caption="Type">
    <dxo-lookup
      [dataSource]="rebateTypes"
      valueExpr="type"
      displayExpr="name"></dxo-lookup>
    <dxi-validation-rule type="required" message="Type is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
</dx-data-grid>

