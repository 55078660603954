import { Component, Input, AfterViewInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { ProductsService } from '../products.service';
import { CrudService } from '../services/crud.service';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/models/product.model';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.css'],
  providers: [ProductsService]
})
export class DetailViewComponent implements AfterViewInit {
  @Input() key: number;
  @Input() buyingGroup: number;

  productDataSource: DataSource;
  products: Product[];
  private apiUrl : string = environment.baseApiUrl;

  constructor(private crudService: CrudService) {}

  async ngAfterViewInit() {
    const productData: any = await this.crudService.getData(`${this.apiUrl}promotion-groups-products?promotion_group_id=`+this.key).toPromise();
    this.products = productData.data;
    console.log('Products: ', this.products);
    this.productDataSource = await new DataSource({
      store: new ArrayStore({
        data: this.products
      }),
      filter: [['promo_group_id', '=', this.key]],
    });
  }

  onCellPrepared(e: any) {
    if(e.rowType === 'data') {
      if(e.data.product_type == 2 && e.column.dataField == 'product_code') {
        e.cellElement.style.cssText = "background-color: #e600e630; font-weight: bold;";
      }
    }
  }

}
