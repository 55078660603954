import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/models/product.model';
import { DxPopupComponent, DxDataGridComponent } from 'devextreme-angular';
import { NotifyService } from 'src/app/services/notify.service';
import { PromotionItem } from 'src/app/models/promotion-item.model';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';


@Component({
  selector: 'app-promotion-group-items',
  templateUrl: './promotion-group-items.component.html',
  styleUrls: ['./promotion-group-items.component.css']
})
export class PromotionGroupItemsComponent implements AfterViewInit {
  @Input() key: number;
  @ViewChild('productsPopup', {static: false}) productsPopup: DxPopupComponent;
  @ViewChild('mixMatchPopup', {static: false}) mixMatchPopup: DxPopupComponent;
  @ViewChild('promotionGroupItems', {static: false}) promotionGroupItems: DxDataGridComponent;

  productDataSource: DataSource;
  products: Product[];
  parent: string = 'promotion-groups'
  private apiUrl : string = environment.baseApiUrl;
  overflowOptions = [
    { value: 1, name: 'Products' },
    { value: 2, name: 'Mix n Match'}
  ]

  constructor(private crudService: CrudService,
    private notifyService: NotifyService,
    private horizontalMenuService: HorizontalMenuService ) {}

  async ngAfterViewInit() {
    await this.loadData();
  }

  async loadData()
  {
    console.log('Key: ', this.key);
    const productData = await this.crudService.getData(`${this.apiUrl}promotion-groups-products?promotion_group_id=${this.key}`).toPromise();
    this.products = productData.data;
    this.productDataSource = await new DataSource({
      store: new ArrayStore({
        data: this.products
      })
    });
  }

  onRowRemoving = (e: any) => {
    let values: PromotionItem = new PromotionItem();
    console.log('Row Removing: ', e.data);
    values.product_id = e.data.product_id;
    values.promotion_group_id = e.data.promo_group_id;
    this.crudService.deleteDataWithBody(`${this.apiUrl}promotion-groups-destroy`, values)
      .subscribe((result: any) => {
        this.notifyService.tellUser(result);
      });
  }

  itemClick(e:any){
    console.log('Item Click: ', e);
    if(e.itemData.value == 2){
      this.horizontalMenuService.seteditMixMatch(true);
    }
    if(e.itemData.value == 1){
      this.horizontalMenuService.seteditProductSelection(true);
    }
  }

  onCellPrepared(e: any) {
    if(e.rowType === 'data') {
      if(e.data.product_type == 2 && e.column.dataField == 'product_code') {
        e.cellElement.style.cssText = "background-color: #e600e630; font-weight: bold;";
      }
    }
  }

  calculateProductType = (row: any) => {
    if(row.product_type == 2){
      return 'Mix n Match';
    }
    return 'Product';
  }

  closeProductSelection = () => {
    this.productsPopup.instance.hide();
  }
  closeMixMatchSelection = () => {
    this.mixMatchPopup.instance.hide();
  }
}
