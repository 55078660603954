<div class="grid">
<dx-data-grid #allowanceGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [(height)]="gridHeight"
>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxi-column dataField="allowance_name" dataType="string" caption="Name"></dxi-column>
  <!-- <dxi-column dataField="type" dataType="string" caption="Type" width="15%">
    <dxo-lookup [dataSource]="allowanceTypes" valueExpr="type" displayExpr="type"></dxo-lookup>
  </dxi-column> -->
  <dxi-column dataField="id" dataType="integer" [visible]="false">
    <dxi-validation-rule type="required" message="Allowance name is required"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
</div>