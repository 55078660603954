import { Component, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';import { environment } from 'src/environments/environment';
import { PromotionHeader } from '../models/promotion-header.model';
import { PromotionGroup } from '../models/promotion-group.model';
import { NotifyService } from '../services/notify.service';

@Component({
  selector: 'app-promotion-group-selection',
  templateUrl: './promotion-group-selection.component.html',
  styleUrls: ['./promotion-group-selection.component.css']
})
export class PromotionGroupSelectionComponent {
  @ViewChild('promotionGroupSelectionGrid', {static: false}) dataGrid: DxDataGridComponent;
  @Output() loadParentData: EventEmitter<any> = new EventEmitter();
  @Output() closeChild: EventEmitter<any> = new EventEmitter();
  @Input() parent: string;
  header: PromotionHeader;
  dataSource: CustomStore;
  categories: CustomStore;
  slotted: boolean = false;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService,
              private notifyService: NotifyService) {
    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');
    }
    this.loadPromotionGroups();
    this.loadPromotionCategories();
  }

  loadPromotionGroups() {
    this.dataSource = new CustomStore<PromotionGroup>({
      load: async (loadOptions) => {
        return await new Promise(async (resolve) => {
          this.crudService.getData(`${this.apiUrl}promotion-groups`, loadOptions).subscribe((result: any) => {
            resolve(result);
          })
        });
      }
    });
  }

  loadPromotionCategories() {
    this.categories = new CustomStore<PromotionGroup>({
      load: async (loadOptions) => {
        return await new Promise(async (resolve) => {
          this.crudService.getData(`${this.apiUrl}planning-categories`, loadOptions).subscribe((result: any) => {
            resolve(result);
          })
        });
      }
    });
  }

  selectPromotionGroups(e: any) {
    let userDetails: any = {};
    if(localStorage.getItem('userDetails') !== null) {
      userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
    }
    const promotionGroups: PromotionGroup[] = this.dataGrid.instance.getSelectedRowsData();
    if(!this.slotted) {
      promotionGroups.forEach((group: PromotionGroup) => {
        group.slot_type = '';
      });
    }
    const values: any = {
      location_id: userDetails.data.location_id,
      promotion_header_id: this.header.id,
      buying_group_id: this.header.buying_group_id,
      promotion_groups: promotionGroups
    };
    this.crudService.insertData(`${this.apiUrl}insert-promotion-details`, values).subscribe((result: any) => {
      this.notifyService.tellUser(result);
      this.loadParentData.emit();
      this.closeChild.emit();
    });
  }

  slottedPromotionGroups(e: any) {
    if(e.value) {
      this.dataSource = new CustomStore<PromotionGroup>({
        load: async () => {
          return await new Promise(async (resolve) => {
            this.crudService.getData(`${this.apiUrl}selected-promotion-groups?`+
              `promotion_period_id=${this.header.promotion_period_id}&`+
              `promotion_start=${this.header.promotion_start}&`+
              `promotion_end=${this.header.promotion_end}&`+
              `buying_group_id=${this.header.buying_group_id}`).subscribe((result: any) => {
              this.slotted = true;
              resolve(result.data);
            })
          });
        }
      });
    } else {
      this.slotted = false;
      this.loadPromotionGroups();
    }
  }

  onHiding(event: any) {
    this.dataGrid.instance.clearSelection();
    this.dataGrid.instance.clearFilter();
  }

}
