import { Component, ViewChild } from '@angular/core';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { environment } from 'src/environments/environment';
import { CrudService } from '../services/crud.service';
import { ConfirmationService } from '../services/confirmation.service';
import CustomStore from 'devextreme/data/custom_store';
import { BuyingGroups } from '../models/buying-groups.model';
import { PromotionPeriod } from '../models/promotion-period.model';
import { DateFormatService } from '../services/date-format.service';
import { NotifyService } from '../services/notify.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { CostServiceService } from '../services/cost-service.service';
import { Product } from '../models/product.model';

@Component({
  selector: 'app-warehouse-pricing',
  templateUrl: './warehouse-pricing.component.html',
  styleUrls: ['./warehouse-pricing.component.css']
})
export class WarehousePricingComponent {
  public dataSource: CustomStore;
  public buyingGroups: BuyingGroups[] = [];
  public promotionPeriods: PromotionPeriod[] = [];
  public warehouses: any[] = [];
  public dataLoading: Boolean = false;
  private buyingGroupId: number = 0;
  private promotionPeriodId: number = 0;
  private warehouseId: number = 0;
  private apiUrl: string = environment.baseApiUrl;
  currencyFormat = { type: 'currency', precision: 2 };
  @ViewChild(DxDataGridComponent, { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
              private confirmationService: ConfirmationService,
              private horizontalMenuService: HorizontalMenuService,
              private dateFormatService: DateFormatService,
              private notify: NotifyService,
              private costService: CostServiceService) {
    this.horizontalMenuService.editWarehousePricing$.subscribe((value: boolean) => {
      this.loadData();
    });
  }

  setBuyingGroupId(e: any) {
    this.buyingGroupId = e.value;
  }

  setPromotionPeriodId(e: any) {
    this.promotionPeriodId = e.value;
  }

  setWarehouseId(e: any) {
    console.log("warehouse id", e.value);
    this.warehouseId = e.value;
  }

  getColour(data: number): string
  {
    return (data >= 1 ? 'red' : 'green');
  }


  loadData(){
    console.log("warehouse pricing component");
    const startDate = this.dateFormatService.twoWeeksAgo();
    this.crudService.getData(`${this.apiUrl}buying-groups`).subscribe((data: any) => {
      this.buyingGroups = data;
    });
    this.crudService.getData(`${this.apiUrl}promotion-periods?start_date=${startDate}&number_of_weeks=26`).subscribe((data: any) => {
      this.promotionPeriods = data;
    });
    this.crudService.getData(`${this.apiUrl}warehouses`).subscribe((data: any) => {
      this.warehouses = data;
    });
  }

  getWarehousePricing() {
    if(this.buyingGroupId === 0 || this.promotionPeriodId === 0 || this.warehouseId === 0) {
      this.notify.returnError('Please select a Buying Group and Promotion and Warehouse');
      return;
    }
    this.dataLoading = true;
    this.dataSource = new CustomStore<any>({
      key: ['product_id', 'buying_group_id'],
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}warehouse-pricing?buying_group_id=${this.buyingGroupId}&promotion_period_id=${this.promotionPeriodId}&warehouse_id=${this.warehouseId}`).subscribe((result: any) => {
            console.log("warehouse pricing", result);
            resolve({data: result.data, totalCount: result.totalCount});
            this.dataLoading = false;
          })
        })
      },
      update: async (key: any, values: any) => {
        return await new Promise((resolve) => {
          const data = {
            buying_group_id: key.buying_group_id,
            product_id: key.product_id,
            rrp: values.sell_price,
          }
          this.crudService.updateData(`${this.apiUrl}warehouse-pricing`, key.product_id, data).subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    });
  }

  calculateUnitPrice = (row: Product) =>
  {
    const sell_price: number = row.sell_price || 0;
    return sell_price / row.sales_unit;
  }

  calculateGpAmount = (row: Product) =>
    {
      return this.costService.calculateGpAmount(row);
    }

    calculateGpPercent = (row: Product) =>
    {
      return this.costService.calculateGpPercent(row);
    }

  onEditingStart(e: any) {
    console.log("onEditingStart", e);
    if(e.data.on_promotion !== 0) {
      e.cancel = true;
    }
  }

}
