<dx-data-grid #rebateGrid
[dataSource]="dataSource"
[showBorders]="true"
width="800px"
onHid>
<dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
<dxi-column dataField="warehouse_id" dataType="number" caption="Warehouse">
    <dxo-lookup [dataSource]="warehouses" valueExpr="id" displayExpr="name"></dxo-lookup>
    <dxi-validation-rule type="required" message="Warehouse is required"></dxi-validation-rule>
</dxi-column>
<dxi-column dataField="rebate_start" dataType="date" caption="Rebate Start" format="dd-MM-yyyy"></dxi-column>
<dxi-column dataField="rebate_end" dataType="date" caption="Rebate End" format="dd-MM-yyyy"></dxi-column>
<dxi-column dataField="buying_start" dataType="date" caption="Buying Start" format="dd-MM-yyyy"></dxi-column>
<dxi-column dataField="buying_end" dataType="date" caption="Buying End" format="dd-MM-yyyy"></dxi-column>
</dx-data-grid>