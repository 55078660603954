import { Component } from '@angular/core';

@Component({
  selector: 'app-msp-range',
  templateUrl: './msp-range.component.html',
  styleUrls: ['./msp-range.component.css']
})
export class MspRangeComponent {

}
