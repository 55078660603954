<div class="report-container">

  <div id="weekFilter" class="filter-container">
      <div class="dx-fieldset">
          <div class="dx-field">

              <!-- Start Week field -->
              <div class="dx-field-container">
                  <div class="dx-field-label report_label">Start Week:</div>
                  <div class="dx-field-value">                  
                    <dx-lookup 
                    [dataSource]="calendarWeeks" 
                    [displayExpr]="getDisplayExpression" 
                    valueExpr="start_date" 
                    width="250px" 
                    #startWeekLookup 
                    validationMessagePosition="right">
                    <dxo-drop-down-options title="No. - Start End"></dxo-drop-down-options>
                  </dx-lookup>
                  
                  </div>
              </div>

              <!-- Number of Weeks field -->
              <div class="dx-field-container">
                  <div class="dx-field-label report_label">Number of Weeks:</div>
                  <div class="dx-field-value">  
                      <dx-lookup [dataSource]="weekRange" displayExpr="name" valueExpr="weeks" [items]="weekRange" width="250px" #numberOfWeeks validationMessagePosition="right">                    
                        <dxo-drop-down-options title="No. - Start End"></dxo-drop-down-options>
                      </dx-lookup>
                  </div>
              </div>

          </div>
      </div>

      <div class="button-wrapper">
        <dx-button [disabled]="!isStartWeekSelected || !isNumberOfWeeksSelected" 
                   class="dx-widget dx-button dx-button-mode-contained dx-button-default dx-button-has-text" 
                   style="width: 130px;" (onClick)="updateGrid(startWeekLookup.value, numberOfWeeks.value)">
            <div class="dx-button-content">
                <div class="dx-button-text">Select</div>
            </div>
        </dx-button>
    </div>
  </div>

</div>
