<app-header (toggleDrawer)="toggleDrawer()"></app-header>
<dx-drawer class="drawer-content"
            [hoverStateEnabled]="true"           
            template="template"
           [(opened)]="isDrawerOpen"
           revealMode="expand"
           position="left"
           [visible]="true"
            class="main-drawer"
           [minSize]="42"
           [maxSize]="180"
           [closeOnOutsideClick]="onOutsideClick"
           >
           <dxo-scrolling mode="virtual"></dxo-scrolling>
    <div *dxTemplate="let data of 'template'">
        <!-- dx-tree-view for navigation -->
        <dx-tree-view
            class="custom-tree-view"
            [dataSource]="navigation"
            [width]="180"
            (onItemClick)="selectItem($event)"
            [selectionMode]="'single'"
            expandEvent="click"
            >
        </dx-tree-view>
    </div>
    <div [class.active]="isDrawerOpen === true" class="maintenance-content">
        <div class="center">
            <div class="frame">
                <ng-container *ngIf="selectedItem" [ngComponentOutlet]="selectedItem"></ng-container>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</dx-drawer>
