<dx-data-grid
  [dataSource]="itemsDataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowRemoving)="onRowRemoving($event)"
>
<dxo-editing
    mode="row"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-toolbar>
    <dxi-item name="saveButton"></dxi-item>
    <dxi-item name="revertButton"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="plus" (onClick)="openProductSelection($event)"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>
  <dxi-column dataField="product_id" dataType="number" caption="ID" alignment="left" width="100"></dxi-column>
  <dxi-column dataField="product_code" dataType="string" caption="Code"></dxi-column>
  <dxi-column dataField="product_name" dataType="string" caption="Product Name"></dxi-column>
  <dxo-paging [pageSize]="5"></dxo-paging>
</dx-data-grid>

<!--Product Selection-->
<dx-popup #productSelection
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true">
    <app-product-selection
    (closeChild)="closeProductSelection()"
    (loadParentData)="loadData()"
    [parent]="parent"
    [header_id]="key"
    [selection_level]="level"></app-product-selection>
</dx-popup>
