<div class="grid">
<dx-data-grid #storePositionGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxi-column dataField="id" dataType="integer" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="position_name" dataType="string" caption="Position Name">
    <dxi-validation-rule type="required" message="store-position name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="position_description" dataType="string" caption="Description"></dxi-column>
  <dxi-column dataField="group_id" dataType="number" [visible]="false"></dxi-column>
  <dxi-column dataField="location_id" dataType="number" [visible]="false"></dxi-column>
</dx-data-grid>
</div>