import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WeekRangeValues } from '../week-range-selector/week-range-selector.component';

@Injectable({
  providedIn: 'root',
})
export class WeekRangeSharedService {
  private selectedWeekRange = new BehaviorSubject<WeekRangeValues | null>(null);
  selectedWeekRange$ = this.selectedWeekRange.asObservable();

  setSelectedWeekRange(weekRange: WeekRangeValues) {
    console.log('Emitting:', weekRange);
    this.selectedWeekRange.next(weekRange);
  }

  
}
