<div *ngIf="currentPromotion" id="clonePromotionPopup">
    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Promotion Name -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Promotion Name:</div>
            <div class="dx-field-value clone_value">
                <div class="dx-field-value-static">{{currentPromotion.promotion_name}}</div>
            </div>
        </dxi-item>

        <!-- Buying Group -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Buying Group:</div>
            <div class="dx-field-value clone_value">
                <dx-select-box class="clone_cell"
                    [dataSource]="buyingGroups"
                    valueExpr="id"
                    displayExpr="buying_group_name"
                    [value]="currentPromotion.buying_group_id"
                    (onValueChanged)="setBuyingGroup($event)">
                </dx-select-box>
            </div>
        </dxi-item>

        <!-- Promotion Type -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Promotion Type:</div>
            <div class="dx-field-value clone_value">
                <dx-select-box class="clone_cell"
                    [dataSource]="promotionTypes"
                    valueExpr="id"
                    displayExpr="name"
                    [value]="currentPromotion.promotion_type"
                    (onValueChanged)="setPromotionType($event)">
                </dx-select-box>
            </div>
        </dxi-item>

        <!-- Description -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Description:</div>
            <div class="dx-field-value clone_value">
                <dx-text-box class="clone_cell"
                    [(value)]="currentPromotion.description"
                    (onValueChanged)="setDescription($event)"
                    [showClearButton]="true">
                </dx-text-box>
            </div>
        </dxi-item>
    </dx-box>

    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Promotion Start -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Promotion Start:</div>
            <div class="dx-field-value clone_value">
                <div class="dx-field-value-static">{{currentPromotion.promotion_start | date:'dd-MM-yyyy'}}</div>
            </div>
        </dxi-item>

        <!-- Promotion End -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Promotion End:</div>
            <div class="dx-field-value clone_value">
                <div class="dx-field-value-static">{{currentPromotion.promotion_end | date:'dd-MM-yyyy'}}</div>
            </div>
        </dxi-item>

        <!-- Buying Start -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Buying Start:</div>
            <div class="dx-field-value clone_value">
                <dx-date-box class="clone_cell"
                    [value]="currentPromotion.buying_start"
                    [displayFormat]="'dd-MM-yyyy'"
                    [type]="'date'"
                    [showClearButton]="true"
                    (onValueChanged)="setBuyingStart($event)">
                </dx-date-box>
            </div>
        </dxi-item>

        <!-- Buying End -->
        <dxi-item class="buying_end" ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Buying End:</div>

            <div class="dx-field-value clone_value">
                <dx-date-box class="clone_cell"
                    [value]="currentPromotion.buying_end"
                    [displayFormat]="'dd-MM-yyyy'"
                    [type]="'date'"
                    [showClearButton]="true"
                    (onValueChanged)="setBuyingEnd($event)">
                </dx-date-box>
            </div>
        </dxi-item>
    </dx-box>

    <dx-box direction="row" width="auto" class="box" crossAlign="center">
      <!-- Rebate Clone Switch -->
      <dxi-item baseSize="auto" class="clone_row padding">
          <div class="dx-field-label clone_label">Rebate Clone:</div>
          <div class="dx-field-value clone_value">
              <dx-switch
                    [value]="rebate_clone"
                    (onValueChanged)="setRebateClone($event)">
                    <div *dxTemplate="let data of 'onText'">Yes</div>
                    <div *dxTemplate="let data of 'offText'">No</div
              >
              </dx-switch>
          </div>
          <dxi-item class="clone_row padding" baseSize="auto"></dxi-item>
      </dxi-item>
    </dx-box>

    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Clone Button -->
        <dxi-item class="clone_row"  baseSize="auto" class="padding">
            <dx-button width="100px"
                text="Clone"
                [type]="'default'"
                [validationGroup]="'clonePromotion'"
                (onClick)="clonePromotion()"></dx-button>
        </dxi-item>

        <!-- Cancel Button -->
        <dxi-item class="clone_row"  baseSize="auto" class="padding">
            <dx-button width="100px"
            stylingMode="outlined"

                text="Cancel"
                [type]="'danger'"
                [validationGroup]="'clonePromotion'"
                (onClick)="cancel()"></dx-button>
        </dxi-item>
    </dx-box>
</div>

<dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#clonePromotionPopup' }"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false"
        >
</dx-load-panel>
