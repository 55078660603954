import { Component, OnInit, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { ProductGroup } from 'src/app/models/product-group.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductComponent implements OnInit {
  @Input() product_groups: ProductGroup[];
  @ViewChild('productsGrid', { static: false }) productsGrid: DxDataGridComponent;
  product: Product[];
  dataSource: CustomStore;
  count: number = 0;
  importDisabled: boolean = false;
  isStored: boolean = false;
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService) {
    this.loadData();
  }

  loadData() {
    this.dataSource = new CustomStore<Product>({
      key: 'id',
      load: async (loadOptions) => {
        return await new Promise(async (resolve) => {
          this.crudService.getData(`${this.apiUrl}products`, loadOptions).subscribe((result: any) => {
            resolve(result);
          })
        });
      },
      insert: async (values) => {
        console.log(values);
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}products`, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.updateData(`${this.apiUrl}products`, key, values)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}products`, key)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    });
  }

  ngOnInit(): void {

  }

  calculateGpAmount(row: Product)
  {
    if(row.sell_price !== null && row.cost_inc_gst !== null) {
      return row.sell_price - row.cost_inc_gst;
    }
    return undefined;
  }

  calculateGpPercent(row: Product)
  {
    if(row.sell_price !== null && row.sell_price !== 0 && row.cost_inc_gst !== null) {
      return ((row.sell_price - row.cost_inc_gst) / row.sell_price) * 100;
    }
    return undefined;
  }

  refreshDataGrid() {
    this.loadData();
    this.productsGrid.instance.refresh();
  }

  onEditorPreparing(e: any) {
    if (e.parentType === "searchPanel") {
      e.editorOptions.onValueChanged = undefined;
      e.editorOptions.onEnterKey = function(arg: any) {
              e.component.searchByText(arg.component.option("value"));
      }
    }
  }
}
