<dx-data-grid
  [dataSource]="productDataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onCellPrepared)="onCellPrepared($event)"
>
  <dxi-column dataField="product_code" dataType="number" width="100px"></dxi-column>
  <dxi-column dataField="product_name" dataType="string"></dxi-column>
  <!-- <dxi-column dataField="carton_quantity" dataType="string"></dxi-column>
  <dxi-column dataField="cost_inc_gst" dataType="number" format="currency"></dxi-column>
  <dxi-column dataField="sell_price" dataType="number" format="currency"></dxi-column> -->
  <dxo-paging [pageSize]="5"></dxo-paging>
</dx-data-grid>
