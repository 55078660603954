<div class="grid">
<dx-data-grid #eventsGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [keyExpr]="['date', 'region']"
  [height]="'90%'"
  (onCellPrepared)="setStyle($event)"
>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowDeleting]="isEditable"
    [allowUpdating]="isEditable"
  >
  </dxo-editing>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="week_number" dataType="integer" caption="Week No" width="80" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="date" dataType="date" caption="Date" format="dd-MM-yyyy" width="*" [setCellValue]="getWeekNumber">
    <dxi-validation-rule type="required" message="date is required"></dxi-validation-rule></dxi-column>
  <dxi-column dataField="event_name" dataType="string" caption="Event" width="*">
    <dxi-validation-rule type="required" message="event name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column 
    dataField="region"
    dataType="string"
    caption="Region"
    width="*"
    editCellTemplate="tagBoxEditor"
    [calculateFilterExpression]="calculateFilterExpression">
    <dxi-validation-rule type="required" message="Event region is required"></dxi-validation-rule>
  </dxi-column>
  <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
    <dx-tag-box
      [dataSource]="regions"
      [value]="cellInfo.data.region"
      valueExpr="region"
      displayExpr="region"
      [showSelectionControls]="true"
      [maxDisplayedTags]="8"
      [showMultiTagOnly]="false"
      [inputAttr]="{ 'aria-label': 'Region' }"
      applyValueMode="useButtons"
      [searchEnabled]="true"
      (onValueChanged)="cellInfo.setValue($event.value)"
      (onSelectionChanged)="cellInfo.component.updateDimensions()"
    >
    </dx-tag-box>
  </div>
</dx-data-grid>
</div>