import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService) {}
  async getReports(): Promise<Report[]> {
    return await this.crudService.getData(`${this.apiUrl}all-reports`).toPromise();
  }

  getTabs(): Tab[] {
    return tabs;
  }
}


export class Report {
  id: number;
  name: string;
  description: string;
  category: string;
  component_name: string;
  type: string;
  favorite: number;
  
}

  export class Tab {
    id: number;

    text: string;

    // icon: string;

    // content: string;
  }

  export class parentTab {
    id: number;
    text: string;
    icon?: string;
  }

  export class favoriteReport {
    group_id: number;
    location_id: number;
    user_id: string;
    report_id: number;
    type: string;
  }

  const tabs: Tab[] = [
    {
      id: 0,
      text: 'All'
    },
    {
      id: 1,
      text: 'Promotion'
    },
    {
      id: 2,
      text: 'POS'
    },
    {
      id: 3,
      text: 'Product'
    },
    {
      id: 4,
      text: 'Purchase'
    },
    {
      id: 5,
      text: 'Configuration'
    }
  ];
