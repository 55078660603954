<dx-validation-group name="clonePromotions">
    <div class="dx-fieldset">
        <div class="dx-fieldset-header">Promotions</div>
        <div class="dx-field">
          <dx-tag-box 
            #promotionsTagBox
            [dataSource]="promotionPeriods"
            displayExpr="promotion_name" 
            valueExpr="id"
            [showSelectionControls]="true"
            applyValueMode="useButtons"
            width="250px">
            <dxo-drop-down-options title="Promotions"></dxo-drop-down-options>
            <dx-validator>
              <dxi-validation-rule type="required" message="At least one promotion is required"></dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </div>
    </div>
    <div class="dx-fieldset">
        <div class="dx-fieldset-header">From Buying Group</div>
        <div class="dx-field">
          <dx-select-box
            #fromBuyingGroupLookup
            [dataSource]="buyingGroups"
            displayExpr="buying_group_name" 
            valueExpr="id"
            [showClearButton]="true"
            width="250px">
            <dxo-drop-down-options title="From Buying Group"></dxo-drop-down-options>
            <dx-validator>
              <dxi-validation-rule type="required" message="From Buying Group is required"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        <div class="dx-fieldset-header">To Buying Group</div>
        <div class="dx-field">
          <dx-lookup 
            #toBuyingGroupLookup
            [dataSource]="buyingGroups"
            displayExpr="buying_group_name" 
            valueExpr="id"
            width="250px">
            <dxo-drop-down-options title="To Buying Group"></dxo-drop-down-options>
            <dx-validator>
              <dxi-validation-rule type="required" message="To Buying Group is required"></dxi-validation-rule>
            </dx-validator>
          </dx-lookup>
        </div>
        <div class="dx-field">
          <dx-button 
            text="Select"
            icon="check"
            stylingMode="contained"
            type="default"
            (onClick)="clonePromotions($event)"
          >
          </dx-button>
        </div>
    </div>
  </dx-validation-group>
  
