import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CrudService } from '../services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MediaTypes } from '../models/media-types.model';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { HorizontalMenuService } from '../services/horizontalmenu.service';
import { ConfirmationService } from '../services/confirmation.service';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

@Component({
  selector: 'app-media-planning',
  templateUrl: './media-planning.component.html',
  styleUrls: ['./media-planning.component.css']
})
export class MediaPlanningComponent {
  dataSource: CustomStore<any>;
  detailsLoading:boolean;
  apiUrl: string = environment.baseApiUrl;
  columns: any[] = [];
  mediaTypes: MediaTypes[] = [];
  mediaPlanning: any[] = [];
  header: any = {};
  promoInfo: any = '';
  totalItems: totalItems[] = [];
  summaryConfig: any = {};
  @ViewChild(DxDataGridComponent, { static: false }) public mediaPlanningGrid: DxDataGridComponent;

  constructor(private router: Router,
    private crudService: CrudService,
    private horizontalMenuService:HorizontalMenuService,
    private confirmationService: ConfirmationService) {
    this.detailsLoading = true;
    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');

      this.promoInfo = `${this.header.promotion_name} Details: ${this.header.promotion_start} - ${this.header.promotion_end}`;
    }
    this.dataSource = new CustomStore<any>({
      key: ['promotion_header_id', 'promotion_group_id'],
      load: async () => await lastValueFrom(this.crudService.getData(
        `${this.apiUrl}media-planning?edlp=1` +
            `&promotion_period_id=${this.header.promotion_period_id}` +
            `&buying_group_id=${this.header.buying_group_id}`)) //+
            // `&promotion_start=${this.header.promotion_start}` +
            // `&promotion_end=${this.header.promotion_end}`))
      .then(async (result:any) => {
        console.log(result);
        this.mediaPlanning = result;
        this.mediaTypes = await this.getMediaTypes();
        this.detailsLoading = false;
        return result;
    }),
      insert: async (values: MediaTypes) => {
        //
      },
      update: async (key, values: any) => {
        const rowIndex: number = this.mediaPlanningGrid.instance.getRowIndexByKey(key);
        const selectedRow: any = this.mediaPlanningGrid.instance.selectRowsByIndexes([rowIndex]);
        const rowData: any = this.mediaPlanningGrid.instance.getSelectedRowsData();
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        const keys: string[] = Object.keys(values);

        const updatePromises = keys.map(async (currentKey: string) => {
          const mediaType = this.mediaTypes.find(x => x.media_type_name === currentKey) || {} as MediaTypes;
          console.log('values', values[currentKey]);
          const updatedValues = {
            media_type_id: mediaType.id || 0,
            media_plan_description: (values[currentKey] !== '' && values[currentKey] !== null) ? values[currentKey] : 'API_DELETE',
            group_id: parsedDetails.data.group_id,
            location_id: parsedDetails.data.location_id,
            promotion_header_id: rowData[0].promotion_header_id,
            promotion_group_id: rowData[0].promotion_group_id,
            linked_promotion_period_id: rowData[0].linked_promotion_period_id
          };

          delete values[currentKey];

          console.log(updatedValues);
          console.log('Key: ', key);

          return lastValueFrom(
            this.crudService.updateData(`${this.apiUrl}media-planning`, 1, updatedValues).pipe(
              tap((result: any) => {
                if (result.success) {
                  notify({ message: result.data }, 'success');
                } else {
                  notify({ message: result.data }, 'error');
                }
              })
            )
          );
        });
        await Promise.all(updatePromises);
      },

      remove: async (key) => {
        await lastValueFrom(this.crudService.deleteData(this.apiUrl, key));
      },
    });

    if(localStorage.getItem('selectedPromo') !== null) {
      this.header = JSON.parse(localStorage.getItem('selectedPromo') || '{}');

      this.promoInfo = `${this.header.promotion_name} Details: ${this.header.promotion_start} - ${this.header.promotion_end}`;
    }
    const storedMediaTypes: string | null = localStorage.getItem('media-types');
    const mediaTypes: MediaTypes[] = storedMediaTypes ? JSON.parse(storedMediaTypes) : [];
    mediaTypes.forEach((mediaType: MediaTypes) => {
      if(mediaType.media_type_field_type === 'Checkbox' || mediaType.media_type_field_type === 'Number'){
        this.totalItems.push({column: mediaType.media_type_name, summaryType: 'sum', name: 'maxTotal', customizeText: this.customiseText(mediaType.max)});
      }
    });
  }

onContentReady(e: any): void {
  this.setUpMutationObserver();
}
currentFilter: any;
  showFilterRow: boolean | null | undefined = false;
  // Function to toggle the filter row visibility
  toggleFilterRow(): void {
    this.showFilterRow = !this.showFilterRow;
    this.clearFilter();
  }
  clearFilter() {
    this.mediaPlanningGrid.instance.clearFilter();
  }
setUpMutationObserver() {
  try {
    const summaryRow = this.mediaPlanningGrid.instance.element().getElementsByClassName('dx-datagrid-total-footer');
    if (!summaryRow || summaryRow.length === 0) {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      this.recalculateSummary();
    });
    observer.observe(summaryRow[0], { childList: true, subtree: true, characterData: true });
  } catch (error) {
    console.error('Error setting up MutationObserver:', error);
  }
}

recalculateSummary = () => {
  const summaryCells = this.mediaPlanningGrid.instance.element().querySelectorAll('.dx-datagrid-summary-item');
  summaryCells.forEach((cell: Element) => {
    const textContent = cell.textContent || '';
    const parts = textContent.split('/');

    if (parts.length === 2) {
      const currentValue = parseFloat(parts[0]); // Assumes format "value/max". // IF FORMAT CHANGES, CHANGE THIS.
      const maxValue = parseFloat(parts[1]);
      if (currentValue > maxValue) {
        (cell as HTMLElement).style.color = 'red';
      } else {
        (cell as HTMLElement).style.color = '';
      }
    }
  });
};
  customiseText = (max: number) => {
    return (e: any) => {
      if(max === 0){
        return `${e.value}`;
      } else {
        return `${e.value}/${max}`;
      }
    };
  }
  convertApiBoolean(apiValue: number): boolean {
    return apiValue === 1;
  }

  async getMediaTypes(): Promise<MediaTypes[]> {
    const mediaTypes: MediaTypes[] =  await lastValueFrom(this.crudService.getData(`${this.apiUrl}media-types`));
    localStorage.setItem('media-types', JSON.stringify(mediaTypes));
    return mediaTypes;
  }

  customiseColumns = (columns: any) => {
    const storedMediaTypes: string | null = localStorage.getItem('media-types');
    const mediaTypes: MediaTypes[] = storedMediaTypes ? JSON.parse(storedMediaTypes) : [];
    columns.forEach((column: any) => {
      if (column.dataField === 'promotion_period_id') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_header_id') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_group_id') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'linked_promotion_period_id') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
        column.dataType = 'number';
      }
      if (column.dataField === 'promotion_name') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_start') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_end') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'buying_group_name') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'store_position_name') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'planning_category_name') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_name') {
        column.allowEditing = false;
        column.fixed = true;
        column.visible = false;
      }
      if (column.dataField === 'promotion_group_name') {
        column.allowEditing = false;
        column.fixed = true;
      }
      if (column.dataField === 'price') {
        column.allowEditing = false;
        column.fixed = true;
      }
      if (column.dataField === 'edlp') {
        column.allowEditing = false;
        column.fixed = false;
      }
      const mediaType = mediaTypes.find(x => x.media_type_name === column.dataField);
      if(column.dataField !== 'promotion_group_name' && column.dataField !== 'promotion_header_id' && mediaType){
        column.caption = mediaType.media_type_name;
        column.dataField = mediaType.media_type_name;
        column.dataType = (() => {
          switch (mediaType.media_type_field_type) {
            case 'Text':
              return 'string';
            case 'Number':
              return 'number';
            case 'Checkbox':
              return 'boolean';
            default:
              return 'string';
          }
        })();
      }
    });
  }

  returnToPlanning = (e:any) =>{
    this.router.navigate(['/promotions']);
  }

  canDeactivate(): Promise<boolean> {
    if (this.mediaPlanningGrid && this.mediaPlanningGrid.instance.hasEditData()) {
      return this.confirmationService.confirmUnsavedChangesNavigation();
    } else {
      return Promise.resolve(true);
    }
  }

  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Slotting Board');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if(gridCell?.rowType === 'header') {
          excelCell.font = { bold: true };
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '6ABDC8' } };
        }
        if(gridCell?.rowType === 'data' && gridCell?.column?.dataType === 'boolean') {
          excelCell.value = gridCell?.value ? true : false;
        }
        if(gridCell?.rowType === 'totalFooter') {
          excelCell.font = { bold: true };
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } };
        }
        console.log('gridCell', gridCell);
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${this.header.promotion_name}_media_plan.xlsx`);
      });
    });
  }
}

export class totalItems {
  column: string = '';
  summaryType: string = '';
  customizeText?: any;
  calculateCustomSummary?: any;
  name: string = '';
}
