import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  constructor() { }

  getRegions(): Region[]
  {
    return Regions;
  }

}

const Regions = [
  {
    id: 1,
    region: 'ACT'
  }, {
    id: 2,
    region: 'NSW'
  }, {
    id: 3,
    region: 'NT'
  }, {
    id: 4,
    region: 'QLD'
  }, {
    id: 5,
    region: 'SA'
  }, {
    id: 6,
    region: 'TAS'
  }, {
    id: 7,
    region: 'VIC'
  }, {
    id: 8,
    region: 'WA'
  },
];

export class Region
{
  id: number;
  region: string;
}
