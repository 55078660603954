import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

export interface InfoCard {
  title: string;
  count: number;
  info: string; // information regarding the notification (i.e. "Unmatched Products" or "Last Host File Update)
  type: string; //warning, error, info
}

@Injectable({
  providedIn: 'root'
})
export class InfoCardsService {
  private baseURL = environment.baseApiUrl;
   infoCardsSubject: BehaviorSubject<InfoCard[]> = new BehaviorSubject<InfoCard[]>([]);
   infoCards: Observable<InfoCard[]> = this.infoCardsSubject.asObservable();
  infoCardsCount: number = 0;

infoCardsCountByTypeSubject: BehaviorSubject<{ [type: string]: number }> = new BehaviorSubject<{ [type: string]: number }>({});
  constructor(private http: HttpClient, private crudService: CrudService) { }
  fetchInfoCards(): void {
    this.fetchLastHostFileUpdate();
    this.fetchUnmatchedProducts();
    this.fetchLatestHostFile();
    this.infoCards.subscribe(cards => {
    let countsByType: { [type: string]: number } = {};
    cards.forEach(card => {
        if (!countsByType[card.type]) {
          countsByType[card.type] = 0;
        }
        countsByType[card.type]++;
      });
  
      this.infoCardsCountByTypeSubject.next(countsByType);
    });
  }

   fetchLastHostFileUpdate(): void {
    this.crudService.getData(`${this.baseURL}config/LastHostFileUpdate`).subscribe((data:any) => {
      if (data && data.length > 0) {
        const newInfoCard: InfoCard = {
          title: 'Last Host File Update',
          count: 0,
          info: data[0].config_string,
          type: 'info'
        };
        this.updateInfoCards(newInfoCard);
      }
    });
  }
  fetchLatestHostFile(): void {
    this.crudService.getData(`${this.baseURL}config/LatestHostFile`).subscribe((data:any) => {
      if (data && data.length > 0) {
        const newInfoCard: InfoCard = {
          title: 'Latest Host File',
          count: 0,
          info: data[0].config_string,
          type: 'info'
        };
        this.updateInfoCards(newInfoCard);
      }
    });
  }
  countInfoCards() {
    this.infoCards.subscribe(cards => {
      this.infoCardsCount = cards.length; // Update the count based on the emitted array's length
      console.log("count", this.infoCardsCount);
    });
    
  }
   fetchUnmatchedProducts(): void {
    this.crudService.getData(`${this.baseURL}products-unmatched`).subscribe((data:any) => {
      if (data && data.length > 0) {
        const newInfoCard: InfoCard = {
          title: 'Unmatched Products',
          count: data.length,
          info: data.length.toString(),
          type: 'warning' 
        };
        this.updateInfoCards(newInfoCard);
      }
    });
  }
 
  
  /*
  fetchErrorExample(): void {
    this.crudService.getData(`${this.baseURL}products-unmatched`).subscribe((data:any) => {
      if (data && data.length > 0) {
        const newInfoCard: InfoCard = {
          title: 'Missing Products',
          count: data.length,
          info: data.length.toString(),
          type: 'error'
        };
        this.updateInfoCards(newInfoCard);
      }
    });
  }
  */
  updateInfoCards(newCard: InfoCard): void {
    const currentCards = this.infoCardsSubject.value;
    // Check for an existing card with the same title to replace, or add the new one
    const cardIndex = currentCards.findIndex(card => card.title === newCard.title);
  
    if (cardIndex > -1) {
      // Replace existing card
      const updatedCards = currentCards.slice();
      updatedCards[cardIndex] = newCard;
      this.infoCardsSubject.next(updatedCards);
    } else {
      // Add new card
      this.infoCardsSubject.next([...currentCards, newCard]);
    }
  }
}
