import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { LocalDataService } from 'src/app/services/local-data.service';
import { DxoLookupComponent } from 'devextreme-angular/ui/nested';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotifyService } from 'src/app/services/notify.service';
import { PromotionService } from 'src/app/services/promotions-data-sync.service';
import { ActivatedRoute, Router } from '@angular/router';
interface ApiResponse {
  data: any[];
}
@Component({
  selector: 'app-promotion-names',
  templateUrl: './promotion-names.component.html',
  styleUrls: ['./promotion-names.component.css']
})
export class PromotionNamesComponent implements AfterViewInit{
  @ViewChild('lookupComponent') lookupComponent: DxoLookupComponent;
  dataSource: any;
  endpoint: string = 'promotion-periods';
  editPromotionNames:boolean = false;
  isLoadingData:boolean = false;
  private apiUrl: string = environment.baseApiUrl + this.endpoint;
  private baseUrl: string = environment.baseApiUrl;
  @ViewChild("promotionNameGrid", { static: false }) public dataGrid: DxDataGridComponent;
  @Input() isVisible: boolean;
  @Output() onClose = new EventEmitter<void>();
  constructor(private crudService: CrudService,
    private localData: LocalDataService,
    private confirmationService:ConfirmationService,
    private horizontalMenuService:HorizontalMenuService,
    private notifyService:NotifyService, private promotionService:PromotionService, private cdr:ChangeDetectorRef,private route: ActivatedRoute,
    private router: Router) {
  }

  ngAfterViewInit(){
    this.horizontalMenuService.editPromotionNames$.subscribe((value: boolean) => {
      this.editPromotionNames = value;
      this.cdr.detectChanges();
    });
   
      // Check if the current route is 'promotion-status'
      const currentRoute = this.route.snapshot.routeConfig?.path;
      if (currentRoute === 'promotion-names') {
        this.loadData();
      }

  }

   loadData() {
    this.dataSource =
    {
      store: new CustomStore<any>({
      key: ['id'],
      load: async () => {
        const response = await this.crudService.getData(this.baseUrl + 'promotion-names').toPromise() as ApiResponse;
        console.log("promotion-names response: ", response)
        return response;
    },
      insert: async (values: any) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
    
        return await new Promise(async (resolve) => {
          await this.crudService.insertData(this.apiUrl, values)
          .subscribe((result: any) => {
            this.localData.addData(this.endpoint, values);
            this.notifyService.tellUser(result);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
          await this.crudService.updateData(this.baseUrl+'promotion-names', key.id, values)
          .subscribe((result: any) => {
            this.localData.updateData(this.endpoint, key, values);
            this.notifyService.tellUser(result);
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          await this.crudService.deleteData(this.apiUrl, key.id)
          .subscribe((result: any) => {
            this.localData.removeData(this.endpoint, key.id);
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      }
    })
  }
  }
  onHiding(event:any){
  this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditPromotionNames(value))
      .then((canHide:boolean) => {
        //if canHide checks if use can hide profile popup.
        if(canHide){
          this.horizontalMenuService.seteditPromotionNames(false);
        }
      });
    }
}
