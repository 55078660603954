import { Component, EventEmitter, OnInit, Output,ViewChild} from '@angular/core';
import { ProductGroup } from 'src/app/models/product-group.model';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { LocalDataService } from 'src/app/services/local-data.service';
import { environment } from 'src/environments/environment';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.css']
})
export class ProductGroupsComponent implements OnInit {
  productGroups: ProductGroup[];
  dataSource: any;
  editProdGroups: boolean = false;
  
  private apiUrl: string = environment.baseApiUrl;
  @Output() groupList = new EventEmitter<ProductGroup[]>();
  @ViewChild("productGroupGrid", { static: false }) public dataGrid: DxDataGridComponent;
  
  constructor(private crudService: CrudService,
    private localData: LocalDataService, private horizontalMenuService: HorizontalMenuService, private confirmationService:ConfirmationService) {
      this.dataSource = new CustomStore<any>({
        key: 'id',
        load: async () => {
          return await new Promise((resolve) => {
            this.crudService.getData(`${this.apiUrl}product-groups`).subscribe((result: any) => {
              this.groupList.emit(result);
              localStorage.setItem('product-groups', JSON.stringify(result));
              resolve({data: result, totalCount: result.length});
            })
          })
      },
        insert: async (values) => {
          const userDetails = localStorage.getItem('userDetails');
          const parsedDetails = JSON.parse(userDetails || '{}');
          values.group_id = parsedDetails.data.group_id;
          values.location_id = parsedDetails.data.location_id;
          console.log(values);
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.insertData(`${this.apiUrl}product-groups`, values)
            .subscribe((result: any) => {
              this.localData.addData('product-groups', values);
              resolve(result);
            });
          });
        },
        update: async (key, values) => {
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.updateData(`${this.apiUrl}product-groups`, key, values)
            .subscribe((result: any) => {
              this.localData.updateData('product-groups', key, values);
              resolve(result);
            });
          });
        },
        remove: async (key) => {
          return await new Promise(async (resolve) => {
            const result: any = await this.crudService.deleteData(`${this.apiUrl}product-groups`, key)
            .subscribe((result: any) => {
              this.localData.removeData('product-groups', key);
              resolve(result);
            });
          });
        }
      })

      this.horizontalMenuService.editProdGroups$.subscribe((value: boolean) => {
        this.editProdGroups = value;
      });
    }

  ngOnInit(): void {
  }
  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditProdGroups(value))
    this.horizontalMenuService.seteditProdGroups(false);
  }
}
