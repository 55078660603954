<dx-data-grid
    #productSelectionGrid
    [dataSource]="dataSource"
    [remoteOperations]="false"
    keyExpr="id"
    [rowAlternationEnabled]="true"
    [repaintChangesOnly]="true"
    [allowColumnResizing]="true"
    [showColumnLines]="true"
    height="95%"
  >
    <dxo-selection
      mode="multiple"
    ></dxo-selection>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="productSelectionState"
    ></dxo-state-storing>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item>
        <dx-switch
          [value]="false"
          hint="Filter for slotted products only"
          [switchedOnText]="'Promotion Products'"
          [switchedOffText]="'All Products'"
          [visible]="showSwitch"
          (onValueChanged)="productsByPromotionGroupId($event)"></dx-switch>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="exportselected" hint="Add selected products" (onClick)="selectProducts($event)"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
      <dxi-item name="groupPanel"></dxi-item>
      <dxi-item name="filterPanel"></dxi-item>
    </dxo-toolbar>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Search..."
    ></dxo-search-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxi-column dataField="id" dataType="number" [visible]="false"></dxi-column>
    <dxi-column dataField="product_code" dataType="string" caption="Code"></dxi-column>
    <dxi-column dataField="product_name" dataType="string" caption="Name" width="200"></dxi-column>
    <dxi-column dataField="supplier_name" dataType="string" caption="Supplier Name" width="200"></dxi-column>
    <dxi-column dataField="product_group_name" dataType="string" caption="Group Name" width="200"></dxi-column>
    <dxi-column dataField="carton_quantity" dataType="integer" caption="Carton Qty" [allowSearch]="false"></dxi-column>
    <dxi-column dataField="luc_ex_gst" dataType="float" caption="LUC exGST" [allowSearch]="false"></dxi-column>
    <dxi-column dataField="cost_inc_gst" dataType="float" caption="Cost Inc." [allowSearch]="false"></dxi-column>
  </dx-data-grid>
