<div class="grid">
<dx-data-grid #promotionStatusGrid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
>
<dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="status" dataType="integer" caption="Status"></dxi-column>
  <dxi-column dataField="name" dataType="string" caption="Name"></dxi-column>
  <dxi-column dataField="type" caption="Type">
    <dxo-lookup
      [dataSource]="promotionStatusTypes"
      valueExpr="type"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>
</dx-data-grid>
</div>