import { Injectable } from '@angular/core';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
/*
                          //EXAMPLE FOR NAVIGATING URLS FOR CHECKING DEVEXPRESS DATAGRID UPDATES//

                              canDeactivate(): Promise<boolean> {
                                if (this.DATAGRID && this.DATAGRID.instance.hasEditData()) {
                                  return this.confirmationService.confirmUnsavedChangesNavigation();
                                } else {
                                  return Promise.resolve(true);
                                }
                              }



                      //EXAMPLE FOR CHECKING DEVEXPRESS DATAGRID UPDATES ON POPUPS//

                            onHiding(event:any){
                      this.confirmationService.checkUnsaved(event, this.DATAGRID, (value:boolean) => this.horizontalMenuService.setINSERTCOMPONENTSETTER(value))
                }
*/
  constructor() { }

  /**
   * This function is intended to be used within the 'onHiding' event of popups.
   * It shows a confirmation dialog to the user when there are unsaved changes.
   *
   * @param {any} event - The 'onHiding' event object.
   * @param {any} initDetails - The initial state of the details/data.
   * @param {any} currentDetails - The current state of the details/data.
   * @param {boolean} hasSaved - Indicates whether the 'Save' button was pressed.
   * @param {Function} setActive - Setter Function to update the 'active' state of the popup.
   * 
   * @returns {Promise<boolean>} - A Promise that resolves to a boolean indicating
   * whether the popup should close ('true') or stay open ('false').
   */
  confirmUnsavedChanges(event: any, hasSaved: boolean, setActive:Function): Promise<boolean> {
    // Return a new Promise
    return new Promise((resolve, reject) => {
      

      // If details are different and the data has not been saved
      if (!hasSaved) {

        // Show a confirmation dialog
        confirm("Are you sure you want to leave? Unsaved data will be lost.", "Confirm Title").then((result) => {
          
          // If the user confirmed
          if (result) {
            // Do not cancel the 'onHiding' event
            event.cancel = false;
            
            // Resolve the Promise as 'true' (i.e., it's OK to close the popup)
            resolve(true);
            
            // Set the popup to inactive
            setActive(false);
          } 
          // If the user canceled
          else {
            // Cancel the 'onHiding' event
            event.cancel = true;
            
            // Resolve the Promise as 'false' (i.e., keep the popup open)
            resolve(false);
          }
        });
        
        // By default, cancel the 'onHiding' event until the user has made a decision
        event.cancel = true;
      } 
      // If details are the same or the data has been saved
      else {
        // Resolve the Promise as 'true' (i.e., it's OK to close the popup)
        resolve(true);
      }
    });
  }

  confirmUnsavedChangesProfilePopup(event: any, hasSaved: boolean, setActive:Function,initDetails?: any, currentDetails?: any): Promise<boolean> {
    // Return a new Promise
    return new Promise((resolve, reject) => {
      
      // Check if initial and current details are different
      const areDetailsDifferent = JSON.stringify(initDetails) !== JSON.stringify(currentDetails);
      
      // If details are different and the data has not been saved
      if (areDetailsDifferent && !hasSaved) {

        // Show a confirmation dialog
        confirm("Are you sure you want to leave? Unsaved data will be lost.", "Confirm Title").then((result) => {
          
          // If the user confirmed
          if (result) {
            // Do not cancel the 'onHiding' event
            event.cancel = false;
            
            // Resolve the Promise as 'true' (i.e., it's OK to close the popup)
            resolve(true);
            
            // Set the popup to inactive
            setActive(false);
          } 
          // If the user canceled
          else {
            // Cancel the 'onHiding' event
            event.cancel = true;
            
            // Resolve the Promise as 'false' (i.e., keep the popup open)
            resolve(false);
          }
        });
        
        // By default, cancel the 'onHiding' event until the user has made a decision
        event.cancel = true;
      } 
      // If details are the same or the data has been saved
      else {
        // Resolve the Promise as 'true' (i.e., it's OK to close the popup)
        resolve(true);
      }
    });
  }
  /** checks unsaved datagrid data in popup (devexpress data grid) */
  checkUnsaved(event: any, dataGrid:any, setActive: Function): Promise<boolean>{
    return new Promise((resolve, reject) => {
      console.log("hasdata", dataGrid.instance.hasEditData())
    if (dataGrid && dataGrid.instance.hasEditData()) {
      return this.confirmUnsavedChanges(event, !dataGrid.instance.hasEditData(),setActive).then((canHide:boolean) =>{
        if(canHide){
          dataGrid.instance.cancelEditData()
        }
      });
    } else {
      return resolve(true);
    }
  })
  }
  /** checks unsaved datagrid on page for when navigating to a new route (devexpress datagrid) */
  confirmUnsavedChangesNavigation(): Promise<boolean> {
    // Return a new Promise
    return new Promise((resolve, reject) => {
      // Show a confirmation dialog
      confirm("Are you sure you want to leave? Unsaved data will be lost.", "Confirm Title").then((result) => {
        // If the user confirmed
        if (result) {
          // Resolve the Promise as 'true' (i.e., it's OK to navigate away)
          resolve(true);
        } 
        // If the user canceled
        else {
          // Resolve the Promise as 'false' (i.e., prevent navigation)
          resolve(false);
        }
      });
    });
  }

}


/*
may require a dev extreme shown event to set init details on popup load/shown.
shown(e:any){
  this.initUserDetails = JSON.parse(JSON.stringify(this.userDetails));
  this.hasSaved = false;
}
*/
