import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { AuthGuard } from 'src/app/auth.guard.service';
import themes from 'devextreme/ui/themes';
import { refreshTheme } from "devextreme/viz/themes";
import { UserDetails } from 'src/app/models/user-details';
import { environment } from 'src/environments/environment';
import { confirm } from "devextreme/ui/dialog"
import { ProfileService } from 'src/app/services/profile.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DxValidatorComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import notify from 'devextreme/ui/notify';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent {
  @ViewChild('targetValidator', { static: false }) confirmPasswordValidator: DxValidatorComponent;
  themeLabel: string;
  switchValue: boolean;
  authApiUrl: string = environment.authApiUrl;
  initials: string;
  confirmDialogVisible: boolean = false;
  editProfile: boolean = false;
  buttonOptions: any = {
    text: 'Save',
    useSubmitBehavior: true,
    type: 'success',
  };
  profileSettings = [
    { value: 1, name: 'Profile', icon: 'user' },
    { value: 2, name: 'Logout', icon: 'runner' }
  ];
  userDetails: UserDetails = {
    data: {
      id: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      country: '',
      email: '',
      group_id: 0,
      location_id: 0,
      location_token: '',
      location_type: '',
      state: '',
      suburb: '',
      theme: 0,
      username: ''
    },
    message: '',
    success: false
  };
  initUserDetails: UserDetails;
  hasSaved: boolean = false;
  showPasswordFields: boolean = false;
  buttonText: string = "Change Password";
  newPassword: string = "";
  oldPassword: string = "";
  password_confirmation: string = "";
  confirmPassword: string = "";
  passwordValidated: boolean = false;
  constructor(private auth: AuthGuard, private profileService:ProfileService, private confirmationService:ConfirmationService) {
    this.auth.userDetails().subscribe(data => {
      this.userDetails = data
      this.initUserDetails = JSON.parse(JSON.stringify(this.userDetails));
      document.body.style.backgroundColor = environment.bgColours[data.data.theme];
      this.themeLabel = data.data.theme === 0 ? 'Light' : 'Dark';
      this.switchValue = data.data.theme === 0 ? false : true;
      this.initials = this.getInitials();
      console.log(`theme label: ${this.themeLabel}`);
    });
    //subscribe to editprofile boolean service. 
    //(provides a connection between the header profile button and this component)
    this.profileService.editProfile$.subscribe((value: boolean) => {
      this.editProfile = value;
    });
  }

  changeTheme(event: any): void {
    this.hasSaved = false;
    if(event.value === true && this.themeLabel === 'Light') {
      themes.current('material.blue.dark.compact');
      document.body.style.backgroundColor = '#363640';
      this.themeLabel = 'Dark';
      this.userDetails.data.theme = 1;
    }else if(event.value === false && this.themeLabel === 'Dark') {
      themes.current('material.blue.light.compact');
      document.body.style.backgroundColor = '#ffffff';
      this.themeLabel = 'Light';
      this.userDetails.data.theme = 0;
    }
    localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
    refreshTheme();
  }
 
  getUserDetails(): UserDetails {
    return JSON.parse(localStorage.getItem('userDetails') || '{}');
  }
  public oldPasswordValid$: Observable<boolean>;
  handleSubmit = (e: any) => {
    e.preventDefault();  // It's typically a good idea to call this at the start to prevent default form submission behavior.
  
    const detailsChanged = this.detailsHaveChanged();
    if (!this.showPasswordFields) {
      this.verifyAndUpdateUser(this.initUserDetails.data.email, this.password_confirmation);
    } else {
      this.verifyAndUpdateUser(this.initUserDetails.data.email, this.oldPassword, true);
    }
  }
  
  detailsHaveChanged = () => {
    return JSON.stringify(this.initUserDetails) !== JSON.stringify(this.userDetails);
  }
  
  verifyAndUpdateUser = (email: string, password: string, isPasswordChange: boolean = false) => {
    this.auth.verifyLoginDetails(email, password).subscribe(
      (isValid: boolean) => {
        console.log(isValid);
        if (!isValid) {
          console.log("Invalid");
          notify("Unauthorised", 'error', 3000);
        } else {
          console.log("Valid");
          this.updateUserDetails(isPasswordChange);
          this.hasSaved = true;
          this.profileService.setEditProfile(false);
        }
      },
      (error) => {
        console.error('Error occurred during login verification', error);
      }
    );
  }
  
  updateUserDetails = (isPasswordChange: boolean) => {
    if (this.detailsHaveChanged() || (isPasswordChange && this.passwordFieldsValid())) {
      localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
      console.log("User details updated.");
      this.auth.updateUserDetails(this.userDetails, isPasswordChange ? this.newPassword : undefined);
      this.resetFormState();
      this.hasSaved = true;
      this.profileService.setEditProfile(false);
    }
  }
  
  passwordFieldsValid = () => {
    return this.newPassword && this.confirmPassword && (this.confirmPassword === this.newPassword) && this.oldPassword !== '';
  }
  
  resetFormState = () => {
    this.password_confirmation = '';
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.showPasswordFields = false;
  }
  
  
  handleClick(): void {
    console.log('Button clicked!');
}
togglePasswordFields = () => {
  this.showPasswordFields = !this.showPasswordFields;
  this.buttonText = this.showPasswordFields ? "Cancel" : "Change Password";
}
handlePasswordValidation(event: any) {
  if (this.newPassword && this.confirmPassword) {
    this.confirmPasswordValidator.instance.validate();
  }
}
comparePasswords = () => this.newPassword;
  getInitials(): string {
    if(this.userDetails) {
      const words = this.userDetails.data.username.split(' ');
      const firstLetterOfFirstWord = words[0].charAt(0);
      const firstLetterOfSecondWord = words.length > 1 ? words[1].charAt(0) : '';
      return `${firstLetterOfFirstWord}${firstLetterOfSecondWord}`;
    }
    return '';
  }
  
  onHiding(event: any) {
    this.confirmationService.confirmUnsavedChangesProfilePopup(event, this.hasSaved, (value:boolean) => this.profileService.setEditProfile(value),this.initUserDetails, this.userDetails)
      .then((canHide:boolean) => {
        //if canHide checks if use can hide profile popup.
        if (canHide && !this.hasSaved) {
          if(event){
            //console.log(event);
            //handle what to do if unsaved and pressed yes
            this.userDetails = JSON.parse(JSON.stringify(this.initUserDetails));
            if(this.userDetails.data.theme == 1){
              themes.current('material.blue.dark.compact');
              document.body.style.backgroundColor = '#363640';
              this.themeLabel = 'Dark';
              this.switchValue = true;
            }
            else if(this.userDetails.data.theme == 0){
              themes.current('material.blue.light.compact');
              document.body.style.backgroundColor = '#ffffff';
              this.themeLabel = 'Light';
              this.switchValue = false;
            }
            localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        }
        this.hasSaved = false;
        }
        else {
          console.log("Not hiding");
        }
      })
      .catch((error:any) => {
        console.log("Confirmation failed:", error);
      });
  }
shown(e:any){
  this.initUserDetails = JSON.parse(JSON.stringify(this.userDetails));
  this.hasSaved = false;
}
onFieldDataChanged(e:any){
  //localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
  //const areDetailsDifferent = JSON.stringify(this.initUserDetails) !== JSON.stringify(this.userDetails);
  //if(areDetailsDifferent){
  //  console.log("changed");
 // }
  //this.userDetails = JSON.parse(JSON.stringify(this.initUserDetails));
}
  }

