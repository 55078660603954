<div class="grid">
<dx-data-grid #mixMatchGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  height="80vh"
>
<dxo-editing
    mode="row"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>
  <dxi-column dataField="name" dataType="string" caption="Name" width="15%">
    <dxi-validation-rule type="required" message="Mix n Match name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="total_price" dataType="number" caption="Total Price"></dxi-column>
  <dxi-column dataField="free_item_id" dataType="number" caption="Free Item"></dxi-column>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
  <div class="master-detail-caption">
    <span>Selection Levels</span>
  </div>
  <div *dxTemplate="let item of 'detail'">
    <app-mix-match-level [key]="item.data.id"></app-mix-match-level>
  </div>

</dx-data-grid>
</div>