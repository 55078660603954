<dx-box direction="row" width="auto" class="box" crossAlign="center">
  <dxi-item ratio="1" baseSize="auto" class="padding">
    <div class="dx-field-label clone_label">Copy Pricing From:</div>
    <div class="dx-field-value clone_value">
        <dx-select-box class="clone_cell"
            [dataSource]="coreRangeHeaders"
            valueExpr="id"
            displayExpr="lookup_name"
            (onValueChanged)="setCoreRangeHeader($event)"
            >
        </dx-select-box>
    </div>
  </dxi-item>
</dx-box>
<dx-box direction="row" width="auto" class="box" crossAlign="center">
  <!-- Clone Button -->
  <dxi-item class="clone_row"  baseSize="auto" class="padding">
    <dx-button width="100px"
        text="Copy"
        [type]="'default'"
        [validationGroup]="'clonePromotion'"
        (onClick)="copyPricing($event)"
        ></dx-button>
  </dxi-item>

  <!-- Cancel Button -->
  <dxi-item class="clone_row"  baseSize="auto" class="padding">
      <dx-button width="100px"
      stylingMode="outlined"

          text="Cancel"
          [type]="'danger'"
          [validationGroup]="'clonePromotion'"
          ></dx-button>
</dxi-item>
</dx-box>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#employee' }"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
