import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeekRangeService {

  constructor() { }

  getWeekRange(): WeekRange[]
  {
    return NumberOfWeeks;
  }
}

const NumberOfWeeks: WeekRange[] = [
  {
    id: 1,
    weeks: 4,
    name: '4 weeks'
  }, {
    id: 2,
    weeks: 8,
    name: '8 weeks'
  }, {
    id: 3,
    weeks: 12,
    name: '12 weeks'
  }, {
    id: 4,
    weeks: 16,
    name: '16 weeks'
  }, {
    id: 5,
    weeks: 20,
    name: '20 weeks'
  }, {
    id: 6,
    weeks: 24,
    name: '24 weeks'
  }, {
    id: 7,
    weeks: 30,
    name: '30 weeks'
  }, {
    id: 8,
    weeks: 34,
    name: '34 weeks'
  }, {
    id: 9,
    weeks: 38,
    name: '38 weeks'
  }, {
    id: 10,
    weeks: 42,
    name: '42 weeks'
  }, {
    id: 11,
    weeks: 46,
    name: '46 weeks'
  }, {
    id: 12,
    weeks: 50,
    name: '24 weeks'
  }, {
    id: 13,
    weeks: 54,
    name: '54 weeks'
  }
]

export class WeekRange
{
  id: number;
  weeks: number;
  name: string;
}