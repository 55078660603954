import { Injectable } from '@angular/core';

export class SimpleObject {

  constructor() { }

  value: number | string;

  name: string;

  icon?: string;

  badge?: string;

}

const profileSettings: SimpleObject[] = [
  { value: 1, name: 'Profile', icon: 'user' },
  { value: 2, name: 'Logout', icon: 'runner' }
];

const setupOptions: SimpleObject[] = [
  { value: 1, name: 'Product Groups' },
  { value: 2, name: 'Products' },
  { value: 3, name: 'Promotion Groups' },
  { value: 4, name: 'Promotion Periods' }
]

@Injectable({
  providedIn: 'root'
})

export class SimpleService {
  getProfileSettings(): SimpleObject[] {
    return profileSettings;
  }

  getSetupOptions(): SimpleObject[] {
    return setupOptions;
  }
}

export class Holidays {
  date: string;
  holiday: string;
  region: string;
  week_number: string;
}

const holidays: Holidays[] = [
  {
    date: '2023-01-26',
    holiday: 'Australia Day',
    region: 'VIC',
    week_number: "w4"
  },
  {
    date: '2023-01-31',
    holiday: 'Last Day of the Month',
    region: 'VIC',
    week_number: "w4"
  },
  {
    date: '2023-3-13',
    holiday: 'Labour Day',
    region: 'VIC',
    week_number: "w11"
  }
];

export class HolidayService {
  getHolidays() {
    return holidays;
  }
}
