<div *ngIf="currentCoreRange" id="cloneCoreRangePopup">
    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Range Name -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Range Name:</div>
            <div class="dx-field-value clone_value">
                <dx-text-box class="clone_cell"
                    [(value)]="currentCoreRange.range_name"
                    (onValueChanged)="setRangeName($event)"
                    [showClearButton]="true">
                </dx-text-box>
            </div>
        </dxi-item>
        <!-- Buying Group -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Buying Group:</div>
            <div class="dx-field-value clone_value">
                <dx-select-box class="clone_cell"
                    [dataSource]="buyingGroups"
                    valueExpr="id"
                    displayExpr="buying_group_name"
                    [value]="currentCoreRange.buying_group_id"
                    (onValueChanged)="setBuyingGroup($event)">
                </dx-select-box>
            </div>
        </dxi-item>
    </dx-box>
    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Start Date -->
        <dxi-item ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">Start Date:</div>
            <div class="dx-field-value clone_value">
                <dx-date-box class="clone_cell"
                    [value]="currentCoreRange.start_date"
                    [displayFormat]="'dd-MM-yyyy'"
                    [type]="'date'"
                    [showClearButton]="true"
                    (onValueChanged)="setStartDate($event)">
                </dx-date-box>
            </div>
        </dxi-item>

        <!-- End Date -->
        <dxi-item class="buying_end" ratio="1" baseSize="auto" class="padding">
            <div class="dx-field-label clone_label">End Date:</div>

            <div class="dx-field-value clone_value">
                <dx-date-box class="clone_cell"
                    [value]="currentCoreRange.end_date"
                    [displayFormat]="'dd-MM-yyyy'"
                    [type]="'date'"
                    [showClearButton]="true"
                    (onValueChanged)="setEndDate($event)">
                </dx-date-box>
            </div>
        </dxi-item>
    </dx-box>

    <dx-box direction="row" width="auto" class="box" crossAlign="center">
      <!-- Rebate Clone Switch -->
      <dxi-item baseSize="auto" class="clone_row padding">
          <div class="dx-field-label clone_label">Rebate Clone:</div>
          <div class="dx-field-value clone_value">
              <dx-switch
                    [value]="rebateClone"
                    (onValueChanged)="setRebateClone($event)">
                    <div *dxTemplate="let data of 'onText'">Yes</div>
                    <div *dxTemplate="let data of 'offText'">No</div
              >
              </dx-switch>
          </div>
          <dxi-item class="clone_row padding" baseSize="auto"></dxi-item>
      </dxi-item>
    </dx-box>

    <dx-box direction="row" width="auto" class="box" crossAlign="center">
        <!-- Clone Button -->
        <dxi-item class="clone_row"  baseSize="auto" class="padding">
            <dx-button width="100px"
                text="Clone"
                [type]="'default'"
                [validationGroup]="'cloneCoreRange'"
                (onClick)="cloneCoreRange()"></dx-button>
        </dxi-item>

        <!-- Cancel Button -->
        <dxi-item class="clone_row"  baseSize="auto" class="padding">
            <dx-button width="100px"
            stylingMode="outlined"

                text="Cancel"
                [type]="'danger'"
                [validationGroup]="'clonePromotion'"
                (onClick)="cancel()"></dx-button>
        </dxi-item>
    </dx-box>
</div>

<dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#clonePromotionPopup' }"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false"
        >
</dx-load-panel>
