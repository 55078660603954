import { Component, ViewChild } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { HorizontalMenuService } from 'src/app/services/horizontalmenu.service';
import { PromotionService } from 'src/app/services/promotions-data-sync.service';
@Component({
  selector: 'app-buying-groups',
  templateUrl: './buying-groups.component.html',
  styleUrls: ['./buying-groups.component.css']
})
export class BuyingGroupsComponent {
  public dataSource: any;
  public accountCodes: any[];
  editBuyingGroups:boolean = false;
  private apiUrl: string = environment.baseApiUrl;
  public regions: string[] = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  @ViewChild("buyingGroupsGrid", { static: false }) public dataGrid: DxDataGridComponent;

  constructor(private crudService: CrudService,
              private confirmationService:ConfirmationService,
              private horizontalMenuService:HorizontalMenuService,
              private promotionService: PromotionService)
    {
    this.horizontalMenuService.editBuyingGroups$.subscribe((value: boolean) => {
      this.loadData();
      this.editBuyingGroups = value;
    });
  }

  loadData(){
    console.log("buying groups component");
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.crudService.getData(`${this.apiUrl}buying-groups`).subscribe((result: any) => {
            const userDetails = localStorage.getItem('userDetails');
            const parsedDetails = JSON.parse(userDetails || '{}');
            this.crudService.getData(`${this.apiUrl}host-data/${parsedDetails.data.location_token}`).subscribe((result: any) => {
              this.accountCodes = result.data;
            });
            localStorage.setItem('buying-groups', JSON.stringify(result));
            resolve({data: result, totalCount: result.length});
          })
        })
    },
      insert: async (values) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.insertData(`${this.apiUrl}buying-groups`, values)
          .subscribe((result: any) => {
            this.promotionService.requestRefreshGrid();
            resolve(result);
          });
        });
      },
      update: async (key, values) => {
        return await new Promise(async (resolve, reject) => {
          // Retrieve the full data for the row that is being updated
          const fullRowData = this.dataGrid.instance.getDataSource().items().find(item => item.id === key);
          // Use the updated buying_group_code if it exists in values, otherwise use the existing one (fallback)
          const buyingGroupCode = values.hasOwnProperty('buying_group_code') ? values.buying_group_code : fullRowData.buying_group_code;

          // Combine the updated values with the existing data
          const updatedData = { ...fullRowData, ...values, buying_group_code: buyingGroupCode };

          // Make the API call to update the data
          try {
            const result = await this.crudService.updateData(`${this.apiUrl}buying-groups`, key, updatedData).toPromise();
            this.promotionService.requestRefreshGrid();
            resolve(result);
          } catch (error) {
            reject(error);
          }
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          const result: any = await this.crudService.deleteData(`${this.apiUrl}buying-groups`, key)
          .subscribe((result: any) => {
            resolve(result);
          });
        });
      }
    })
  }
  onSaving(e: any) {
    const updatedRows = e.changes.filter((change:any) => change.type === 'update');

    updatedRows.forEach((change:any) => {
      const updatedRowData = change.data;
      const rowIndex = change.key; // This is typically the primary key of the row, e.g., id

      // Retrieve the full row data from the data source if necessary
      const fullRowData = e.component.getDataSource().items().find((item:any) => item.id === rowIndex);

      // Access buying_group_code of the updated row
      let buyingGroupCode = fullRowData ? fullRowData.buying_group_code : undefined;
      console.log(`Updated Row Index: ${rowIndex}, Buying Group Code: ${buyingGroupCode}`);
    });
  }

  onRadioValueChanged = (e: any, row: any) => {
    const data = this.dataGrid.instance.getDataSource().items();
    this.clearDefault(data, row.id);
  }

  clearDefault(items: any, currentID: number) {
    items.forEach((item: any) => {
      const rowIndex = this.dataGrid.instance.getRowIndexByKey(item.id);
      this.dataGrid.instance.cellValue(rowIndex, 'default', 0);
      this.dataSource.push([{type: 'update', data: {default: 0}, key: item.id}]);
      if (item.id === currentID) {
        this.dataGrid.instance.cellValue(rowIndex, 'default', 1);
        this.dataSource.push([{type: 'update', data: {default: 1}, key: currentID}]);
      }
    });
  }

  onHiding(event:any){
    this.confirmationService.checkUnsaved(event, this.dataGrid, (value:boolean) => this.horizontalMenuService.seteditBuyingGroups(value))
        .then((canHide:boolean) => {
          //if canHide checks if use can hide profile popup.
          if(canHide){
            this.horizontalMenuService.seteditBuyingGroups(false);
          }
        });
      }
}
