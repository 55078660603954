import { Injectable } from '@angular/core';

export interface IProducts {
  account?: string;
  campaign?: string;
  supplier?: number;
  caption?: string;
  productCode?: number;
  productDescription?: string;
  cartonQty?: number;
  invPrice?: number;
  lucExGst?: number;
  totalDiscount?: number;
  totalCostIncGst?: number;
  singleSell?: number;
  multiBuyQty?: number;
  multiBuyPrice?: number;
  gpPercent?: number;
  gpAmount?: number;
  moqCtn?: string;
  notes?: string;
  supplierName?: string;
  promoStartDate?: string;
  promoEndDate?: string;
  buyStartDate?: string;
  buyEndDate?: string;
  captionSequence?: number;
  groupId?: number;
  groupDesc?: string;
  promotionGroup?: string;
  qtyPerSaleUnit?: number;
  promotionId?: string;
  promotionFdte?: string;
  storeFormat?: string;
  promotionGroupId?: number;
}

const productData: IProducts[] =
[
  {
    productCode: 893091,
    productDescription: "19 Crimes Hard Chard 750ml",
    invPrice: 43.58,
    totalDiscount: 24.67,
    totalCostIncGst: 47.99,
    singleSell: 52.00,
    promotionId: "P675921",
	promotionGroupId: 1
  },{
    productCode: 893091,
    productDescription: "19 Crimes Tempranillo 750ml",
    invPrice: 44.02,
    totalDiscount: 3.23,
    totalCostIncGst: 51.98,
    singleSell: 52.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 970661,
    productDescription: "19 Crimes Sangiovese 750ml",
    invPrice: 44.02,
    totalDiscount: 3.23,
    totalCostIncGst: 51.98,
    singleSell: 52.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 592751,
    productDescription: "19 Crimes Uprising Red 750ml",
    invPrice: 35.51,
    totalDiscount: 3.14,
    totalCostIncGst: 41.98,
    singleSell: 67.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 559975,
    productDescription: "19 Crimes Shiraz 750ml",
    invPrice: 4.51,
    totalDiscount: 1.14,
    totalCostIncGst: 6.99,
    singleSell: 67.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 456707,
    productDescription: "19 Crimes Pinot Noir 750ml",
    invPrice: 51.04,
    totalDiscount: 3.68,
    totalCostIncGst: 61.98,
    singleSell: 67.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 740795,
    productDescription: "19 Crimes Cab Sauv 750ml",
    invPrice: 41.48,
    totalDiscount: 2.54,
    totalCostIncGst: 54.99,
    singleSell: 67.00,
    promotionId: "P658521",
	promotionGroupId: 1
  },{
    productCode: 765210,
    productDescription: "Carlt Dry Can 375ml 24pk",
    invPrice: 20.00,
    totalDiscount: 2.99,
    totalCostIncGst: 29.99,
    singleSell: 49.99,
    promotionId: "P658481",
	promotionGroupId: 2
  },{
    productCode: 755938,
    productDescription: "Carlt Dry Bt 330ml 24pk",
    invPrice: 20.00,
    totalDiscount: 1.00,
    totalCostIncGst: 29.99,
    singleSell: 49.99,
    promotionId: "P658481",
	promotionGroupId: 2
  },{
    productCode: 874079,
    productDescription: "Carlt Dry Peel Bt 330ml 24pk",
    invPrice: 20.00,
    totalDiscount: 1.50,
    totalCostIncGst: 29.99,
    singleSell: 49.99,
    promotionId: "P658481",
	promotionGroupId: 2
  },{
    productCode: 380624,
    productDescription: "Carlt Dry Lime Bt 355ml 24pk",
    invPrice: 20.00,
    totalDiscount: 2.65,
    totalCostIncGst: 29.99,
    singleSell: 49.99,
    promotionId: "P658481",
	promotionGroupId: 2
  },{
    productCode: 360077,
    productDescription: "Carlt Dry Can 375ml 24pk",
    invPrice: 20.00,
    totalDiscount: 3.65,
    totalCostIncGst: 29.99,
    singleSell: 49.99,
    promotionId: "P658481",
	promotionGroupId: 2
  },{
    productCode: 305268,
    productDescription: "Can Club & Dry 4.8% 10pk 375ml",
    invPrice: 35.50,
    totalDiscount: 2.00,
    totalCostIncGst: 45.00,
    singleSell: 88.99,
    promotionId: "P804291",
	promotionGroupId: 3
  },{
    productCode: 857112,
    productDescription: "Can Club & Cola 10pk 375ml",
     invPrice: 35.50,
    totalDiscount: 2.00,
    totalCostIncGst: 45.00,
    singleSell: 88.99,
    promotionId: "P804291",
	promotionGroupId: 3
  },{
    productCode: 527614,
    productDescription: "Absolut Passionfruit Vodka 700ml",
    invPrice: 35.00,
    totalDiscount: 6.50,
    totalCostIncGst: 45.95,
    singleSell: 180.00,
    promotionId: "P633890",
	promotionGroupId: 4
  },{
    productCode: 527614,
    productDescription: "Absolut Lime Vodka 700ml",
    invPrice: 35.00,
    totalDiscount: 6.50,
    totalCostIncGst: 45.95,
    singleSell: 180.00,
    promotionId: "P633890",
	promotionGroupId: 4
  },{
    productCode: 107081,
    productDescription: "Absolut Peach Vodka 700ml",
    invPrice: 35.00,
    totalDiscount: 6.50,
    totalCostIncGst: 45.95,
    singleSell: 180.00,
    promotionId: "P633890",
	promotionGroupId: 4
  },{
    productCode: 567669,
    productDescription: "Absolut Vodka 700ml",
    invPrice: 35.00,
    totalDiscount: 6.50,
    totalCostIncGst: 45.95,
    singleSell: 180.00,
    promotionId: "P633890",
	promotionGroupId: 4
  },{
    productCode: 454167,
    productDescription: "Absolut Rasp Vodka 700ml",
    invPrice: 35.00,
    totalDiscount: 6.50,
    totalCostIncGst: 45.95,
    singleSell: 180.00,
    promotionId: "P633890",
	promotionGroupId: 4
  },{
    productCode: 233176,
    productDescription: "Brown Bros Origins Cab Sauv 750ml",
    invPrice: 9.99,
    totalDiscount: 2.50,
    totalCostIncGst: 13.99,
    singleSell: 79.80,
    promotionId: "P610035",
	promotionGroupId: 5
  },{
    productCode: 610855,
    productDescription: "Brown Bros Origins P/Noir 750ml",
    invPrice: 9.99,
    totalDiscount: 2.50,
    totalCostIncGst: 13.99,
    singleSell: 79.80,
    promotionId: "P610035",
	promotionGroupId: 5
  },{
    productCode: 399082,
    productDescription: "Brown Bros Origins Shiraz Bl 750ml",
    invPrice: 9.99,
    totalDiscount: 2.50,
    totalCostIncGst: 13.99,
    singleSell: 79.80,
    promotionId: "P610035",
	promotionGroupId: 5
  },{
    productCode: 628392,
    productDescription: "Brown Bros Origins Malbec 750ml",
    invPrice: 9.99,
    totalDiscount: 2.50,
    totalCostIncGst: 13.99,
    singleSell: 79.80,
    promotionId: "P610035",
	promotionGroupId: 5
  },{
    productCode: 831840,
    productDescription: "Brown Bros Origins Gren",
    invPrice: 9.99,
    totalDiscount: 2.50,
    totalCostIncGst: 13.99,
    singleSell: 79.80,
    promotionId: "P610035",
	promotionGroupId: 5
  },{
    productCode: 638142,
    productDescription: "J/Daniels & Cola Can 375ml",
    invPrice: 3.50,
    totalDiscount: 1.00,
    totalCostIncGst: 5.20,
    singleSell: 125.00,
    promotionId: "P125011",
	promotionGroupId: 6
  },{
    productCode: 567809,
    productDescription: "J/Daniels & Dry Can 375ml",
    invPrice: 3.50,
    totalDiscount: 1.00,
    totalCostIncGst: 5.20,
    singleSell: 125.00,
    promotionId: "P125011",
	promotionGroupId: 6
  },{
    productCode: 243030,
    productDescription: "J/Daniels & Lemonade Can 375ml",
    invPrice: 3.50,
    totalDiscount: 1.00,
    totalCostIncGst: 5.20,
    singleSell: 125.00,
    promotionId: "P125011",
	promotionGroupId: 6
  },{
    productCode: 777129,
    productDescription: "J/Daniels Tennessee Apple 700ml",
    invPrice: 35.00,
    totalDiscount: 5.00,
    totalCostIncGst: 43.50,
    singleSell: 150.00,
    promotionId: "P138021",
	promotionGroupId: 7
  },{
    productCode: 718989,
    productDescription: "J/Daniels Tennessee Fire 700ml",
    invPrice: 35.00,
    totalDiscount: 5.00,
    totalCostIncGst: 43.50,
    singleSell: 150.00,
    promotionId: "P138021",
	promotionGroupId: 7
  },{
    productCode: 912662,
    productDescription: "J/Daniels Tennessee Honey 700ml",
    invPrice: 35.00,
    totalDiscount: 5.00,
    totalCostIncGst: 43.50,
    singleSell: 150.00,
    promotionId: "P138021",
	promotionGroupId: 7
  },{
    productCode: 366359,
    productDescription: "Wild Turkey & Cla 101 10pk 375ml",
    invPrice: 50.00,
    totalDiscount: 3.00,
    totalCostIncGst: 61.99,
    singleSell: 61.99,
    promotionId: "P145513",
	promotionGroupId: 8
  },{
    productCode: 614585,
    productDescription: "Wild Turkey 101 Zero 10pk 375ml",
    invPrice: 50.00,
    totalDiscount: 3.00,
    totalCostIncGst: 61.99,
    singleSell: 61.99,
    promotionId: "P145513",
	promotionGroupId: 8
  },{
    productCode: 188675,
    productDescription: "Cascade Bright Bt 375ml 4pk",
    invPrice: 10.00,
    totalDiscount: 1.00,
    totalCostIncGst: 14.00,
    singleSell: 41.00,
    promotionId: "P587489",
	promotionGroupId: 9
  },{
    productCode: 203596,
    productDescription: "Cascade Spec St Bt 375ml 4pk",
    invPrice: 10.00,
    totalDiscount: 1.00,
    totalCostIncGst: 14.00,
    singleSell: 41.00,
    promotionId: "P587489",
	promotionGroupId: 9
  },{
    productCode: 870349,
    productDescription: "Cascade Bitter Bt 375ml 4pk",
    invPrice: 10.00,
    totalDiscount: 1.00,
    totalCostIncGst: 14.00,
    singleSell: 41.00,
    promotionId: "P587489",
	promotionGroupId: 9
  },{
    productCode: 577111,
    productDescription: "Cascade Blonde Bt 375ml 4pk",
    invPrice: 35.00,
    totalDiscount: 3.0,
    totalCostIncGst: 41.00,
    singleSell: 41.00,
    promotionId: "P587489",
	promotionGroupId: 9
  },{
    productCode: 747609,
    productDescription: "Cascade Pr Lgr Bt 375ml 4pk",
    invPrice: 10.00,
    totalDiscount: 1.00,
    totalCostIncGst: 14.00,
    singleSell: 41.00,
    promotionId: "P587489",
	promotionGroupId: 9
  },
];

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor() { }

  getProductsData(): IProducts[] {
    return productData;
  }
}
