<div class="grid">
<dx-data-grid #promotionNameGrid
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
>
<dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <!-- <dxi-column dataField="id" dataType="integer" [allowEditing]="false" width="60"></dxi-column> -->
  <dxi-column dataField="promotion_name" dataType="string" caption="Name">
    <dxi-validation-rule type="required" message="Promotion name is required"></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
</div>