<dx-box direction="row" width="100%" height="75">
  <dxi-item [ratio]="0" baseSize="200">
    <div class="dx-fieldset">
      <div class="dx-fieldset-label">Buying Group</div>
      <dx-lookup
      [dataSource]="buyingGroups"
      [valueExpr]="'id'"
      [displayExpr]="'buying_group_name'"
      (onValueChanged)="setBuyingGroupId($event)"></dx-lookup>
    </div>
    <dxi-validation-rule type="required" message="Buying group code is required"></dxi-validation-rule>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="200">
    <div class="dx-fieldset">
      <div class="dx-fieldset-label">Promotion</div>
      <dx-lookup
      [dataSource]="promotionPeriods"
      [valueExpr]="'id'"
      [displayExpr]="'promotion_name'"
      (onValueChanged)="setPromotionPeriodId($event)"></dx-lookup>
    </div>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="200">
    <div class="dx-fieldset">
      <div class="dx-fieldset-label">Warehouse</div>
      <dx-lookup
      [dataSource]="warehouses"
      [valueExpr]="'id'"
      [displayExpr]="'name'"
      (onValueChanged)="setWarehouseId($event)"></dx-lookup>
    </div>
  </dxi-item>
  <dxi-item [ratio]="0" baseSize="150">
    <div class="dx-fieldset">
      <div class="button-spacer"></div>
        <dx-button
          stylingMode="contained"
          text="Load"
          type="default"
          [width]="120"
          (onClick)="getWarehousePricing()"
        ></dx-button>
      </div>
  </dxi-item>
</dx-box>
<app-custom-loader *ngIf="dataLoading"></app-custom-loader>
<div class="grid">
<dx-data-grid #warehousePricing [ngClass]="{ 'hidden': dataLoading, 'visible': !dataLoading }"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [showColumnLines]="true"
  [wordWrapEnabled]="true"
  [columnAutoWidth]="true"
  [focusedRowEnabled]="true"
  (onEditingStart)="onEditingStart($event)"
  >

  <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="warehousePricingState"
  ></dxo-state-storing>
  <dxo-editing
    mode="batch"
    [allowAdding]="false"
    [allowUpdating]="true"
    [allowDeleting]="false"
  >
  </dxo-editing>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-scrolling mode="virtual" preloadEnabled="true"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-column-chooser
    #columnChooser
    [enabled]="true"
    mode="select"
  ></dxo-column-chooser>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="product_code" dataType="string" caption="Code" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="product_name" dataType="string" caption="Name" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="planning_category_name" dataType="string" caption="Category" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="product_ordering_code" dataType="string" caption="Ordering Code" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="carton_quantity" dataType="number" caption="Carton" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="packs_per_carton" dataType="number" caption="Packs" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="sales_unit" dataType="number" caption="Units" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="current_rrp" dataType="number" caption="Current RRP" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="sell_price" dataType="number" caption="Retail Price"></dxi-column>
  <dxi-column dataType="number" caption="Unit Price" [format]="currencyFormat" [calculateCellValue]="calculateUnitPrice" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="gp_percent" [format]="currencyFormat" caption="GP %" [calculateCellValue]="calculateGpPercent" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="gp_amount" [format]="currencyFormat" caption="GP $" [calculateCellValue]="calculateGpAmount" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="on_promotion" dataType="number" caption="On Promotion" [allowEditing]="false" cellTemplate="cellTemplate">
    <div *dxTemplate="let data of 'cellTemplate'">
      <div class="circle" [style.background-color]="getColour(data.value)">
        <div *ngIf="data.value; else elseBlock" class="dx-icon-lock"></div>
        <ng-template #elseBlock>
          <div class="dx-icon-unlock"></div>
        </ng-template>
      </div>
    </div>
  </dxi-column>
</dx-data-grid>
</div>
