<div class="grid">
<dx-data-grid #buyingGroupsGrid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [height]="'90%'"
  (onSaving)="onSaving($event)"
>
<dxo-editing
    mode="batch"
    [allowAdding]="true"
    [allowUpdating]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="buying_group_code" dataType="string" caption="Code" width="15%">
    <dxi-validation-rule type="required" message="Buying group code is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="buying_group_name" dataType="string" caption="Name">
    <dxi-validation-rule type="required" message="Buying group name is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="account_code" dataType="string" caption="Host Account">
    <dxo-lookup
      [dataSource]="accountCodes"
    ></dxo-lookup>
    <dxi-validation-rule type="required" message="Account Code is required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="region" caption="Region">
    <dxo-lookup
      [dataSource]="regions"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="default" caption="Default" cellTemplate="cellTemplate" width="80px"></dxi-column>
  <dxi-column dataField="id" dataType="integer" [visible]="false"></dxi-column>

  <div *dxTemplate="let data of 'cellTemplate'">
    <div class="radio-group">
      <dx-radio-group
        [value]="data.value"
        [items]="[{id: 1}]"
        [valueExpr]="'id'"
        (onValueChanged)="onRadioValueChanged($event, data.data)"
      >
      </dx-radio-group>
    </div>
  </div>

</dx-data-grid>
</div>