<dx-data-grid #promotionGroupItems
  [dataSource]="productDataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowRemoving)="onRowRemoving($event)"
  (onCellPrepared)="onCellPrepared($event)"
>
<dxo-editing
    mode="row"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxo-toolbar>
    <dxi-item name="saveButton"></dxi-item>
    <dxi-item name="revertButton"></dxi-item>
    <!-- <dxi-item>
      <div *dxTemplate>
        <dx-button icon="plus" (onClick)="openProductSelection($event)"></dx-button>
      </div>
    </dxi-item> -->
    <dxi-item location="after" >
      <div class="create_category_button" *dxTemplate>
      <dx-drop-down-button class="category-icon"
      [elementAttr]="{ class: 'category-icon-border' }"
      hint="Product Configuration"
          [useSelectMode]="false"
          width="22"
          height="22"
          [items]="overflowOptions"
          displayExpr="name"
          keyExpr="id"
          stylingMode="text"
          [showArrowIcon]="false"
          [dropDownOptions]="{
            width: 180,
  
        }"
        (onItemClick)="itemClick($event)"
        >
      </dx-drop-down-button>
      </div>
    </dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
  </dxo-toolbar>
  <dxi-column dataField="product_code" dataType="number" alignment="left" width="100"></dxi-column>
  <dxi-column dataField="product_name" dataType="string"></dxi-column>
  <dxi-column dataField="product_type" dataType="number" caption="Product Type" [calculateCellValue]="calculateProductType"></dxi-column>
  <dxo-paging [pageSize]="5"></dxo-paging>
</dx-data-grid>

<!--Product Selection-->
<dx-popup #productsPopup
  [(visible)]="productSelection.editProductSelection"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Products"
  height="75%"
  width="70%"
  [showCloseButton]="true"
  (onHiding)="productSelection.onHiding($event)">
    <app-product-selection #productSelection
    (closeChild)="closeProductSelection()"
    (loadParentData)="loadData()"
    [parent]="parent"
    [promotionGroupId]="key"></app-product-selection>
</dx-popup>

<!--Mix n Match Selection-->
<dx-popup #mixMatchPopup
  [(visible)]="mixMatchSelection.seteditMixMatch"
  [hideOnOutsideClick]="true"
  [showTitle]="true"
  title="Select Mix n Match"
  height="75%"
  width="50%"
  [showCloseButton]="true"
  (onHiding)="mixMatchSelection.onHiding($event)">
    <app-mix-match-selection #mixMatchSelection
    (closeChild)="closeMixMatchSelection()"
    (loadParentData)="loadData()"
    [parent]="parent"
    [promotionGroupId]="key"></app-mix-match-selection>
</dx-popup>

