import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionProductsService {
  private apiUrl: string = environment.baseApiUrl + 'promotion-details';

  constructor(private crudService: CrudService) { }


  async getPromoProducts(header_id: number): Promise<PromotionProduct[]> {
    return this.crudService.getData(`${this.apiUrl}?header_id=${header_id}`).toPromise();
  }

}

export class PromotionProduct
{
  id: number;
  product_id:number;
  group_id: number;
  location_id: number;
  product_code: string;
  product_name: string;
  supplier_code: string | null;
  supplier_name: string | null;
  product_group_code: string | null;
  carton_quantity: number | null;
  packs_per_carton: number | null;
  luc_ex_gst: number | null;
  cost_inc_gst: number | null;
  sell_price: number | null;
  list_price: number | null;
  gp_percent: number | null;
  gp_amount: number | null;
  product_group_name: string | null;
  promotion_group_id: number | null;
  promotion_group_name: string | null;
  promotion_header_id: number;
  store_position_id: number | null;
  store_position_name: string | null;
  planner_category_id: number | null;
  planning_category_code: string | null;
  planning_category_name: string | null;
  rebate: number | null;
  allowance: number | null;
  allowance_universal: number | null;
  allowance_group: number | null;
  allowance_promotion: number | null;
  allowance_total: number | null;
  max_rebate_qty: number | null;
  price_override: number | null;
  comment: string | null;
  buying_start: string | null;
  buying_end: string | null;
  promotion_start: string | null;
  promotion_end: string | null;
  description: string | null;
  pick_fee: number | null;
  has_wet: number | null;
  has_gst: number | null;
  product_type: number | null;
  mix_match_header_id: number | null;
  mix_match_header_name: string | null;
  rebates?: any[];
  created_at: string | null
  updated_at: string | null;
  deleted_at: string | null;
  product_group_id: number | null;
  cost_ex_gst: number | null;
  slot_type: number | null;
  cost_inc_wet_only: number | null;
  by_line?: any;

}
