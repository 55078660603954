import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  // Private BehaviorSubject that holds the state of the 'editProfile' boolean.
  // Initially set to 'false'.
  private editProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Public observable for the 'editProfile' BehaviorSubject, allowing 
  // external components to subscribe to its changes.
  editProfile$ = this.editProfile.asObservable();

  /**
   * Updates the 'editProfile' BehaviorSubject with a new value.
   *
   * @param {boolean} value - The new value to update the '_editProfile' state.
   */
  setEditProfile(value: boolean) {
    this.editProfile.next(value);
  }

  /**
   * Convenience method to set 'editProfile' to false, essentially
   * indicating that the popup should be hidden.
   */
  hidePopup() {
    this.setEditProfile(false);
  }
}
