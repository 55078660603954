import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class ClearStorage {

  constructor(private cookieService: CookieService) { }

  logout(): void {
    // Clear local storage
    localStorage.removeItem("userDetails");
    localStorage.removeItem("promoSequences");
    localStorage.removeItem("tempPromoSequences");
    localStorage.removeItem("store-positions");
    localStorage.removeItem("buying-groups");
    localStorage.removeItem("promotion-names");
    localStorage.removeItem("selectedDayIndex");
    localStorage.removeItem("suppliers");
    localStorage.removeItem("promotion-groups");
    localStorage.removeItem("promotion-statuses");
    localStorage.removeItem("calender-weeks");
    localStorage.removeItem("promotion-types");
    localStorage.removeItem("events");
    localStorage.removeItem("promotion-headers");
    localStorage.removeItem("promotion-periods");
    localStorage.removeItem("product-groups");
    localStorage.removeItem("promoNames");
    localStorage.removeItem("weekRangeValues");
    this.cookieService.deleteAll();
    // non cleared data
    /*
      slottingState
      weekRangeValues
      endDate
      DisplayWeekRange
      weekRange
    */

    
  }
}
