import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  constructor() { }

  addData(key: string, values: any): void
  {
    const localData = this.getLocalData(key);
    if (localData) {
      localStorage.setItem(key, JSON.stringify([...localData, values]));
    }
  }

  updateData(key: string, id: number, values: any): void
  {   
    const localData = this.getLocalData(key);
    const newArray = localData.map((obj: any) => {
      if (obj.id === id) {
        return { ...obj, values };
      }
      return obj;
    });
    localStorage.setItem(key, JSON.stringify(newArray));
  }

  removeData(key: string, id: number): void
  {
    const localData = this.getLocalData(key);
    localStorage.setItem(key, JSON.stringify(localData.filter((obj: any) => obj.id !== id)));
  }

  private getLocalData(key: string)
  {
    const localDataString = localStorage.getItem(key);
    return localDataString ? JSON.parse(localDataString) : null;
  }
}
