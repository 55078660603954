<div class="dashboard-body container">
  <div class="info-cards">
    <ng-container *ngFor="let card of infoCards">
      <!-- Info Cards -->
      <app-custom-card *ngIf="card.type === 'info'">
        <div card-icon><i class="info dx-icon-info"></i></div>
        <div card-header>{{card.title}} <br><b>{{card.info}}</b></div>
      </app-custom-card>
      <!-- Warning Cards -->
      <a class="unmatched-products" *ngIf="card.title == 'Unmatched Products'" routerLink="/products">
      <app-custom-card *ngIf="card.type === 'warning'">
        <div card-icon><i class="warning dx-icon-warning"></i></div>
        <div card-header><b>{{card.info}}</b> <br> {{card.title}}</div>
      </app-custom-card>
    </a>
    <app-custom-card *ngIf="card.type === 'warning' && card.title != 'Unmatched Products'">
      <div card-icon><i class="warning dx-icon-warning"></i></div>
      <div card-header><b>{{card.info}}</b> <br> {{card.title}}</div>
    </app-custom-card>
      <!-- Error Cards -->
      <app-custom-card *ngIf="card.type === 'error'">
        <div card-icon><i class="error dx-icon-info"></i></div>
        <div card-header><b>{{card.info}}</b> <br>{{card.title}}</div>
      </app-custom-card>
    </ng-container>
  </div>
  <div class="dashboard-logo">
  </div>
  <div class="version">{{version}}</div>
</div>
