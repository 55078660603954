import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/app/services/notify.service';
import { PromotionStatuses } from '../promotion-status/promotion-status.component';
import { BuyingGroups } from 'src/app/models/buying-groups.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DateService } from 'src/app/services/date.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-core-range-headers',
  templateUrl: './core-range-headers.component.html',
  styleUrls: ['./core-range-headers.component.css']
})
export class CoreRangeHeadersComponent {
  dataSource: CustomStore;
  status: PromotionStatuses[] | any;
  buyingGroups: BuyingGroups[] | any;
  cloneCoreRangeData: boolean = false;
  private apiUrl: string = environment.baseApiUrl + 'core-range-headers';
  @ViewChild(DxDataGridComponent, { static: false }) public dataGrid: DxDataGridComponent;
  coreRangeLoading:boolean;
  constructor(private crudService: CrudService,
    private confirmationService:ConfirmationService,
    private dateService: DateService,
    private router: Router,
    private notifyService: NotifyService,
    private cdr: ChangeDetectorRef)
  {
    this.crudService.getData(environment.baseApiUrl + 'promotion-statuses').subscribe((result: any) => {
      this.status = result;
    });
    this.crudService.getData(environment.baseApiUrl + 'buying-groups').subscribe((result: any) => {
      this.buyingGroups = result;
    });
    this.loadData();
  }

  loadData()
  {
    this.dataSource = new CustomStore<any>({
      key: 'id',
      load: async () => {
        return await new Promise((resolve) => {
          this.coreRangeLoading = true;
          this.crudService.getData(this.apiUrl).subscribe((result: any) => {
            resolve(result);
          });
        });
      },
      insert: async (values: CoreRangeHeader) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        this.formatDates(values);
        return await new Promise(async (resolve) => {
          this.crudService.insertData(this.apiUrl, values).subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      update: async (key, values: CoreRangeHeader) => {
        return await new Promise(async (resolve) => {
        const userDetails = localStorage.getItem('userDetails');
        const parsedDetails = JSON.parse(userDetails || '{}');
        values.group_id = parsedDetails.data.group_id;
        values.location_id = parsedDetails.data.location_id;
        await this.crudService.updateData(this.apiUrl, key, values).subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      },
      remove: async (key) => {
        return await new Promise(async (resolve) => {
          await this.crudService.deleteData(this.apiUrl, key).subscribe((result: any) => {
            this.notifyService.tellUser(result);
            resolve(result);
          });
        });
      }
    });
  }

  canDeactivate(): Promise<boolean> {
    if (this.dataGrid && this.dataGrid.instance.hasEditData()) {
      return this.confirmationService.confirmUnsavedChangesNavigation();
    } else {
      return Promise.resolve(true);
    }
  }
  contentReady(e: any) {
    if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
  }
  contentReadyLoading(event:any){
    this.coreRangeLoading = false; // End loading
  }
  formatDates(values: CoreRangeHeader): CoreRangeHeader
  {
    if(values.end_date)
      values.end_date = this.dateService.dateFormat(values.end_date);
    if(values.start_date)
      values.start_date = this.dateService.dateFormat(values.start_date);
    return values;
  }

  navigateDetails = async (e:any) =>{
    await localStorage.setItem('core-range-header', JSON.stringify(e.row.data));
    this.router.navigate(['/core-range-details']);
  }

  openCloneSelection = (e: any) => {
    console.log(e);
    localStorage.setItem('selected-range', JSON.stringify(e.row.data));
    this.cloneCoreRangeData = !this.cloneCoreRangeData;
    this.cdr.detectChanges();
  }

  closeCloneSelection = () => {
    this.cloneCoreRangeData = false;
    this.dataGrid.instance.refresh();
  }

}

export class CoreRangeHeader
{
  id: number;
  group_id: number;
  location_id: number;
  range_name: string;
  start_date: string;
  end_date: string;
  status: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null
}
