import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Config } from '../models/config.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private apiUrl: string = environment.baseApiUrl;

  constructor(private crudService: CrudService) { }

  async getAllConfig(): Promise<Config[]>
  {
    return await this.crudService.getData(`${this.apiUrl}config`).toPromise();
  }

  async GetConfigByKey(key: string): Promise<Config[]> {
    try {
      const data: Config[] = await this.crudService.getData(`${this.apiUrl}config/${key}`).toPromise();
      return data;
    } catch (error) {
      console.error('Error fetching config:', error);
      return [];
    }
  }

  insertConfig(values: Config, config_key: string, config_string: string)
  {
    const userDetails = localStorage.getItem('userDetails');
    const parsedDetails = JSON.parse(userDetails || '{}');
    values.group_id = parsedDetails.data.group_id;
    values.location_id = parsedDetails.data.location_id;
    values.config_key = config_key;
    values.config_string = config_string;
    return this.crudService.insertData(`${this.apiUrl}config`, values).subscribe((data) => {
      return data;
    });
  }

  updateConfig(config_key: string, config_string: string)
  {
    const userDetails = localStorage.getItem('userDetails');
    const parsedDetails = JSON.parse(userDetails || '{}');
    const values: Config = {
      group_id: parsedDetails.data.group_id,
      location_id: parsedDetails.data.location_id,
      config_key: config_key,
      config_string: config_string.toString()
    }
    return this.crudService.updateData(`${this.apiUrl}config`,1, values).subscribe((data) => {
      return data;
    });
  }
}
